/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SnackbarMessage from '../../components/SnackbarMessage';
import Validations from '../../utils/Validations';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo-red.png';
import RoutePaths from '../../config/Routes';
import loginStyle from '../../theme/styles/pages/Login';
import { signUpFilmmaker, checkUserExists } from '../../services/Auth';
import UpgradeUserModal from '../../components/auth/UpgradeUserModal';

const useStyles = makeStyles(loginStyle);

// Login page
const SignUp = () => {
  const pageName = 'Sign Up';
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userData, setuserData] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, watch } = useForm();

  const onSubmit = (data) => {
    setIsSubmitted(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    const payload = {
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone || '',
    };
    checkUserExists(data.email)
      .then((resp) => {
        if (resp?.data?.userExist) {
          setuserData({ email: data.email, phone: data.phone, userId: resp?.data?.userId });
          setIsOpenModal(true);
          setIsSubmitted(false);
        } else {
          signUpFilmmaker(payload)
            .then((res) => {
              setIsSubmitted(false);
              setSnackbarMeesage({
                ...snackbarMeesage,
                show: false,
              });
              window.localStorage.setItem('isLoggedIn', true);
              window.localStorage.setItem('user', JSON.stringify(res.user));
              window.location.assign(RoutePaths.HOME);
            })
            .catch(({ response }) => {
              setIsSubmitted(false);
              const { message } = response?.data;
              if (message && message?.indexOf('Email has already been taken') !== -1) {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  message: message || 'Something went wrong. Please try again.',
                  type: 'error',
                  show: true,
                });
              } else {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  message: message || 'Something went wrong. Please try again.',
                  type: 'error',
                  show: true,
                });
              }
            });
        }
      })
      .catch((e) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: e?.response?.data?.message || 'Something went wrong. Please try again.',
          type: 'error',
          show: true,
        });
      });
  };

  const passwordMatchValidation = (value) => {
    const passwordValue = watch('password');
    return value === passwordValue || 'Passwords do not match';
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />

          <Container component="div" className={classes.authPageText}>
            <Typography component="h1" variant="h5">
              Create Your Filmmaker Account
            </Typography>
            <Typography component="span" style={{ fontSize: '12px' }}>
              This will also create a{' '}
              <a
                href={process.env.REACT_APP_WEBSITE_ENDPOINT}
                target="_blank"
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                rel="noreferrer"
              >
                reveel.net
              </a>{' '}
              account with the same details.
            </Typography>
          </Container>

          <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="name"
                id="name"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Full Name"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="text"
                    autoFocus
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                name="phone"
                id="phone"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Phone Number"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="text"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_EMAIL}
                name="email"
                id="email"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Email Address"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="email"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                className={classes.authInput}
                rules={Validations.REQUIRED_PASSWORD}
                name="password"
                id="password"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                className={classes.authInput}
                rules={{ ...Validations.REQUIRED, validate: passwordMatchValidation }}
                name="confirmpassword"
                id="confirmpassword"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Confirm Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                  />
                )}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.authSubmitBtn}
              disabled={isSubmitted}
            >
              Create Account
            </Button>
          </form>
          <Box>
            Already have a Reveel account? <Link to={RoutePaths.LOGIN}>Login here!</Link>
          </Box>
        </Container>
        {isOpenModal && (
          <UpgradeUserModal closeModal={() => setIsOpenModal(false)} userData={userData} />
        )}

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Grid>
    </PublicWrapper>
  );
};

export default SignUp;
