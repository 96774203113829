import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pageStyle from '../../theme/styles/pages/FormPage';
import SnackbarMessage from '../SnackbarMessage';
import { updateRequestReleaseDate } from '../../services/Filmmakers';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(pageStyle);

const ScheduleFilmModal = ({ closeModal, filmDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, reset, getValues } = useForm();

  useEffect(() => {}, []);

  const handleDialog = () => {
    reset();
    closeModal();
  };

  const submitData = (data) => {
    if (!data.releaseDate) {
      setSnackbarMeesage({
        show: true,
        type: 'error',
        message: 'Please select date and time',
      });
      return;
    }
    setProcessing(true);
    const payload = {
      releaseDate: data.releaseDate,
      programId: filmDetails.programId,
      filmmakerFilmId: filmDetails.filmmakerFilmId,
      type: filmDetails.type,
      programStatus: filmDetails.programStatus,
    };

    updateRequestReleaseDate(payload)
      .then((res) => {
        if (res.data) {
          history.push(RoutePaths.HOME);
          handleDialog();
        }
        setProcessing(false);
      })
      .catch((err) => {
        setSnackbarMeesage({
          show: true,
          type: 'error',
          message: err.message || 'Something went wrong, please try again.',
        });
        setProcessing(false);
      });
  };

  const defaultFormValues = getValues();

  return (
    <>
      <Dialog open fullWidth maxWidth="sm">
        <DialogTitle>Requested Release Date</DialogTitle>
        <DialogContent>
          <form id="submit-date" onSubmit={handleSubmit(submitData)}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Controller
                  control={control}
                  name="releaseDate"
                  id="releaseDate"
                  defaultValue={defaultFormValues.scheduleTime}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <div style={{ color: 'black', marginBottom: '5px', fontWeight: 'bold' }}>
                        Timezone (GMT)
                      </div>
                      <DatePicker
                        selected={value}
                        className={classes.datePickerInput}
                        minDate={new Date()}
                        showTimeSelect
                        onChange={onChange}
                        dateFormat="eee, dd MMM yyyy h:mm aa"
                        isClearable
                        inline
                      />
                    </div>
                  )}
                />
              </Box>
              <Box mt={3} width="35%">
                <Typography>
                  This request is dependent on approval of assets submitted. We recommend at least 2
                  weeks lead time to allow for processing and revisions.
                </Typography>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
            form="submit-date"
          >
            Submit
          </Button>
        </DialogActions>
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Dialog>
    </>
  );
};

ScheduleFilmModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  filmDetails: PropTypes.objectOf.isRequired,
};

export default ScheduleFilmModal;
