import { getApiCall } from '../utils/Api';

// eslint-disable-next-line import/prefer-default-export
export const listAllResources = async (resourcesCategory, page) => {
  const result = await getApiCall(
    `/filmmakers/film/${resourcesCategory}/resource-list?page=${page}&isAdmin=0`,
    true
  );
  return result.data;
};
