import {
  Box,
  Grid,
  Button,
  CardMedia,
  Card,
  Paper,
  Typography,
  CardContent,
  CardActions,
  CardActionArea,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import VideoPlayer from 'react-video-js-player';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import JsFileDownloader from 'js-file-downloader';
import SnackbarMessage from '../../components/SnackbarMessage';
import formInputStyle from '../../theme/styles/FormInput';
import { listAllResources } from '../../services/Resources';
import ViewResource from '../../components/resources/ViewResource';

const useStyles = makeStyles(formInputStyle);
const ResourcesCategory = ({ tabValue }) => {
  const classes = useStyles();
  const [resourceData, setResourceData] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [actionId, setActionId] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const openViewModel = (item) => {
    setSelectedItem(item);
    setViewModel(true);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAllResources(tabValue, activePage)
      .then((res) => {
        setResourceData(res.data.rows);
        setTotalPages(res.data.totalPages);
        setLoading(false);
        setDataLoaded(true);
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Error in loading the data.',
          type: 'error',
          show: true,
        });
        setDataLoaded(true);
      });
  }, [activePage]);

  const download = async (item) => {
    setActionId(item.id);
    setDownloading(true);
    const filename = item.fileUrl.split('/').pop();

    // eslint-disable-next-line no-unused-vars
    const d = new JsFileDownloader({
      url: `${item.fileUrl}?rand=${Math.random()}`,
      timeout: 2 * 60 * 1000,
      forceDesktopMode: true,
      nameCallback() {
        return `${filename}`;
      },
    }).then(() => {
      setDownloading(false);
      setActionId(null);
    });
  };

  return (
    <>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <div>
          <Grid container spacing={2}>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress style={{ marginTop: 30, marginBottom: 30 }} />
              </Box>
            )}
            {!loading && resourceData && resourceData?.length === 0 && (
              <div style={{ paddingTop: 50, paddingLeft: 20, fontSize: 15 }}>
                Resources not found.
              </div>
            )}
            {!loading &&
              resourceData &&
              resourceData.length > 0 &&
              resourceData.map((item) => (
                <Grid item key={`card-res-${item.id}`}>
                  <Card component={Box} width={300}>
                    <CardActionArea>
                      {item.type === 'Image' && (
                        <CardMedia
                          component="img"
                          alt=""
                          image={item.fileUrl}
                          title=""
                          height={200}
                          width={300}
                        />
                      )}

                      {item.type === 'Video' && (
                        <>
                          {item.videoThumb && (
                            <CardMedia
                              component="img"
                              alt=""
                              image={item.videoThumb}
                              title=""
                              height={200}
                              width={300}
                            />
                          )}
                          {!item.videoThumb && (
                            <VideoPlayer
                              controls={false}
                              src={item.fileUrl}
                              width="300"
                              height="200"
                              hideControls={[
                                'play',
                                'volume',
                                'seekbar',
                                'timer',
                                'playbackrates',
                                'fullscreen',
                              ]}
                              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            />
                          )}
                        </>
                      )}
                    </CardActionArea>
                    <CardContent style={{ height: '80px' }}>
                      <Typography variant="body1" className={classes.threeLineEllipsis}>
                        {item.title}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        size="small"
                        onClick={() => openViewModel(item)}
                      >
                        {item.type === 'Video' ? 'Play' : 'Preview'}
                      </Button>
                      {1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          size="small"
                          onClick={() => download(item)}
                          endIcon={
                            downloading &&
                            actionId === item.id && (
                              <CircularProgress
                                color="inherit"
                                size={20}
                                style={{ marginRight: '5px' }}
                              />
                            )
                          }
                        >
                          Download
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
          {dataLoaded && totalPages > 1 && (
            <Pagination
              count={totalPages}
              showFirstButton
              showLastButton
              className={classes.tablePagination}
              onChange={(_, pageNumber) => {
                setActivePage(pageNumber);
              }}
              page={activePage}
              style={{ marginTop: 30 }}
            />
          )}
          {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
        </div>
      </Box>

      {viewModel && (
        <ViewResource
          onClose={() => {
            setViewModel(false);
          }}
          title={selectedItem?.title}
          fileUrl={selectedItem?.fileUrl}
          type={selectedItem?.type}
        />
      )}
    </>
  );
};

ResourcesCategory.propTypes = {
  tabValue: PropTypes.bool.isRequired,
};

export default ResourcesCategory;
