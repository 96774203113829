import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PropTypes from 'prop-types';
import CallMadeIcon from '@material-ui/icons/CallMade';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PaymentIcon from '@material-ui/icons/Payment';
import Routes from '../config/Routes';
import sidebarStyle from '../theme/styles/components/SidebarNavs';
import { validateSession } from '../services/Auth';

const useStyles = makeStyles(sidebarStyle);

// Sidebar navigation links
const SidebarNavs = ({ userId, showResourcesTab }) => {
  const notificationInterval = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [openedSubMenus, setOpenedSubMenu] = useState(true);
  const [notificationCnt, setNotificationCnt] = useState(0);

  const routeArr = [
    {
      title: 'Dashboard',
      path: Routes.PROFILE_OVERVIEW,
      icon: <InsertChartIcon />,
      selected: location.pathname === Routes.PROFILE_OVERVIEW,
    },
    {
      title: 'My Profile',
      path: Routes.PROFILE,
      icon: <PersonOutlineIcon />,
      selected:
        location.pathname === Routes.PROFILE || location.pathname === Routes.CHANGE_PASSWORD,
    },
  ];

  const handleLink = (page) => {
    history.push(page);
  };

  const getNotificationCount = async () => {
    clearTimeout(notificationInterval.current);
    validateSession()
      .then((res) => {
        setNotificationCnt(res.data?.notificationCount || 0);

        notificationInterval.current = setTimeout(() => {
          getNotificationCount();
        }, 10000);
      })
      .catch(() => {
        // skip error
      });
  };

  useEffect(() => {
    clearTimeout(notificationInterval.current);
    getNotificationCount();

    return () => {
      clearTimeout(notificationInterval.current);
      notificationInterval.current = null;
    };
  }, []);

  return (
    <List className={classes.menuItemContainer}>
      <ListItem
        button
        onClick={() => setOpenedSubMenu(!openedSubMenus)}
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuIcon}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary="Profile" />
        {!openedSubMenus && <ExpandMore />}
        {openedSubMenus && <ExpandLess />}
      </ListItem>
      <Collapse in={openedSubMenus} timeout={0} unmountOnExit>
        <List component="div" disablePadding>
          {routeArr.map((item) => (
            <>
              <ListItem
                button
                onClick={() => handleLink(item.path)}
                selected={item.selected}
                className={`${classes.menuSubItem}`}
              >
                <ListItemIcon className={classes.menuIcon}>{item.icon}</ListItemIcon>
                <ListItemText className={classes.menuText} primary={item.title} />
              </ListItem>
            </>
          ))}
        </List>
      </Collapse>

      <ListItem
        button
        onClick={() => handleLink(Routes.HOME)}
        selected={location.pathname === Routes.HOME || location.pathname.includes('/films/view')}
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuIcon}>
          <PlaylistPlayIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary="My Films" />
        {notificationCnt > 0 && (
          <Badge badgeContent={notificationCnt} color="secondary">
            <NotificationsIcon />
          </Badge>
        )}
      </ListItem>
      {showResourcesTab && (
        <ListItem
          button
          onClick={() => handleLink(Routes.RESOURCES)}
          selected={location.pathname === Routes.RESOURCES}
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.menuIcon}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText className={classes.menuText} primary="Resources" />
        </ListItem>
      )}
      {showResourcesTab && (
        <ListItem
          button
          onClick={() => handleLink(Routes.PAYMENT)}
          selected={location.pathname === Routes.PAYMENT}
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.menuIcon}>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText className={classes.menuText} primary="Payment" />
        </ListItem>
      )}
      <ListItem
        button
        onClick={() => window.open('https://help.reveelfilmmakers.com/support/solutions', '_blank')}
        selected=""
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuIcon}>
          <ListIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary="FAQ" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          window.FreshworksWidget('show');
          window.FreshworksWidget('open');
        }}
        selected=""
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuIcon}>
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary="Contact Us" />
      </ListItem>
      <ListItem
        button
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_WEBSITE_ENDPOINT}?utm_brand=RTV&utm_source=films.reveel.net&utm_medium=referral&utm_campaign=watchnow&utm_term=${userId}&utm_content=browse`,
            '_blank'
          )
        }
        selected=""
        className={classes.menuItem}
      >
        <ListItemIcon className={classes.menuIcon}>
          <CallMadeIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuText} primary="Go to Reveel" />
      </ListItem>
    </List>
  );
};
SidebarNavs.propTypes = {
  userId: PropTypes.number.isRequired,
  showResourcesTab: PropTypes.bool.isRequired,
};

export default SidebarNavs;
