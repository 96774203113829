import { Button, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import listingStyle from '../../theme/styles/pages/Listing';
import ViewPrompt from '../assets/ViewPrompt';
import UploadPromotionAsset from '../assets/UploadPromotionAsset';

const useStyles = makeStyles(listingStyle);

const Copromote = ({
  title,
  interviewType,
  filmDetail,
  roles,
  promptData,
  assetUrl,
  promotionSubmissionId,
  assetsDataCallback,
  type,
}) => {
  const classes = useStyles();

  const [showViewPrompt, setShowViewPrompt] = useState(false);

  const handleCloseViewPrompt = () => {
    setShowViewPrompt(false);
  };

  const onFilmUploadComplete = (v) => {
    assetsDataCallback(type, v.videoUrl, v.id);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      >
        <div className={classes.form50Section1}>
          <Typography>{title}</Typography>
        </div>

        <div className={classes.form50Section2}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<DescriptionIcon />}
            onClick={() => setShowViewPrompt(true)}
          >
            {`View ${interviewType === 'video' ? 'prompt' : 'questions'}`}
          </Button>
        </div>

        <div className={classes.form50Section2}>
          <UploadPromotionAsset
            name={`${interviewType === 'video' ? 'video' : 'answers'}`}
            caption={`${interviewType === 'video' ? 'video' : 'answers'}`}
            filmmakerId={filmDetail.filmmakerId}
            programId={filmDetail.reveelProgramId}
            role={roles}
            value={assetUrl}
            onUploadComplete={(v) => onFilmUploadComplete(v)}
            promotionSubmissionId={promotionSubmissionId}
            assetsDataCallback={assetsDataCallback}
            type={type}
          />
        </div>
      </div>

      {showViewPrompt && (
        <ViewPrompt
          title={`${interviewType === 'video' ? 'prompt' : 'questions'} - ${title}`}
          closeViewPromptDialog={handleCloseViewPrompt}
          promptData={promptData}
          downloadFileName={`${interviewType}_interview_${roles}_${
            interviewType === 'video' ? 'prompt' : 'questions'
          }`}
        />
      )}
    </>
  );
};

Copromote.propTypes = {
  title: PropTypes.string.isRequired,
  interviewType: PropTypes.string.isRequired,
  filmDetail: PropTypes.objectOf.isRequired,
  roles: PropTypes.string.isRequired,
  promptData: PropTypes.string.isRequired,
  assetUrl: PropTypes.string.isRequired,
  promotionSubmissionId: PropTypes.number.isRequired,
  assetsDataCallback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Copromote;
