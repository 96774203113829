import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menubar from '../components/Menubar';
import wrapperStyle from '../theme/styles/LayoutWrapper';

const useStyles = makeStyles(wrapperStyle);

// Layout for authorized pages
const Private = ({ pageName, children }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      history.push('/logout');
    }

    const user = window.localStorage.getItem('user');
    const userNameObj = user ? JSON.parse(user) : null;
    // window.FreshworksWidget('show');
    window.FreshworksWidget('identify', 'ticketForm', {
      name: userNameObj?.name || '',
      email: userNameObj?.email || '',
    });
  }, [pageName]);

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      <Menubar pageName={pageName} />

      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </Container>
  );
};

Private.propTypes = {
  pageName: PropTypes.string,
  children: PropTypes.element.isRequired,
};
Private.defaultProps = {
  pageName: '',
};

export default Private;
