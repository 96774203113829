/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { TableSortLabel, Link, Menu, MenuItem, Box, Tooltip } from '@material-ui/core';
import { ArrowDownward, ImportExport, MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import PrivateWrapper from '../../layout/Private';
import TableLoader from '../../components/TableLoader';
import listingStyle from '../../theme/styles/pages/Listing';
import {
  deleteFilmById,
  listAllFilms,
  updateFilmmakerNotificationById,
} from '../../services/Filmmakers';
import SnackbarMessage from '../../components/SnackbarMessage';
import ConfirmDialog from '../../components/ConfirmDialog';
import ManageFilmAssets from '../../components/filmmakers/ManageFilmAssets';
import ManageFilmmakerFilm from '../../components/filmmakers/ManageFilmmakerFilm';
import ViewFilmmakerFilm from '../../components/filmmakers/ViewFilmmakerFilm';
import ManageBtsFiles from '../../components/filmmakers/ManageBtsFiles';
import VideoTypes from '../../config/VideoTypes';
import filmsViewPageUrl from '../../utils/Url';
import { TOTAL_RECORDS_PER_PAGE, filmmkaerFilmsStatus } from '../../config/Constants';
import Promotions from '../../components/filmmakers/Promotions';

const useStyles = makeStyles(listingStyle);

const FilmsPage = () => {
  const pageName = 'My Films';

  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [actionId, setActionId] = useState(0);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openConifrmDeleteDialog, setOpenConifrmDeleteDialog] = useState(false);
  const [openEditFormDialog, setOpenEditFormDialog] = useState(false);
  const [openProgramAssetStatusDialog, setOpenProgramAssetStatusDialog] = useState(false);
  const [openBtsFilesDialog, setOpenBtsFilesDialog] = useState(false);
  const [openPromotionsDialog, setOpenPromotionsDialog] = useState(false);
  const [rejectedRowData, setRejectedRowData] = useState([]);
  const [filmDetail, setFilmDetail] = useState({
    reveelProgramId: 0,
    filmTitle: '',
    filmmakerId: 0,
    promotionStatus: 0,
    filmmakerName: '',
    promotionOptOut: null,
    affiliateCode: '',
    userClicks: 0,
  });

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const columns = [
    { name: 'Row Number', id: 'serialNo', width: '3%' },
    { name: 'Title', id: 'title', width: '15%' },
    { name: 'Type', id: 'type', width: '6%' },
    { name: 'Reveel Program', id: 'reveelProgramTitle', width: '15%' },
    { name: 'Status', id: 'status', width: '15%' },
    { name: 'Submission Date', id: 'submissionDate', width: '13%' },
    { name: 'Release Date', id: 'releaseDate', width: '12%' },
    { name: 'Remarks', id: 'remarks', width: '15%', align: 'left' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (searchText) queryString += `&searchText=${encodeURIComponent(searchText)}`;
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    queryString += `&filmmaker=true`;
    return queryString.substring(4);
  };

  const handleClosePromotionModal = (showSnackbarMsg) => {
    if (showSnackbarMsg) {
      setSnackbarMeesage({ ...snackbarMeesage, show: false });
      setTimeout(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: true,
          type: 'success',
          message: 'Promotion detail saved successfully.',
        });
      }, 100);
    }
    setOpenPromotionsDialog(false);
    window.FreshworksWidget('show');
    setActionId(null);
    setReloadRows(!reloadRows);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAllFilms(activePage, getParams())
      .then((res) => {
        let updatedRows;
        if (res?.data?.rows) {
          updatedRows = res.data.rows;
          updatedRows = updatedRows.map((tr, index) => ({
            ...tr,
            serialNo: (activePage - 1) * TOTAL_RECORDS_PER_PAGE + index + 1,
          }));
        }
        setRows(updatedRows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Error in loading the data.',
          type: 'error',
          show: true,
        });
        setRows([]);
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const handleActions = async (row, action) => {
    if (action === 'edit') {
      setActionId(row.id);
      setOpenEditFormDialog(true);
    } else if (action === 'delete') {
      setActionId(row.id);
      setOpenConifrmDeleteDialog(true);
    } else if (action === 'manage-assets') {
      setActionId(row.id);
      setOpenProgramAssetStatusDialog(true);
    } else if (action === 'view') {
      if (row.notificationNotes) {
        const data = {
          updateNotificationNote: true,
        };
        await updateFilmmakerNotificationById(row.id, data);
      }
      setActionId(row.id);
      if (row.statusValue === 1 || row.statusValue === 7 || row.statusValue === 6) {
        setRejectedRowData(
          row.statusValue === 7 || row.statusValue === 6
            ? rows.filter((item) => item.id === row.id)
            : []
        );
        setOpenViewDialog(true);
      } else if (
        row.statusValue === 2 ||
        row.statusValue === 3 ||
        row.statusValue === 4 ||
        row.statusValue === 5
      ) {
        history.push(filmsViewPageUrl(row.id));
      }
    } else if (action === 'manage-bts-files') {
      setActionId(row.id);
      setOpenBtsFilesDialog(true);
    } else if (action === 'promotions') {
      window.FreshworksWidget('hide');
      if (row.promotionNotificationNotes) {
        const data = {
          updatePromotionNotificationNote: true,
        };
        await updateFilmmakerNotificationById(row.id, data).then(() => {
          const updatedRows = rows.map((r) => {
            if (r.id === row.id) {
              return {
                ...r,
                promotionNotificationNotes: null,
              };
            }
            return r;
          });
          setRows(updatedRows);
        });
      }
      setActionId(row.id);
      setFilmDetail({
        ...filmDetail,
        reveelProgramId: row.reveelProgramId,
        filmTitle: row.title,
        filmmakerId: row.filmmakerId,
        promotionStatus: row.promotionStatus,
        filmmakerName: row.filmmakerName,
        promotionOptOut: row.promotionOptOut,
        affiliateCode: row.affiliateCode,
        userClicks: row.userClicks,
      });
      setOpenPromotionsDialog(true);
    }
  };

  const handleDeleteFilm = async () => {
    try {
      setSnackbarMeesage({
        message: '',
        type: '',
        show: false,
      });

      const result = await deleteFilmById(actionId);
      if (result.success) {
        setSnackbarMeesage({
          message: 'Deleted successfully.',
          type: 'success',
          show: true,
        });
        setOpenConifrmDeleteDialog(false);
        setActionId(null);
        setReloadRows(!reloadRows);
      }
    } catch ({ response }) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: response?.data?.message || 'Something went wrong. Please try again.',
        type: 'error',
        show: true,
      });
      setOpenConifrmDeleteDialog(false);
      setActionId(null);
    }
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <div className={classes.filterToolbar}>
        <div className={classes.filterLeft}>
          <TextField
            name="search"
            id="search"
            label="Search"
            className={classes.searchInput}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchList();
            }}
            value={searchText}
          />
          <Button
            variant="contained"
            className={classes.searchFilterBtn}
            onClick={() => setReloadRows(!reloadRows)}
          >
            Refresh
          </Button>
        </div>
        <div className={classes.filterRight}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            className={classes.addNewBtn}
            onClick={() => {
              window.FreshworksWidget('hide');
              setOpenEditFormDialog(true);
            }}
          >
            Submit New Film
          </Button>
        </div>
      </div>
      <TableContainer>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || 'left'} key={`col-${col.id}`}>
                  {col.id === 'serialNo' ? (
                    <span>{col.name}</span>
                  ) : (
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell align="right" width="15%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded && <TableLoader cols={9} />}
            {dataLoaded && rows.length === 0 && (
              <TableCell
                align="center"
                size="medium"
                colSpan={9}
                className={classes.noRecordFoundText}
              >
                No records found
              </TableCell>
            )}

            {dataLoaded &&
              rows.map((row) => (
                <TableRow key={`gn-${row.id}`}>
                  <TableCell>{row.serialNo}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {row.notificationNotes && (
                        <Box marginLeft={-2}>
                          <PriorityHighIcon color="primary" size={10} />
                        </Box>
                      )}
                      {row.title}
                      {row.promotionNotificationNotes && (
                        <Tooltip title={row.promotionNotificationNotes}>
                          <Box marginLeft={1}>
                            <NotificationsActiveIcon color="primary" />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                    {/* {row.notificationNotes && (
                      <Box display="flex">
                        <Box marginLeft={-2}>
                          <PriorityHighIcon color="primary" size={10} />
                        </Box>
                        {row.title}
                      </Box>
                    )}
                    {!row.notificationNotes && <>{row.title}</>} */}
                  </TableCell>
                  <TableCell>{VideoTypes[row.type] || ''}</TableCell>
                  <TableCell>
                    {row.reveelProgramId && (row.statusValue === 4 || row.statusValue === 5) && (
                      <Link href={row.reveelProgram.programLink} target="_blank">
                        {row.reveelProgram.title || '-'}
                      </Link>
                    )}
                    {row.reveelProgramId && row.statusValue !== 4 && row.statusValue !== 5 && (
                      <Box>{row.reveelProgram.title || '-'}</Box>
                    )}
                    {!row.reveelProgramId && '-'}
                  </TableCell>
                  <TableCell align="left">
                    <span className={classes[row.statusClass]}>{row.status}</span>
                  </TableCell>
                  <TableCell>{row.submissionDate ? row.submissionDate : '-'}</TableCell>
                  <TableCell>{row.releaseDate ? row.releaseDate : '-'}</TableCell>
                  <TableCell align="left">
                    {row.statusValue === filmmkaerFilmsStatus.ASSET_UPLOAD &&
                      'Please upload film assets'}
                    {(row.statusValue === filmmkaerFilmsStatus.FOR_APPROVAL ||
                      row.statusValue === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE) &&
                      row.rejectedAssetCount > 0 &&
                      `${row.rejectedAssetCount} assets rejected, please check your submission`}
                    {row.statusValue !== filmmkaerFilmsStatus.ASSET_UPLOAD &&
                      row.statusValue !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                      row.statusValue !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE &&
                      row.remarks &&
                      row.remarks}
                    {(row.statusValue === filmmkaerFilmsStatus.SAVED ||
                      row.statusValue === filmmkaerFilmsStatus.SUBMITTED) &&
                      '-'}
                    {!row.remarks &&
                      row.statusValue !== filmmkaerFilmsStatus.ASSET_UPLOAD &&
                      row.statusValue !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                      row.statusValue !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE &&
                      '-'}
                    {(row.statusValue === filmmkaerFilmsStatus.FOR_APPROVAL ||
                      row.statusValue === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE) &&
                      row.rejectedAssetCount <= 0 &&
                      '-'}
                  </TableCell>
                  <TableCell align="right">
                    <ActionMenu row={row} selectAction={handleActions} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {openConifrmDeleteDialog && (
        <ConfirmDialog
          title="Delete Film?"
          closeButtonText="Cancel"
          message="Are you sure want to delete this film? This action can not be undone."
          approveButtonText="Delete"
          onClose={() => {
            setOpenConifrmDeleteDialog(false);
            setActionId(null);
          }}
          onApprove={handleDeleteFilm}
        />
      )}
      {openEditFormDialog && (
        <ManageFilmmakerFilm
          filmmakerProgramId={actionId}
          closeModal={() => {
            window.FreshworksWidget('show');
            setOpenEditFormDialog(false);
            setActionId(null);
          }}
          onSuccess={() => {
            window.FreshworksWidget('show');
            setOpenEditFormDialog(false);
            setReloadRows(!reloadRows);
            setActionId(null);
          }}
        />
      )}
      {openProgramAssetStatusDialog && (
        <ManageFilmAssets
          filmId={actionId}
          closeModal={() => {
            setOpenProgramAssetStatusDialog(false);
            setActionId(null);
          }}
        />
      )}
      {openViewDialog && (
        <ViewFilmmakerFilm
          viewId={actionId}
          closeModal={() => {
            setOpenViewDialog(false);
            setActionId(null);
          }}
          open={openViewDialog}
          rejectedRowData={rejectedRowData}
        />
      )}
      {openBtsFilesDialog && (
        <ManageBtsFiles
          filmmakerProgramId={actionId}
          handleClose={() => {
            setOpenBtsFilesDialog(false);
            setActionId(null);
          }}
        />
      )}
      {openPromotionsDialog && (
        <Promotions filmDetail={filmDetail} closeModal={(v) => handleClosePromotionModal(v)} />
      )}
    </PrivateWrapper>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.statusValue !== 0 && (
          <MenuItem
            onClick={() => {
              handleMenuClick('view');
            }}
          >
            View
          </MenuItem>
        )}
        {row.statusValue === 0 && (
          <MenuItem
            onClick={() => {
              handleMenuClick('edit');
            }}
          >
            Edit
          </MenuItem>
        )}
        {(row.statusValue === 4 || row.statusValue === 5) && (
          <MenuItem
            onClick={() => {
              handleMenuClick('promotions');
            }}
          >
            Promotions
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf.isRequired,
  selectAction: PropTypes.func.isRequired,
};

export default FilmsPage;
