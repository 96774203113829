import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import RoutePaths from '../config/Routes';
import LoginPage from './auth/Login';
import SignupPage from './auth/Signup';
import LogoutPage from './auth/Logout';
import NotFoundPage from './error/NotFound';
import AppLoader from '../components/AppLoader';
import Default from '../theme/Default';
import FilmsPage from './filmmakers/FilmsPage';
import Resources from './resources/Resources';
import InvitationPage from './auth/Invitation';
import { validateSession } from '../services/Auth';
import ContractCancelledFrame from './contract/ContractCancelledFrame';
import ContractSignedFrame from './contract/ContractSignedFrame';
import ContractPage from './contract/Contract';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import ProfilePage from './profile/ProfilePage';
import ChangePassword from './auth/ChangePassword';
import AutoLogin from './auth/AutoLogin';
import FilmsViewPage from './filmmakers/FilmsViewPage';
import OverviewPage from './profile/OverviewPage';
import Payment from './payment/Payment';
import Terms from './auth/Terms';

// Initialize app routes
const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [showResourcesModule, setShowResourcesModule] = useState(false);
  const history = useHistory();

  useEffect(() => {
    validateSession()
      .then((res) => {
        if (res.data.status === 0) {
          window.localStorage.removeItem('isLoggedIn');
          window.localStorage.removeItem('user');
          history.push(RoutePaths.LOGIN);
        }
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('user', res.data ? JSON.stringify(res.data) : '');
        setShowResourcesModule(res.data?.showResourcesTab);
        setAppLoaded(true);
      })
      .catch(() => {
        setAppLoaded(true);
      });
  }, []);

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />

      {!appLoaded && <AppLoader />}

      {appLoaded && (
        <Router>
          <Switch>
            <Route path={RoutePaths.LOGIN} exact component={LoginPage} />
            <Route path={RoutePaths.SIGNUP} exact component={SignupPage} />
            <Route path={RoutePaths.FORGOT_PASSWORD} exact component={ForgotPassword} />
            <Route path={RoutePaths.RESET_PASSWORD} exact component={ResetPassword} />
            <Route path={RoutePaths.HOME} exact component={FilmsPage} />
            {showResourcesModule && (
              <Route path={RoutePaths.RESOURCES} exact component={Resources} />
            )}
            <Route path={RoutePaths.INVITATION} exact component={InvitationPage} />
            <Route path={RoutePaths.CONTRACT} exact component={ContractPage} />
            <Route path={RoutePaths.CONTRACT_CANCELLED} exact component={ContractCancelledFrame} />
            <Route path={RoutePaths.CONTRACT_SIGNED} exact component={ContractSignedFrame} />
            <Route path={RoutePaths.LOGOUT} exact component={LogoutPage} />
            <Route path={RoutePaths.PROFILE_OVERVIEW} exact component={OverviewPage} />
            <Route path={RoutePaths.PROFILE} exact component={ProfilePage} />
            <Route path={RoutePaths.CHANGE_PASSWORD} exact component={ChangePassword} />
            <Route path={RoutePaths.AUTOLOGIN} component={AutoLogin} />
            <Route path={RoutePaths.FILMS_VIEW} component={FilmsViewPage} />
            <Route path={RoutePaths.TERMS} component={Terms} />
            <Route path={RoutePaths.PAYMENT} component={Payment} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
