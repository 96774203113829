import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import wrapperStyle from '../theme/styles/LayoutWrapper';

const useStyles = makeStyles(wrapperStyle);

// Layout for public pages
const Public = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      {children}
    </Container>
  );
};

Public.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Public;
