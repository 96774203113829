import React, { useEffect, useState } from 'react';
import VideoPlayer from 'react-video-js-player';
import PropTypes from 'prop-types';

// Preview video inline
const VideoInlinePreview = ({ url }) => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setShowVideo(false);
    setTimeout(() => setShowVideo(true), Math.random() * 1200 + 250);
  }, [url]);

  if (!showVideo) {
    return <></>;
  }

  return (
    <>
      <VideoPlayer src={url} width="250" controls />
      <div
        style={{
          marginTop: 5,
          marginBottom: 5,
          width: 250,
          color: 'grey',
          fontSize: 10,
          textAlign: 'center',
        }}
      >
        Note: Some video preview may not work in browser,{' '}
        <a href={url} target="_blank" rel="noreferrer">
          click here
        </a>{' '}
        to view/download the video.
      </div>
    </>
  );
};

VideoInlinePreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoInlinePreview;
