import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarMessage from '../SnackbarMessage';
import 'react-datepicker/dist/react-datepicker.css';
import Copromote from './Copromote.promotion';
import PromotionPreferences from './PromotionPreferences';
import ConfirmDialog from '../ConfirmDialog';
import { updatePromotionOptOut } from '../../services/Programs';
import VideoUpload from './VideoUpload';
import { promotionStatus, promotionSubmissionType } from '../../config/Constants';
import {
  createFilmmakerFilmsShortlink,
  getPromotionAssetsById,
  getPromotionPrompts,
  removePromotionAssetById,
  updatePromotionDetails,
} from '../../services/Filmmakers';
import SubmittedView from './SubmittedView.promotion';

const Promotions = ({ filmDetail, closeModal }) => {
  const [notes, setNotes] = useState('');
  const [openPromotionPreferences, setOpenPromotionPreferences] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    promotionPreferences: false,
    saveSubmitNow: false,
  });
  const [promptsData, setPromptsData] = useState({});
  const [filmPromotionDetails, setFilmPromotionDetails] = useState({
    promotionStatus: filmDetail.promotionStatus,
    promotionOptOut: filmDetail.promotionOptOut,
  });
  const [promotionAllAssets, setPromotionAllAssets] = useState([]);
  const [promotionAssets, setPromotionAssets] = useState({
    videoDirector: [],
    videoCast: [],
    writtenDirector: [],
    writtenCast: [],
    otherFiles: [],
  });
  const [errorNotes, setErrorNotes] = useState(false);
  const [promoLink, setPromoLink] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const showSnackbarMessage = (type, message) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: true,
      type,
      message,
    });
  };

  const closePromotionPreferencesDialog = () => {
    setOpenPromotionPreferences(false);
  };

  const handleShowConfirmDialog = () => {
    setShowConfirmDialog({ ...showConfirmDialog, promotionPreferences: true });
  };

  const confirmPromotionPreferences = () => {
    updatePromotionOptOut(filmDetail.reveelProgramId)
      .then((res) => {
        if (res.data.success) {
          setSnackbarMeesage({
            ...snackbarMeesage,
            show: true,
            type: 'success',
            message: 'Your film has been successfully opting out of promotions.',
          });
          setFilmPromotionDetails({
            ...filmPromotionDetails,
            promotionOptOut: 1,
          });
          setShowConfirmDialog({ ...showConfirmDialog, promotionPreferences: false });
          setOpenPromotionPreferences(false);
        }
      })
      .catch(() => {
        // Skipped
      });
  };

  const handleCopyUniqueLink = async () => {
    setSnackbarMeesage({ ...snackbarMeesage, show: false });

    const url = `${process.env.REACT_APP_FILM_ENDPOINT}/${promoLink}`;
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setSnackbarMeesage({
        ...snackbarMeesage,
        show: true,
        type: 'success',
        message: 'Link copied successfully.',
      });
    }, 100);
  };

  const onUploadComplete = (v) => {
    setPromotionAssets({
      ...promotionAssets,
      otherFiles: [...promotionAssets.otherFiles, { fileName: v.fileName, id: v.id }],
    });
  };

  const handleSaveSubmitNow = () => {
    if (notes && notes.length > 255) {
      setErrorNotes(true);
      return;
    }
    setShowConfirmDialog({ ...showConfirmDialog, saveSubmitNow: true });
  };

  const handleSaveFinishLater = () => {
    if (notes && notes.length > 255) {
      setErrorNotes(true);
      return;
    }
    const payload = {
      promotionStatus: promotionStatus.SAVED,
      promotionNotes: notes || null,
      programId: filmDetail.reveelProgramId,
    };
    updatePromotionDetails(payload)
      .then((res) => {
        if (res.data) {
          closeModal(true);
        }
      })
      .catch((e) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: true,
          type: 'error',
          message: e.response?.data?.message || 'Something went wrong. Please try again later.',
        });
      });
  };

  const confirmsaveSubmitNow = () => {
    const payload = {
      promotionStatus: promotionStatus.SUBMMITED,
      promotionNotes: notes || null,
      programId: filmDetail.reveelProgramId,
    };
    updatePromotionDetails(payload)
      .then((res) => {
        if (res.data) {
          setSnackbarMeesage({
            ...snackbarMeesage,
            show: true,
            type: 'success',
            message: 'Promotion detail submitted successfully.',
          });
          setShowConfirmDialog({ ...showConfirmDialog, saveSubmitNow: false });
          setFilmPromotionDetails({
            ...filmPromotionDetails,
            promotionStatus: promotionStatus.SUBMMITED,
          });
        }
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: true,
          type: 'error',
          message: 'Something went wrong. Please try again later.',
        });
      });
  };

  const handleRemoveFile = (id, index) => {
    removePromotionAssetById(id)
      .then((res) => {
        if (res.success) {
          promotionAssets.otherFiles.splice(index, 1);
          setSnackbarMeesage({ ...snackbarMeesage, show: false });
          setTimeout(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              show: true,
              type: 'success',
              message: 'Asset removed successfully.',
            });
          }, 100);
        }
      })
      .catch(() => {
        // Skipped
      });
  };

  const assetsDataCallback = (type, url = null, id = null) => {
    setPromotionAssets((prevPromotionAssets) => ({
      ...prevPromotionAssets,
      [type]: url && id ? [{ id, fileUrl: url }] : [],
    }));
  };

  useEffect(() => {
    getPromotionAssetsById(filmDetail.reveelProgramId, filmPromotionDetails.promotionStatus)
      .then((res) => {
        setPromotionAssets({
          ...promotionAssets,
          videoDirector: res.data.filter(
            (item) =>
              item.type === promotionSubmissionType.VIDEO_INTERVIEW_FILE && item.role === 'director'
          ),
          videoCast: res.data.filter(
            (item) =>
              item.type === promotionSubmissionType.VIDEO_INTERVIEW_FILE && item.role === 'cast'
          ),
          writtenDirector: res.data.filter(
            (item) =>
              item.type === promotionSubmissionType.WRITTEN_INTERVIEW_FILE &&
              item.role === 'director'
          ),
          writtenCast: res.data.filter(
            (item) =>
              item.type === promotionSubmissionType.WRITTEN_INTERVIEW_FILE && item.role === 'cast'
          ),
          otherFiles: res.data.filter((item) => item.type === promotionSubmissionType.OTHER_FILE),
        });
        setNotes(res.data[0].promotionNotes);
        setPromotionAllAssets(res.data);
      })
      .catch(() => {
        // Skipped
      });
  }, [filmPromotionDetails.promotionStatus]);

  useEffect(() => {
    if (
      filmDetail.promotionStatus === promotionStatus.INVITED ||
      filmDetail.promotionStatus === promotionStatus.SAVED
    ) {
      getPromotionPrompts()
        .then((res) => {
          setPromptsData(res.data);
        })
        .catch(() => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            show: true,
            type: 'error',
            message: 'Something went wrong. Please try again later.',
          });
        });
    }

    const payload = {
      filmmakerId: filmDetail.filmmakerId,
      programId: filmDetail.reveelProgramId,
      affiliateCode: filmDetail.affiliateCode || null,
    };
    createFilmmakerFilmsShortlink(payload)
      .then((res) => {
        setPromoLink(res.data.shortlink);
      })
      .catch(() => {
        // Skipped
      });
  }, []);

  return (
    <>
      <Dialog open fullWidth maxWidth="lg">
        <DialogTitle>
          <Box>{`Promotions - ${filmDetail.filmTitle}`}</Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>Promote on your own network</Box>
                {!filmPromotionDetails.promotionOptOut && (
                  <Button disableElevation onClick={() => setOpenPromotionPreferences(true)}>
                    <SettingsIcon />
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginTop: '10px',
                }}
              >
                <Grid
                  style={{
                    background: '#e0e0e0',
                    display: 'flex',
                    justifyContent: 'center',
                    height: 30,
                  }}
                >
                  <Box display="flex" justifyContent="center" alignSelf="center">
                    {`${process.env.REACT_APP_FILM_ENDPOINT.split('//')[1]}/${promoLink}`}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box mt={3} display="flex" flexDirection="row">
                    <Grid item xs={12} sm={8} md={8}>
                      <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<FileCopyIcon />}
                        onClick={handleCopyUniqueLink}
                      >
                        Copy Promo Link
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignSelf: 'center',
                        float: 'right',
                      }}
                    >
                      <Box fontSize={17}>
                        <b>User clicks -</b> {filmDetail.userClicks}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={7} md={7} style={{ fontSize: '15px' }}>
                <Box mt={1}>
                  This is your film&apos;s unique link in Reveel. Use this when you post on social
                  media so people can easily find your film in the platform!
                </Box>
              </Grid>
            </Grid>

            {filmPromotionDetails.promotionOptOut && (
              <Grid container item xs={12} spacing={2} style={{ marginTop: '20px' }}>
                <Grid
                  item
                  xs={12}
                  style={{ fontSize: '15px', textAlign: 'left', fontStyle: 'italic' }}
                >
                  <Box>
                    *You have voluntarily opted out of promotions. Your title will not be part of
                    any Reveel campaign either within the platform or in any of our external
                    channels. Users can still find your title via its unique promo link or by Reveel
                    title search.
                  </Box>
                </Grid>
              </Grid>
            )}

            {(filmPromotionDetails.promotionStatus === promotionStatus.INVITED ||
              filmPromotionDetails.promotionStatus === promotionStatus.SAVED) &&
              !filmPromotionDetails.promotionOptOut && (
                <>
                  <Grid container item xs={12} spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} style={{ fontWeight: 'bold', fontSize: '18px' }}>
                      <Box>Co-promote with our marketing team</Box>
                    </Grid>
                    <Grid item xs={12} style={{ fontSize: '15px' }}>
                      <Box>
                        {' '}
                        We would love to feature you on our Filmmaker Highlights! Simply submit any
                        or all of the below assets for our Marketing Team&apos;s evaluation, and we
                        will reach out to you for further details through your provided email
                        address.
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                    <Grid item xs={6} sm={4} md={4} style={{ fontWeight: 'bold' }}>
                      <Box>Video interview</Box>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Box>
                        Record yourself answering questions about your film using our prompts
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={1} md={1} />
                    <Grid item xs={12} sm={10} md={10} style={{ padding: '15px 0px' }}>
                      <div style={{ marginBottom: '20px' }}>
                        <Copromote
                          title="For the director"
                          interviewType="video"
                          filmDetail={filmDetail}
                          roles="director"
                          promptData={promptsData.videoDirector}
                          assetUrl={promotionAssets.videoDirector[0]?.fileUrl}
                          promotionSubmissionId={promotionAssets.videoDirector[0]?.id}
                          assetsDataCallback={assetsDataCallback}
                          type="videoDirector"
                        />
                      </div>
                      <div>
                        <Copromote
                          title="For the cast"
                          interviewType="video"
                          filmDetail={filmDetail}
                          roles="cast"
                          promptData={promptsData.videoCast}
                          assetUrl={promotionAssets.videoCast[0]?.fileUrl}
                          promotionSubmissionId={promotionAssets.videoCast[0]?.id}
                          assetsDataCallback={assetsDataCallback}
                          type="videoCast"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} />
                  </Grid>

                  <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                    <Grid item xs={6} sm={4} md={4} style={{ fontWeight: 'bold' }}>
                      <Box>Written interview</Box>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Box>Answer interview questions in written format</Box>
                    </Grid>

                    <Grid item xs={12} sm={1} md={1} />
                    <Grid item xs={12} sm={10} md={10} style={{ padding: '15px 0px' }}>
                      <div style={{ marginBottom: '20px' }}>
                        <Copromote
                          title="For the director"
                          interviewType="written"
                          filmDetail={filmDetail}
                          roles="director"
                          promptData={promptsData.writtenDirector}
                          assetUrl={promotionAssets.writtenDirector[0]?.fileUrl}
                          promotionSubmissionId={promotionAssets.writtenDirector[0]?.id}
                          assetsDataCallback={assetsDataCallback}
                          type="writtenDirector"
                        />
                      </div>
                      <div>
                        <Copromote
                          title="For the cast"
                          interviewType="written"
                          filmDetail={filmDetail}
                          roles="cast"
                          promptData={promptsData.writtenCast}
                          assetUrl={promotionAssets.writtenCast[0]?.fileUrl}
                          promotionSubmissionId={promotionAssets.writtenCast[0]?.id}
                          assetsDataCallback={assetsDataCallback}
                          type="writtenCast"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} />
                  </Grid>

                  <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                    <Grid item xs={6} sm={4} md={4} style={{ fontWeight: 'bold' }}>
                      <Box>Other files</Box>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Box>
                        If you have other videos or images that you would want to share with us,
                        please upload below
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} />
                    <Grid item xs={12} sm={7} md={7} style={{ marginLeft: '28px' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 5,
                        }}
                      >
                        <VideoUpload
                          caption="Upload video, image, or document"
                          onUploadComplete={(v) => onUploadComplete(v)}
                          programId={filmDetail.reveelProgramId}
                          filmmakerId={filmDetail.filmmakerId}
                          promotionsAsset
                          showSnackbarMessage={showSnackbarMessage}
                        />
                      </div>
                    </Grid>

                    <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                      <Grid item xs={12} sm={4} md={4} />
                      <Grid item xs={12} sm={7} md={7}>
                        {promotionAssets.otherFiles.map((item, index) => (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              backgroundColor: '#EDEFF1',
                              width: '99%',
                              margin: '2px 0px 2px 28px',
                              padding: '2px 8px',
                            }}
                          >
                            <Box>{item.fileName}</Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ cursor: 'pointer', color: 'grey' }}
                              onClick={() => handleRemoveFile(item.id, index)}
                            >
                              <CloseIcon />
                            </Box>
                          </Box>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={1} md={1} />
                    </Grid>

                    <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                      <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                        <Box>Notes</Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          label="Enter value"
                          variant="outlined"
                          onChange={(e) => {
                            setNotes(e.target.value);
                            if (e.target.value.length <= 255) {
                              setErrorNotes(false);
                            }
                          }}
                          value={notes}
                          error={!!errorNotes}
                          helperText={
                            errorNotes &&
                            'Please enter the value must be less than or equal to 255 characters.'
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

            {filmPromotionDetails.promotionStatus === promotionStatus.SUBMMITED &&
              !filmPromotionDetails.promotionOptOut && (
                <>
                  <Grid container item xs={12} spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} style={{ fontWeight: 'bold', fontSize: '18px' }}>
                      <Box>Co-promote with our marketing team</Box>
                    </Grid>
                    <Grid item xs={12} style={{ fontSize: '15px' }}>
                      <Box>
                        {' '}
                        Thank you for your interest in co-promoting with us! Our team will review
                        your submissions and we will reach out via your provided email address for
                        further details.
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                      <Box>Your submitted files</Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <SubmittedView
                        filmDetail={filmDetail}
                        promotionAllAssets={promotionAllAssets}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2} style={{ fontSize: '15px' }}>
                    <Grid item xs={6} sm={3} md={3} style={{ fontWeight: 'bold' }}>
                      <Box>Your notes</Box>
                    </Grid>
                    <Grid item xs={6} sm={9} md={9}>
                      <Box>{promotionAllAssets[0]?.promotionNotes || '-'}</Box>
                    </Grid>
                  </Grid>
                </>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {filmPromotionDetails.promotionStatus !== promotionStatus.SUBMMITED &&
              !filmPromotionDetails.promotionOptOut &&
              (promotionAssets.videoDirector.length > 0 ||
                promotionAssets.videoCast.length > 0 ||
                promotionAssets.writtenDirector.length > 0 ||
                promotionAssets.writtenCast.length > 0 ||
                promotionAssets.otherFiles.length > 0) && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    style={{ marginRight: 10 }}
                    onClick={() => handleSaveSubmitNow()}
                  >
                    Save and Submit Now
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="button"
                    onClick={() => handleSaveFinishLater()}
                    style={{ marginRight: 10 }}
                  >
                    Save and Finish Later
                  </Button>
                </Box>
              )}
            <Box />

            <Box>
              <Button variant="contained" disableElevation onClick={() => closeModal(false)}>
                Back
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>

      {openPromotionPreferences && (
        <PromotionPreferences
          closePromotionPreferencesDialog={closePromotionPreferencesDialog}
          showConfirmDialog={handleShowConfirmDialog}
          filmDetail={filmDetail}
        />
      )}

      {showConfirmDialog.promotionPreferences && (
        <ConfirmDialog
          title="Confirm Promotions Preferences"
          message="Are you sure? This action cannot be undone."
          approveButtonText={"Yes, I'm sure"}
          closeButtonText="Back"
          onClose={() => {
            setShowConfirmDialog({ ...showConfirmDialog, promotionPreferences: false });
          }}
          onApprove={() => confirmPromotionPreferences()}
        />
      )}

      {showConfirmDialog.saveSubmitNow && (
        <ConfirmDialog
          title="Confirm Submission"
          message="Submitting now will lock all submissions and you will not be able to add or edit promotion files for this film via the portal. Our team will reach out to you for further details or if there are any issues."
          approveButtonText="Submit Now"
          closeButtonText="Back"
          onClose={() => {
            setShowConfirmDialog({ ...showConfirmDialog, saveSubmitNow: false });
          }}
          onApprove={() => confirmsaveSubmitNow()}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

Promotions.propTypes = {
  filmDetail: PropTypes.objectOf.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Promotions;
