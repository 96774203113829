import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown, CloudUpload } from '@material-ui/icons';
import axios from 'axios';
import { getAWSUploadUrl } from '../../services/Assets';
import ConfirmDialog from '../ConfirmDialog';
import SnackbarMessage from '../SnackbarMessage';
import ViewVideo from './ViewVideo';
import { promotionSubmissionType } from '../../config/Constants';
import { removePromotionAssetById, uploadFilmmakerPromotionAsset } from '../../services/Filmmakers';
import { getCurrentTimestamp } from '../../utils/Datetime';

const UploadPromotionAsset = ({
  name,
  caption,
  filmmakerId,
  programId,
  role,
  value,
  onUploadComplete,
  promotionSubmissionId,
  assetsDataCallback,
  type,
}) => {
  const hiddenFileInput = useRef(null);
  const buttonId = Date.now();

  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [viewModel, setViewModel] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setVideoUrl(value);
  }, [value]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
    setProgress(Math.round((p.loaded / p.total) * 100));
  };

  const onFinish = async (data, file) => {
    const URL = data.signedUrl.split('?').shift();
    setProgress(100);
    setProgress(0);
    setFileSelected(false);
    setVideoUrl(URL);
    setIsFileUploading(false);

    const payload = {
      filmmakerId,
      programId,
      type:
        caption === 'video'
          ? promotionSubmissionType.VIDEO_INTERVIEW_FILE
          : promotionSubmissionType.WRITTEN_INTERVIEW_FILE,
      fileName: file.name,
      role,
      fileUrl: URL,
      promotionSubmissionId: promotionSubmissionId || null,
    };
    uploadFilmmakerPromotionAsset(payload)
      .then((res) => {
        onUploadComplete({ videoUrl: URL, id: res?.data, data: payload });
      })
      .catch(() => {
        setSnackbarMeesage({
          message: 'Something went wrong. Please try again later.',
          type: 'error',
          show: true,
        });
      });
    hiddenFileInput.current.value = null;
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          onProgress(p);
        },
      });
      onFinish(data, file);
    } catch {
      onError();
    }
  };

  useEffect(() => {
    if (downloading) {
      fetch(videoUrl)
        .then((resp) => resp.blob())
        .then((blobobject) => {
          const blob = window.URL.createObjectURL(blobobject);
          const anchor = document.createElement('a');
          anchor.style.display = 'none';
          anchor.href = blob;
          anchor.download = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);
          document.body.appendChild(anchor);
          anchor.click();
          window.URL.revokeObjectURL(blob);
          setDownloading(false);
        })
        .catch(() => {
          setDownloading(false);
          window.open(videoUrl, '_blank');
        });
    }
  }, [downloading]);

  const downloadFile = () => {
    setDownloading(true);
  };

  const uploadS3ApiCall = (file) => {
    setHasError(false);
    let resp;
    let error = false;

    const fileExtension = file?.name.split('.')[file?.name.split('.').length - 1];
    const currentTime = getCurrentTimestamp();
    const fileName = `${filmmakerId}_${currentTime}.${fileExtension}`;
    const params = {
      objectName: fileName,
      contentType: file.type,
      path: `films/${resp?.data}/assets/`,
    };
    if (!error) {
      getAWSUploadUrl(params)
        .then((res) => {
          res.id = resp?.data;
          uploadFile(res, file);
          setFileSelected(true);
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Something went wrong.');
        });
    }
    error = false;
  };

  const handleChange = async (e) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setHasError(false);
    const file = e.target.files[0];
    if (file) {
      if (name === 'video' && file?.type?.split('/')[0] === 'video') {
        uploadS3ApiCall(file);
      } else if (name === 'video') {
        setSnackbarMeesage({
          message: `Please select video file only.`,
          type: 'error',
          show: true,
        });
      } else if (
        name === 'answers' &&
        (file?.type?.split('/')[1] === 'pdf' ||
          file?.type?.split('/')[1] === 'msword' ||
          file?.type?.split('/')[1] ===
            'vnd.openxmlformats-officedocument.wordprocessingml.document')
      ) {
        uploadS3ApiCall(file);
      } else if (name === 'answers') {
        setSnackbarMeesage({
          message: `Please select pdf, doc or docx file only.`,
          type: 'error',
          show: true,
        });
      }
    }
  };

  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFile = () => {
    setProcessing(true);
    removePromotionAssetById(promotionSubmissionId)
      .then((res) => {
        if (res.success) {
          setSnackbarMeesage({
            show: true,
            type: 'success',
            message: `${name === 'answers' ? 'File' : 'Video'} removed successfully.`,
          });
          setVideoUrl('');
          setConfirmDialog(false);
          setProcessing(false);
        }
        assetsDataCallback(type);
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      <input
        accept={name === 'video' ? 'video/*' : '.doc, .docx, .pdf'}
        id={`upload-vid-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading}
      />

      {videoUrl ? (
        <div>
          <Button
            aria-controls={name}
            aria-haspopup="true"
            variant="outlined"
            fullWidth
            endIcon={<ArrowDropDown />}
            onClick={handleManageClick}
            style={{ marginBottom: 20, color: 'green', borderColor: 'green' }}
          >
            {downloading && (
              <CircularProgress color="inherit" size={20} style={{ marginRight: '5px' }} />
            )}{' '}
            {`Manage ${name === 'answers' ? 'File' : caption}`}
          </Button>
          <Menu
            id={name}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {name === 'video' && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setViewModel(true);
                }}
                button
              >
                {`Preview ${name === 'answers' ? 'File' : 'Video'}`}
              </MenuItem>
            )}
            {name === 'answers' && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  downloadFile();
                }}
                button
                disabled={downloading}
              >
                {downloading && (
                  <CircularProgress color="inherit" size={20} style={{ marginRight: '5px' }} />
                )}{' '}
                {`Download ${name === 'answers' ? 'File' : 'Video'}`}
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                handleClose();
                setConfirmDialog(true);
              }}
              button
            >
              {`Remove ${name === 'answers' ? 'File' : 'Video'}`}
            </MenuItem>

            <label htmlFor={`upload-vid-button-${buttonId}`}>
              <MenuItem button>
                {!fileSelected && `Upload New ${name === 'answers' ? 'File' : 'Video'}`}
                {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
                {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                {hasError && 'Error'}
              </MenuItem>
            </label>
          </Menu>
        </div>
      ) : (
        <label htmlFor={`upload-vid-button-${buttonId}`}>
          <Button
            fullWidth
            onClick={handleClick}
            variant="outlined"
            color={hasError ? 'primary' : ''}
            startIcon={<CloudUpload />}
            title={errorMessage}
          >
            {!fileSelected && `Upload ${caption}`}
            {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
            {hasError && (errorMessage || ' Error')}
          </Button>
          {isFileUploading && (
            <div style={{ marginBottom: 20, color: 'grey' }}>
              Do not close the window or save the details until whole file is uploaded.
            </div>
          )}
        </label>
      )}

      {confirmDialog && (
        <ConfirmDialog
          title={`Remove ${name === 'answers' ? 'File' : 'Video'}`}
          message={`Do you want to delete this  ${
            name === 'answers' ? 'file' : 'video'
          }? This action can not be undone.`}
          onClose={() => {
            setConfirmDialog(false);
          }}
          onApprove={() => removeFile()}
          approveProcessing={processing}
        />
      )}

      {viewModel && (
        <ViewVideo
          caption={caption}
          url={videoUrl}
          viewModelOpen={viewModel}
          handleViewModel={(flag) => setViewModel(flag)}
          programId={programId}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadPromotionAsset.propTypes = {
  name: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  filmmakerId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  promotionSubmissionId: PropTypes.number.isRequired,
  assetsDataCallback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default UploadPromotionAsset;
