import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import listingStyle from '../../theme/styles/pages/Listing';

const useStyles = makeStyles(listingStyle);

const SubmittedView = ({ filmDetail, promotionAllAssets }) => {
  const classes2 = useStyles();

  const handleDownload = (row, fileName) => {
    const fileExtension = row?.fileUrl.split('.').pop();
    fetch(row?.fileUrl)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error('Network response was not ok');
        }
        return resp.blob();
      })
      .then((blobobject) => {
        const url = window.URL.createObjectURL(blobobject);
        const link = document.createElement('a');
        link.href = url;
        link.download = row.type === 3 ? fileName : `${fileName}.${fileExtension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        //  Skipped
      });
  };

  const createFileName = (row) => {
    if (row.type === 1) {
      return `${filmDetail.filmmakerName} - ${filmDetail.filmTitle} - Video interview - ${row.role}`;
    }
    if (row.type === 2) {
      return `${filmDetail.filmmakerName} - ${filmDetail.filmTitle} - Written interview - ${row.role}`;
    }
    return `${filmDetail.filmmakerName} - ${filmDetail.filmTitle} - Other files - ${row.fileName}`;
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8%',
        marginTop: '20px',
        marginBottom: '20px',
      }}
    >
      {promotionAllAssets &&
        promotionAllAssets.length > 0 &&
        promotionAllAssets.map((row) => (
          <Box itemType="span" padding="5px">
            <Box
              className={classes2.styledLink}
              display="inline"
              onClick={() => {
                handleDownload(row, createFileName(row));
              }}
            >
              {createFileName(row)}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

SubmittedView.propTypes = {
  filmDetail: PropTypes.objectOf.isRequired,
  promotionAllAssets: PropTypes.arrayOf.isRequired,
};

export default SubmittedView;
