// Get current time stamp
export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);
export const convertTimestampToDate = (val) => {
  const d = new Date(Number(val) * 1000);
  return d.toUTCString();
};

export const secondsToHms = (input) => {
  if (input === 0) {
    return '00:00:00.00';
  }
  const duration = input > 0 ? input.split('.')[0] : 0;
  const milliseconds = input > 0 ? input.split('.')[1] : 0;

  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  let seconds = duration - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  let newMilliseconds = '00';
  if (Number(milliseconds) <= 25 && Number(milliseconds) > 0) {
    newMilliseconds = '25';
  } else if (Number(milliseconds) <= 50 && Number(milliseconds) > 0) {
    newMilliseconds = '50';
  } else if (Number(milliseconds) <= 75 && Number(milliseconds) > 0) {
    newMilliseconds = '75';
  }

  return `${hours}:${minutes}:${seconds}.${newMilliseconds}`;
};

export const hmsToSeconds = (input) => {
  const milliseconds = input.split('.')[1] || 0;
  const timeString = input.split('.')[0];
  const arr = timeString.split(':');
  const seconds = arr[0] * 3600 + arr[1] * 60 + +arr[2];

  let newMilliseconds = '00';
  if (Number(milliseconds) <= 25 && Number(milliseconds) > 0) {
    newMilliseconds = '25';
  } else if (Number(milliseconds) <= 50 && Number(milliseconds) > 0) {
    newMilliseconds = '50';
  } else if (Number(milliseconds) <= 75 && Number(milliseconds) > 0) {
    newMilliseconds = '75';
  }

  return parseFloat(`${seconds}.${newMilliseconds}`);
};

export const roundUpMilis = (val) => {
  const inputToString = val.toString().split(':');
  const hrPos = inputToString[0];
  const minPos = inputToString[1];
  const secPos = inputToString[2];
  const roundedSec = (Math.ceil(Number(secPos) * 4) / 4).toFixed(2);
  let roundedSecStr = roundedSec.toString();
  if (roundedSecStr.split('.')[0].length === 1) {
    roundedSecStr = `0${roundedSec}`;
  }
  return `${hrPos}:${minPos}:${roundedSecStr}`;
};
