import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ErrorWrapper from '../../layout/Error';
import ErrorStyle from '../../theme/styles/Error';
import isPageInIframe from '../../utils/IsPageInIFrame';
import { updateContractStatus } from '../../services/Filmmakers';
import { filmmakerProgramStatus } from '../../config/Constants';

const useStyles = makeStyles(ErrorStyle);

const ContractSignedFrame = () => {
  const classes = useStyles();
  const { token } = useParams();

  useEffect(() => {
    const doCheck = isPageInIframe();
    if (doCheck) {
      const data = {
        newStatus: filmmakerProgramStatus.CONTRACT_SIGNED,
      };
      updateContractStatus(token, data)
        .then(() => {
          window.parent.location.href = '/';
        })
        .catch(() => {
          window.parent.location.href = '/';
        });
    }
  }, []);

  return (
    <ErrorWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <Container component="div" className={classes.errorPageText}>
            <Typography component="p">
              Contract has been signed successfully. <br /> You can download your agremeent by
              logging into your account.
            </Typography>
          </Container>
        </Container>
      </Grid>
    </ErrorWrapper>
  );
};

export default ContractSignedFrame;
