import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Validations from '../../utils/Validations';
import PrivateWrapper from '../../layout/Private';
import loginStyle from '../../theme/styles/pages/Login';
import RoutePaths from '../../config/Routes';
import SnackbarMessage from '../../components/SnackbarMessage';
import { changeFilmmakerPassword } from '../../services/Filmmakers';

const useStyles = makeStyles(loginStyle);

const ChangePassword = () => {
  const pageName = 'Change Password';
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, control, reset } = useForm();
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const submitData = (data) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    changeFilmmakerPassword(data)
      .then(() => {
        setProcessing(false);
        history.push(RoutePaths.LOGOUT);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data.message,
          type: 'error',
          show: true,
        });
        reset();
        setProcessing(false);
      });
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <Container maxWidth="sm">
        <Box m={3}>
          <Box mb={2}>
            <Typography component="h1" variant="h5">
              Change your account password
            </Typography>
            <Typography variant="caption" className={classes.authPageText}>
              You will be logout once you successfully changed your password
            </Typography>
          </Box>
          <form id="change-password" onSubmit={handleSubmit(submitData)}>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_PASSWORD}
                name="oldPassword"
                id="oldPassword"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Current Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_PASSWORD}
                name="newPassword"
                id="newPassword"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="New Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_PASSWORD}
                name="confirmPassword"
                id="confirmPassword"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Confirm Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.authSubmitBtn}
              endIcon={processing && <CircularProgress size={25} />}
              disabled={processing}
            >
              Submit
            </Button>
            <Button
              fullWidth
              variant="contained"
              className={classes.authSubmitBtn}
              onClick={() => history.push(RoutePaths.PROFILE)}
            >
              Back To Profile
            </Button>
          </form>
        </Box>
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Container>
    </PrivateWrapper>
  );
};

export default ChangePassword;
