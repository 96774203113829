import { createMuiTheme } from '@material-ui/core/styles';

// Extend default MuiTheme
const Default = createMuiTheme({
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      main: '#ce0e2d',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        color: '#ce0e2d',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
});

export default Default;
