import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import sidebarStyle from '../../theme/styles/components/SidebarNavs';
import PrivateWrapper from '../../layout/Private';
import { affiliateCounters } from '../../services/Filmmakers';

const useStyles = makeStyles(sidebarStyle);
const OverviewPage = () => {
  const pageName = 'Dashboard';
  const classes = useStyles();

  const [counters, setCounters] = useState({
    submittedFilmsCount: 0,
    publishedFilmsCount: 0,
    shortlinksClick: 0,
    registrationCount: 0,
    trackingPeriod: 0,
  });

  const apiCall = async () => {
    const user = window.localStorage.getItem('user');
    const userNameObj = user ? JSON.parse(user) : null;

    affiliateCounters(userNameObj.id)
      .then((res) => {
        setCounters({
          submittedFilmsCount: res.data.submittedFilmsCount || 0,
          publishedFilmsCount: res.data.publishedFilmsCount || 0,
          shortlinksClick: res.data.shortlinksClick,
          registrationCount: res.data.registrationCount,
          trackingPeriod: res.data.trackingPeriod,
        });
      })
      .catch(() => {
        // Skipped
      });
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <PrivateWrapper pageName={pageName}>
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={2} sm={2} />
        <Grid item xs={12} md={8} sm={8}>
          <Box
            bgcolor="#ce0e2d"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="30px"
          >
            <Typography variant="h6" className={classes.menuText}>
              Your Films
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} sm={2} />
      </Grid>

      <Grid container spacing={6} style={{ marginTop: 5 }}>
        <Grid item xs={12} md={2} sm={2} />
        <Grid item xs={12} md={4} sm={4}>
          <Card style={{ backgroundColor: '#F2F4F6', height: '180px' }}>
            <CardContent
              style={{
                textAlign: 'center',
                minHeight: '180px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Submitted Films
              </Typography>
              <Typography variant="h3" component="div">
                {counters.submittedFilmsCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} sm={4}>
          <Card style={{ backgroundColor: '#F2F4F6', height: '180px' }}>
            <CardContent
              style={{
                textAlign: 'center',
                minHeight: '180px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Published Films
              </Typography>
              <Typography variant="h3" component="div">
                {counters.publishedFilmsCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={2} sm={2} />
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 50 }}>
        <Grid item xs={12} md={2} sm={2} />
        <Grid item xs={12} md={8} sm={8}>
          <Box
            bgcolor="#ce0e2d"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="30px"
          >
            <Typography variant="h6" className={classes.menuText}>
              Promotion Stats
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} sm={2} />
      </Grid>

      <Grid container spacing={6} style={{ marginTop: 5 }}>
        <Grid item xs={12} md={2} sm={2} />
        <Grid item xs={12} md={4} sm={4}>
          <Card style={{ backgroundColor: '#F2F4F6', height: '180px' }}>
            <Tooltip
              title={<h2>Total clicks on your films&#39; promo links</h2>}
              arrow
              placement="bottom"
              fontSize={24}
            >
              <CardContent
                style={{
                  textAlign: 'center',
                  minHeight: '180px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Total Clicks
                </Typography>
                <Typography variant="h3" component="div">
                  {counters.shortlinksClick}
                </Typography>
              </CardContent>
            </Tooltip>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} sm={4}>
          <Card style={{ backgroundColor: '#F2F4F6', height: '180px' }}>
            <Tooltip
              title={
                <h2>{`Number of users who registered within ${counters.trackingPeriod} days after clicking on your link`}</h2>
              }
              arrow
              placement="bottom"
            >
              <CardContent
                style={{
                  textAlign: 'center',
                  minHeight: '180px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Total Signups
                </Typography>
                <Typography variant="h3" component="div">
                  {counters.registrationCount}
                </Typography>
              </CardContent>
            </Tooltip>
          </Card>
        </Grid>
        <Grid item xs={12} md={2} sm={2} />
      </Grid>
    </PrivateWrapper>
  );
};

export default OverviewPage;
