import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SearchableAutocomplete from '../SearchableAutocomplete';
import ConfirmDialog from '../ConfirmDialog';
import { getCountriesList } from '../../services/Filmmakers';
import Validations from '../../utils/Validations';
import { filmmkaerFilmsStatus } from '../../config/Constants';

const GeoRestrictionModal = ({ closeModal, onSuccess, geoData, filmStatus }) => {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [type, setType] = useState(geoData?.type || 'include');

  const { handleSubmit, control, reset, setValue } = useForm();

  const loadTypes = (inputText, callBack) => {
    getCountriesList(inputText).then((res) => {
      callBack(res.data.rows.map((r) => ({ id: r.code, name: r.countryName })));
    });
  };

  const handleDialog = () => {
    reset();
    closeModal();
  };

  const submitData = (data) => {
    onSuccess({ ...data, type });
    closeModal();
  };

  useEffect(() => {
    if (geoData && Array.isArray(geoData) && geoData[0]?.countries.length > 0) {
      setType(geoData && geoData.length > 0 ? geoData[0].type : 'include');
      setValue('countries', geoData && geoData.length ? geoData[0]?.countries : []);
    } else if (geoData && Object.keys(geoData).length > 0) {
      setValue('countries', geoData.countries);
    }
  }, []);

  return (
    <>
      <Dialog open fullWidth maxWidth="sm" onClose={handleDialog}>
        <DialogTitle>Manage Availability</DialogTitle>
        <DialogContent>
          <form id="manage-availability" onSubmit={handleSubmit(submitData)}>
            <FormControl component="fieldset">
              <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>Select Type</p>
              <RadioGroup
                style={{ flexDirection: 'row' }}
                aria-label="type"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel
                  value="include"
                  control={<Radio />}
                  label="Include"
                  disabled={
                    filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
                    filmStatus === filmmkaerFilmsStatus.DEACTIVATED ||
                    filmStatus === filmmkaerFilmsStatus.REJECTED
                  }
                />
                <FormControlLabel
                  value="exclude"
                  control={<Radio />}
                  label="Exclude"
                  disabled={
                    filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
                    filmStatus === filmmkaerFilmsStatus.DEACTIVATED ||
                    filmStatus === filmmkaerFilmsStatus.REJECTED
                  }
                />
              </RadioGroup>
            </FormControl>
            <p style={{ marginBottom: '20px', fontWeight: 'bold' }}>Select Countries</p>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="countries"
              id="countries"
              defaultValue={geoData?.countries || []}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SearchableAutocomplete
                  label="Countries"
                  value={value}
                  loadOptions={loadTypes}
                  onChange={onChange}
                  error={!!error}
                  disabled={
                    filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
                    filmStatus === filmmkaerFilmsStatus.DEACTIVATED ||
                    filmStatus === filmmkaerFilmsStatus.REJECTED
                  }
                  helperText={error ? error.message : null}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          {filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              form="manage-availability"
            >
              Submit
            </Button>
          )}

          <Button variant="contained" disableElevation onClick={handleDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isConfirmation && (
        <ConfirmDialog
          title="Manage Availability"
          message="Availability data will be cleared for this program. Do you want to continue?"
          approveButtonText="Yes"
          closeButtonText="No"
          onClose={() => {
            setIsConfirmation(false);
          }}
          onApprove={() => {
            setIsConfirmation(false);
          }}
        />
      )}
    </>
  );
};

GeoRestrictionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  geoData: PropTypes.objectOf.isRequired,
  filmStatus: PropTypes.number.isRequired,
};

export default GeoRestrictionModal;
