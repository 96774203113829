import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PublicWrapper from '../../layout/Public';
import { viewContractByToken } from '../../services/Filmmakers';

const ContractPage = () => {
  const pageName = 'Contract';

  const { token } = useParams();
  const [embedUrl, setEmbedUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
    viewContractByToken(token)
      .then((res) => {
        setEmbedUrl(res.data.contractEmbedUrl);
      })
      .catch(({ response }) => {
        setErrorMessage(
          response?.data?.message || 'Something went wrong while fetching contract details.'
        );
      });
  }, []);

  return (
    <PublicWrapper>
      {errorMessage ? (
        <Grid container spacing={0} align="center" justify="center" direction="column">
          <Container maxWidth="xs">
            <Container component="div">
              <Typography variant="h4">Whoops...</Typography>
              <Typography variant="h6">{errorMessage}</Typography>
            </Container>
          </Container>
        </Grid>
      ) : (
        <iframe title={pageName} src={embedUrl} frameBorder="0" width="100%" />
      )}
    </PublicWrapper>
  );
};

export default ContractPage;
