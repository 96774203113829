import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import cominsoonStyle from '../../theme/styles/components/ComingSoon';

const useStyles = makeStyles(cominsoonStyle);

// Coming soon message
const Terms = () => {
  const classes = useStyles();

  return (
    <div className={classes.infoContainer} style={{ flexDirection: 'column' }}>
      <Box mb={8} mt={5}>
        <Grid container spacing={0} align="center" justifyContent="center" direction="column">
          <Container maxWidth="md">
            <Container component="div" className={classes.infoPageText}>
              <Typography component="h1" variant="h3">
                <Box sx={{ fontWeight: 'bold' }}>Terms and Conditions</Box>
              </Typography>
            </Container>
          </Container>
          <Box>
            <Typography component="h4" variant="body2">
              Last updated: October 28, 2024
            </Typography>
          </Box>
        </Grid>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography component="h5" variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Introduction
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We appreciate your decision to use the Reveel platform, along with the array of
                products, services, and functionalities we offer as components of the platform
                (together referred to as the “Service”).
              </Typography>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  What Do These Terms Cover?
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                This document sets forth the terms and conditions governing your use of our
                platform, which can be accessed at www.reveel.net, through our mobile app, and via
                any other applications that facilitate access to the Service. It covers both our
                video-on-demand services and the content monetisation features available on the
                platform (hereafter referred to as &quot;our site&quot; or &quot;the site&quot;).
              </Typography>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Who We Are and How to Reach Us
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Reveel.net is owned and operated by Reveel Entertainment Inc. (&quot;Reveel,&quot;
                &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), a legal entity registered in
                Delaware. Contact us via email at support@reveel.net or through the website at
                reveel.net.
                <Box mt={2}>
                  <Typography component="h4" variant="body2">
                    We offer a free, advertisement-supported and tiered SVOD platform designed to
                    simplify how creators monetise their content, ensuring creative liberty while
                    highlighting superior quality content. Reveel empowers content creators
                    (Creators) to produce their finest work and monetise it effectively, all the
                    while offering viewers (Viewers) an access to a free and top-tier streaming
                    service that showcases only the best creators. These terms are applicable to
                    Creators, Viewers, and any other individuals utilizing this platform or mobile
                    application (referred to hereinafter individually and collectively as you,
                    your). For any inquiries, our contact email is support@reveel.net.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography component="h4" variant="body2">
                    By using our platform, you confirm that you accept these terms of use and that
                    you agree to comply with them. If you do not agree to these terms you must not
                    use our platform.
                  </Typography>
                </Box>
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Updates to These Terms
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We reserve the right to modify these terms at our discretion. We encourage you to
                review these terms each time you plan to use our platform to stay informed about the
                conditions that apply at that moment.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Adjustments to Our Platform
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Periodically, we may modify our platform to better align with the needs of our users
                and our strategic priorities. These adjustments could involve the editing, addition,
                removal, or modification of the features and services we provide.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Platform Availability
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We cannot assure continuous or uninterrupted access to our platform, or any content
                within it. For business and operational considerations, we may need to suspend,
                withdraw, or limit access to our platform or any part of it. Whenever possible, we
                will attempt to provide reasonable notice of any such actions. It is your
                responsibility to ensure that anyone accessing our platform via your internet
                connection is informed about these terms of use and any other relevant terms and
                conditions, and that they adhere to them.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Overview of Registration and Usage of Reveel Services
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Our Services encompass the website accessible at www.reveel.net, our software
                applications, mobile apps, and any other products or services we may provide. By
                logging in, registering, downloading, accessing, or utilizing the Services, you
                affirm that you are at least 18 years old; you are entering into a legal agreement
                with us concerning your use of the Services; and you acknowledge having read,
                understood, and agreed to adhere to these terms.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  If you access or use the Services on behalf of a company, partnership, or any
                  other form of organization, you declare and assure that you: (i) have the proper
                  authorization for such actions and possess the authority to legally bind that
                  entity to these terms, including the granting of licenses described herein; and
                  (ii) accept these terms on behalf of the entity.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  You shall bear full responsibility and agree to indemnify us against any
                  liabilities arising from claims related to employment or worker status initiated
                  by you against us. As a service provider, our role is to offer a platform that
                  supports Creators in delivering on-demand Content to Viewers. Although we collect
                  payments from some paid tiered Viewers and possibly other third parties as an
                  agent for Creators. The contractual agreement regarding the Platform is
                  exclusively between the Creator and the Viewer. We are not a party to this
                  contract and do not bear any liability for issues stemming from or related to it.
                  The Creator alone is accountable for the quality of the Content they provide, as
                  well as for addressing any claims, complaints, or other matters that arise from
                  the contract with the Viewer.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Account Information for Creators, Viewers, and Other Users
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Browsing our site does not require you to have an account. However, to view content
                or engage with features like free or paid subscriptions, live events and other
                purchases, account registration is necessary.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  When creating an account, you&apos;ll need to provide certain details, such as a
                  username and password. It&apos;s your responsibility to keep your account details
                  confidential, including your password, and to prevent unauthorized access to your
                  computer. If we provide you with, or you select, a user identification code,
                  password, or any other security information, you must keep this information secret
                  and not share it with anyone else. We may disable any user identification code or
                  password at our discretion if we believe you&apos;ve violated any of these terms.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  If you suspect or know that someone else has access to your user identification
                  code or password, you should inform us immediately at support@reveel.net . We have
                  the authority to delete or screen any content on Reveel at any time and without
                  prior notice, especially if the content breaches these terms or applicable laws.
                  Permissions and Restrictions
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  You are allowed to access and use the Service as it&apos;s provided to you, as
                  long as you adhere to these terms and applicable laws. You can view content for
                  your personal, non-commercial use under the following conditions.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  You are prohibited from: Accessing, reproducing, downloading, distributing,
                  transmitting, broadcasting, displaying, selling, licensing, modifying, or
                  otherwise using any part of the Service or Content unless: (i) explicitly allowed
                  by the Service; (ii) you have prior written permission from Reveel and, when
                  relevant, from the rights holders (including Creators); or (iii) it&apos;s
                  permitted by law.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Bypassing, disabling, engaging in fraud, or interfering with the Service
                  (including attempting any of these), particularly features related to security or
                  those that: (a) prevent or restrict content copying or usage; or (b) limit the
                  Service or Content usage. (c)Using automated means (like robots, botnets, or
                  scrapers) to access the Service, manipulate view time (D) Collecting or using any
                  personal identification information (such as usernames or faces) unless authorized
                  by that person or allowed under section 1 above. (E) Utilizing the Service for
                  distributing spam or unsolicited commercial content, or for any form of unwanted
                  mass communication. (F)Abusing any reporting, flagging, complaint, dispute, or
                  appeals mechanism, including making unfounded, troublesome, or trivial
                  submissions.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Uploading Content
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                If you possess an account on Reveel, you have the opportunity to upload your Content
                onto the Service by switching to a filmmaker account at no additional charge. This
                allows you to use your Content as a means to promote your content or creative
                endeavors as well as the Reveel Platform. Should you decide to upload Content, it is
                imperative that such Content adheres to these terms as well as applicable laws.
                Specifically, any Content you provide should not infringe on the intellectual
                property rights of third parties (e.g., copyrighted material) unless you have
                secured permission from the rightful owner or are entitled to use it by law. You
                bear full legal responsibility for the Content you upload to the Service. Our
                content moderation efforts aim to uphold a quality standard as specified in our
                Content Guidelines while also identifying and addressing potential infringements and
                abuses, including but not limited to spam, malware, and illegal Content.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Uploading content is done in five steps:
                  <ol>
                    <li>
                      <Box ml={2}>
                        During Initial submission, the Creator will submit a title, a short
                        description, and a copy of the full video (in the case of movies) or up to 3
                        full episodes (in the case of series).
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        The Reveel team will review the initial submission and will either approve
                        or reject the Content for publication on Reveel. If rejected, the process
                        stops in this stage.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        Upon approval of the initial submission, the Creator will upload additional
                        details and assets pertinent to the Content.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        The Reveel team will review each asset. All of the assets will have to be
                        approved before the Content can be published on Reveel.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        Once all assets have been approved, the Content is scheduled to be released
                        on the Reveel platform.
                      </Box>
                    </li>
                  </ol>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  As a Creator, once you have at least one video published or scheduled to be
                  published on Reveel, you will be granted full filmmaker account privileges
                  enabling you to access Payment-related features.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Co-authored Content
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                In the event that you post Content on your Creator&apos;s account that features any
                person other than yourself, or alongside you (this includes instances where the
                individual is not identifiable from the Content) (&quot;Co-Authored Content&quot;),
                you are making a legally binding guarantee to us that every person depicted in any
                Co-Authored Content uploaded to your account is either i) a Creator who is duly
                registered on Reveel; or ii) an adult who has given their consent, and that you have
                confirmed the identity and legal age of each such individual and are prepared to
                supply proof as requested by us at our discretion.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Additionally, you guarantee that you have secured and maintained written
                  permission from every individual featured in your Co-Authored Content, affirming
                  that such individual:
                  <ul>
                    <li>
                      <Box ml={2}>
                        has provided their explicit, informed, and prior consent to be included in
                        the Co-Authored Content; and
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        has agreed to the use of the Co-Authored Content they are part of being
                        uploaded to Reveel.
                      </Box>
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Removal of Content by Reveel
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                If we have a reasonable basis to believe that any of your Content violates this
                Agreement or could potentially harm Reveel, our users, or third parties, we retain
                the right to remove or take down some or all of that Content. We may inform you of
                the rationale behind our action unless we reasonably determine that doing so:
              </Typography>
              <Typography component="h4" variant="body2">
                <ul>
                  <li>
                    <Box ml={2}>
                      would breach legal requirements or follow the order of law enforcement
                      authorities, thereby potentially exposing us or our affiliates to legal
                      liability;
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      could hinder an investigation or affect the integrity or operation of the
                      Service; or
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      might harm any user, other third party, Reveel, or our affiliates.
                    </Box>
                  </li>
                </ul>
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Moderation
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Our Content Guidelines are in place to ensure Reveel remains a welcoming space for
                Viewers, Creators, and sponsors. We will not publish content that goes against these
                guidelines on Reveel.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Personal Use and Content Restrictions:</i> You are expected to use our platform
                  solely for your personal enjoyment and are prohibited from selling, leasing,
                  transferring, or sharing your account or any content obtained through your
                  interactions with Reveel with third parties.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Lawful Usage:</i> Your use of Reveel must strictly comply with all legal
                  requirements and be aimed at lawful activities.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Content Upload and Prohibitions:</i> You are forbidden from uploading, posting,
                  displaying, or publishing any content on Reveel that is illegal, fraudulent,
                  defamatory, hateful, discriminatory, threatening, harassing, or that incites or
                  promotes violence or any illegal acts.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Protection of Minors:</i> You must not exploit, harm, or attempt to exploit or
                  harm individuals under the age of 18 in any way through your use of Reveel,
                  including by exposing them to inappropriate content.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Prohibition of Harmful Conduct:</i> You are not allowed to use Reveel to stalk,
                  bully, abuse, harass, threaten, or intimidate any other user or person.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Misleading or Deceptive Conduct:</i> Your activities on Reveel should not
                  involve any misleading or deceptive actions, or any behavior likely to mislead or
                  deceive other users.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Respect for Intellectual Property:</i> You must honor the intellectual property
                  rights of Creators by not recording, reproducing, sharing, publicly communicating,
                  or otherwise distributing their content without proper authorization.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Respect for Rights:</i> You are to avoid actions that infringe on our rights or
                  the rights of others, including but not limited to intellectual property rights
                  (such as copyright, trademarks, confidential information, and goodwill),
                  personality rights, unfair competition, privacy, and data protection rights.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Impersonation:</i> You must not impersonate us, our staff, another user, or any
                  other person or entity, nor falsely claim or suggest any association, endorsement,
                  or sponsorship between you and us, or any other person or entity.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Accurate Account Information:</i> You must not provide inaccurate registration
                  information for your account or misuse anyone else&apos;s information or content.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Prohibition of Spam and Inauthentic Content:</i> You are strictly banned from
                  posting spam, or any content or using tools or fraudulent methods meant to
                  unnaturally inflate a Creator&apos;s views or interactions, or content that is
                  inauthentic, repetitive, misleading, or of low quality. Viewers or creators found
                  to be involved in any of the above will be permanently banned from the platform.
                  The decisions made by Reveel on expected fraudulent use are at their discretion
                  and final.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Communication Methods:</i> You must not employ alternate media or methods
                  (e.g., codewords or signals) to communicate content that violates this Policy.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Content Usage Limitations:</i> Except as expressly permitted under the Terms of
                  Service, you must not reproduce, print, distribute, attempt to download, modify,
                  create derivative works from, publicly display, publicly perform, republish,
                  download, store, or transmit any content.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Malicious Software:</i> You must not knowingly introduce viruses, trojans,
                  worms, logic bombs, or other materials that are malicious or technologically
                  harmful into content.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Reverse Engineering:</i> It is expressly forbidden to decompile, disassemble,
                  reverse engineer, or otherwise attempt to discover or derive the source code of
                  Reveel.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>System Interference:</i> You must not use Reveel in a way that could harm our
                  systems or security, or interfere with another user&apos;s use of Reveel,
                  including their real-time activities.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  You are expressly forbidden from using the &quot;Reveel&quot; name, its logo, or
                  any related or similar names, logos, product and service designations, designs, or
                  slogans, except as explicitly allowed in the Terms of Service or with our prior
                  written consent. Violating this Policy may lead to the suspension or termination
                  of your account and the forfeiture of access to your earnings, as detailed in the
                  Terms of Service.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Account Suspension and Termination
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Termination by You: At any moment, you can cease using the Service. Additionally,
                you have the option to delete your Reveel account, which will close your profile and
                erase your data permanently. This action is irreversible, meaning your data cannot
                be recovered in the future.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Termination and Suspension by Reveel: Reveel holds the right to either suspend or
                  terminate your account or your access to any or all parts of the Service if: (i)
                  you significantly or habitually violate this agreement; (ii) compliance with a
                  legal obligation or a judicial order necessitates it; or (iii)we have a reasonable
                  belief of actions that result in liability or harm to any user, third party,
                  Reveel, or our affiliates.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Notification of Termination or Suspension: Should your account be terminated or
                  suspended, we will aim to notify you and explain the reasons for Reveel&apos;s
                  decision, unless doing so: (i) would contravene legal requirements or instructions
                  from law enforcement authorities; (ii) could obstruct an ongoing investigation;
                  (iii) might undermine the Service&apos;s integrity, operation, or security; or
                  (iv) could potentially harm any user, third party, Reveel, or our affiliates.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Transactions
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We serve as a clear intermediary for Creators on the Reveel platform by facilitating
                the interface and the collection of payments from Viewers and advertisers to be
                passed on to the Creators. Creators are encouraged, should they choose, to motivate
                Viewers to subscribe, or engage with advertisers and promotions and subscriber
                benefits.. To adhere to our legal and/or licensing commitments to third parties,
                along with managing Viewer data and payments, we gather the necessary information to
                meet our financial obligations to the Creators.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  For Creator transactions to occur, a payment account with the creator&apos;s
                  preferred payment method must be set up on our site with our third party. You
                  grant permission for us and our subsidiary entities to share your payment details
                  with our third-party payment processor to handle your Payment. We have no control
                  over and will not be responsible for any currency exchange rates or fees your
                  choice of payment method or bank may apply.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  The ownership of all content lies with the Creators, and Viewers are prohibited
                  from photographing, filming, recording, taking screenshots, or in any way
                  duplicating the content across any media, or making the content publicly
                  accessible. Transactions between Viewers and Creators are agreements directly
                  between these two parties. While we provide the Reveel platform as a service to
                  facilitate these interactions, we are not liable for the transactions between
                  Viewers and Creators.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Deductions and Payments
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Reveel is dedicated to financially supporting our Creators by sharing a substantial
                portion of the revenue from advertising, subscriptions, and other monetizable
                activities on the platform.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Creator Advertising Revenue Share:</i> Reveel will pay Creators USD 0.20 per
                  hour of their content’s watch time, or their content’s attributed advertising
                  revenue, whichever is higher. Attributed advertising revenue is determined by
                  taking the Creator’s content’s total watch time by Viewers without a paid
                  subscription, divided by the total watch time by Viewers without a paid
                  subscription across all Reveel content, multiplied by Reveel’s net advertising
                  revenue from all content, multiplied by 80%.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Creator Subscriber Revenue Share:</i> If a potential Viewer registers to Reveel
                  within 7 days of clicking a Creator’s unique content promotion link, said Viewer
                  will be recognized as a referral of the Creator, provided that the Creator’s
                  content promotion link was the most recent content promotion link that the Viewer
                  clicked. If this Viewer becomes a paid subscriber anytime in the future, the
                  Creator will receive a 10% share of the actual paid amount by the Viewer per month
                  for as long as the Viewer remains a paid subscriber with Reveel. If the subscribed
                  Viewer requests a chargeback or refund of their subscription payment, Reveel
                  reserves the right to subtract 10% of the chargeback or refund amount from the
                  Creator’s future earnings.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Payout Schedule and Thresholds:</i> Creators are paid monthly, provided they
                  meet a minimum payout threshold of USD 100.00. Earnings below this threshold carry
                  over to the next month until the minimum is reached. All charges are made in U.S.
                  dollars (unless indicated otherwise).
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  <i>Changes to Payment Terms:</i> Reveel reserves the right to adjust payment
                  terms, including revenue share ratios and fees. Creators will be notified of any
                  changes in advance via email and platform notifications.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  By participating on Reveel, Creators legally affirm their commitment to accurately
                  report all payments received to their local tax authority as required by law. In
                  case of tax non-compliance, including failure to report earnings or any
                  tax-related legal issues, creators must Notify Reveel within 7 days via
                  support@reveel.net, and provide detailed information about the non-compliance and
                  steps for rectification. Creators are solely responsible for their tax
                  obligations; Reveel and its affiliates do not offer tax advice, and Creators agree
                  to indemnify Reveel against any tax-related liabilities. Accounts may be
                  terminated in cases of tax non-compliance.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Availability
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Utilizing the Reveel Services necessitates the use of your personal computer, mobile
                device or smart TV in addition to communication with or usage of space on such
                devices. It is your responsibility to bear any Internet connection or mobile fees
                and charges incurred while accessing or utilizing the Reveel Services.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Third-Party Partnerships
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We may earn payments or rewards for directly or indirectly promoting advertisers. By
                using the Reveel Services, you consent to our display of advertisements and
                promotions in any form within the Reveel Services. Additionally, we may enter into
                agreements with third parties that include revenue sharing from advertisements or
                other transaction types. Unless specifically agreed upon, Viewers and Creators have
                no claim to any benefits Reveel accrues from such advertisements, partnerships, or
                promotions. We reserve the right to use and exhibit your name, likeness, clips,
                promos and trailers for the purposes of advertising, publicizing, and promoting your
                content, Reveel and the Reveel Services.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Permissible Use of Materials on Our Platform
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                The Reveel Services are exclusively owned and operated by us. All content,
                information, and other materials on the Reveel Services, apart from User Content as
                outlined below, including but not limited to Reveel’s trademarks and logos, visual
                interfaces, graphics, design, compilation, software, computer code (both source and
                object code), services, text, images, information, data, audio files, other files,
                and their selection and organization (collectively, the &quot;Reveel
                Materials&quot;), are safeguarded by applicable intellectual property and
                proprietary rights and laws. Ownership of all Reveel Materials rests with us and/or
                our third-party licensors. By accepting these terms, you are provided with a
                personal, restricted, revocable, non-transferable, non-sublicensable, limited
                license to access and utilize the Reveel Services and Reveel Materials strictly as
                per the provisions mentioned herein, unless explicitly stated otherwise by us in
                writing.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  Reveel reserves all rights not specifically granted in these terms. Any employment
                  of the Reveel Services or Reveel Materials beyond the scope explicitly authorized
                  within these terms, without prior written consent from Reveel, is expressly
                  prohibited and could violate intellectual property laws or other statutes.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  User Content
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Creators bear the responsibility for all images, text, graphics, recordings, and
                other content featured in their videos and/or on the Reveel URL page linked to their
                video, and any content communicated to Viewers as part of the video or through other
                activities. Similarly, Viewers and other users of the Reveel platform are
                responsible for the images, words, graphics, recordings, comments, emojis, chat,
                postings, or any other activities they create, post, transmit, perform, or store on
                or through the Reveel Services. All content mentioned in this section is
                collectively referred to as &quot;User Content.&quot;
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  You are entirely responsible for obtaining all necessary third-party permissions,
                  licenses, and approvals required (for example, from record labels, music
                  publishers, performance rights organizations, artists, performers, producers,
                  etc.). You warrant and assert that you are the creator and owner of, or have
                  obtained the necessary licenses, rights, consents, releases, and permissions
                  regarding the User Content (including but not limited to all content used in
                  videos, profile images, banners, etc.) before uploading any video to the Reveel
                  platform. You agree to indemnify, defend, and hold us and our affiliates harmless
                  from any claims related to such User Content.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Ownership of User Content
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                While you maintain all ownership rights over your User Content, by distributing,
                communicating, or making your User Content available through the Reveel Services,
                you grant Reveel and its successors a perpetual, worldwide, non-exclusive,
                transferable, assignable, royalty-free license. This license allows for hosting,
                transferring, displaying, performing, reproducing, creating derivative works from,
                distributing, compressing, converting for streaming, and otherwise exploiting your
                User Content across any media formats and channels to publish and promote such
                Content in association with Reveel&apos;s services marketing efforts. This license
                extends to any form, media, or technology, whether currently known or developed in
                the future, and explicitly includes the rights to use your User Content alongside or
                in connection with third-party content.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  The license you grant is ongoing until your Content is removed as outlined herein.
                  Upon removal, the license will cease, except where continued operation of the
                  Service, use of Content previously permitted, or legal requirements dictate
                  otherwise. For instance, your removal of Content does not obligate Reveel to
                  expunge copies retained for legitimate legal reasons or remove your content from
                  pre-existing marketing materials.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Copyright Infringement and Takedown Notice Procedure
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                If you hold copyright ownership, an exclusive license, or are an authorized agent
                and believe content on the Reveel Service infringes upon your copyrights, you can
                initiate a notification by sending us the following details in writing to
                support@reveel.net:
              </Typography>
              <Typography component="h4" variant="body2">
                <ol>
                  <li>
                    <Box ml={2}>
                      A detailed description of the copyrighted work you claim has been infringed.
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      Identification of the allegedly infringing material you request to be removed
                      or to which access should be disabled, including the URL or specific location
                      of such material.
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      A physical or electronic signature of a person authorized to act on behalf of
                      the copyright owner of an exclusive right that is allegedly infringed.
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      Contact information sufficient for us to reach you, including your full name,
                      address, telephone number, and, if available, an email address.
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      A statement under good faith belief that the disputed use of the material is
                      not authorized by the copyright owner, its agent, or the law.
                    </Box>
                  </li>
                  <li>
                    <Box ml={2}>
                      A statement attesting to the accuracy of the notification and your authority
                      to act on behalf of the copyright owner of the right that is allegedly
                      infringed.
                    </Box>
                  </li>
                </ol>
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  If your content was removed or access to it was disabled, and you believe this was
                  a mistake or that you have authorization from the copyright owner, their agent, or
                  under the law, to post and use the content on Reveel, you may submit a
                  counter-notice to support@reveel.net (and cc the copyright owner) including:
                </Typography>
                <Typography component="h4" variant="body2">
                  <ol>
                    <li>
                      <Box ml={2}>
                        A description of the copyrighted work that has been removed or to which
                        access was disabled, and the location at which the material appeared before
                        it was removed or access to it was disabled.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        A physical or electronic signature of a person authorized to act on your
                        behalf.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        Your contact information, including full name, address, telephone number,
                        and email address, if available.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        A statement of good faith belief that the material was removed or disabled
                        as a result of mistake or misidentification.
                      </Box>
                    </li>
                    <li>
                      <Box ml={2}>
                        A statement under penalty of perjury that you are authorized to act on
                        behalf of the owner of an exclusive right that is allegedly infringed.
                      </Box>
                    </li>
                  </ol>
                </Typography>
                <Typography component="h4" variant="body2">
                  All notices will be reviewed, and Reveel will take appropriate action as deemed
                  suitable by law including potentially removing the contested content from the
                  platform. Additionally, Reveel may disable or terminate accounts of users who are
                  repeat infringers in suitable circumstances. We also reserve the right to hold any
                  net proceeds due to you pending the resolution of any claims or disputes regarding
                  your video.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Do Not Rely Solely on Information Provided on Our Platform
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                The information presented on our platform serves for general informational purposes
                and is not meant to constitute professional guidance upon which you should depend.
                Before taking or abstaining from any action based on the content found on our
                platform, it&apos;s crucial to seek advice from a professional or a specialist in
                the relevant field. While we strive to keep the information on our platform current,
                we do not claim, nor do we provide warranties or assurances, whether explicitly or
                implicitly, about the accuracy, completeness, or currentness of the content on our
                platform.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Our Liability Regarding External Links
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Our platform may include links to external sites and resources operated by third
                parties, which are provided for your convenience only. The inclusion of such links
                does not imply our endorsement of those external websites or the information you may
                receive from them. We do not have oversight or control over the content of those
                external sites or resources.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Our Liability for Losses or Damages Incurred by You
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We are not responsible for the actions or inactions of Creators, Viewers, or for any
                User Content on our platform. Unauthorized use of our platform, the Reveel Services,
                or the Reveel Materials may lead to a claim for damages and/or constitute a criminal
                offense. We exclude all liability for any loss or damage resulting from the use of
                our platform, the Reveel Services, or the Reveel Materials to the maximum extent the
                law permits. These terms do not intend to exclude our liability for death or
                personal injury resulting from our negligence, nor for our employees or agents’
                negligence, and not for fraud or fraudulent misrepresentation. Beyond the
                aforementioned exceptions, our total liability to you, whether due to contract, tort
                (including negligence), breach of statutory duty, or otherwise, shall be limited to
                the lesser of $100.00 or your entitled share of Net Proceeds.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Usage of Your Personal Information
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Your personal information will be utilized strictly in accordance with our Privacy
                Policy.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Responsibility Regarding Viruses and Prohibited Activities
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                We cannot assure that our platform is secure from bugs or viruses. It is your
                responsibility to configure your information technology, computer programs, and
                platform for safe access to our platform. It is advisable to use your virus
                protection software.
              </Typography>
              <Typography component="h4" variant="body2">
                You must not misuse our platform by introducing viruses, trojans, worms, logic
                bombs, or other malicious or technologically harmful material. Unauthorized attempts
                to gain access to our platform, the server on which our platform is stored, or any
                server, computer, or database connected to our platform are prohibited. You must not
                conduct denial-of-service attacks or distributed denial-of-service attacks against
                our platform. Violating this condition would constitute a criminal offense under the
                Computer Misuse Act 1990. We will report any such violations to the relevant law
                enforcement authorities and cooperate with them by disclosing your identity. In the
                case of such a violation, your right to use our platform will terminate immediately.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box sx={{ fontWeight: 'bold' }}>Rules for Linking to Our Platform</Box>
              </Typography>
              <Typography component="h4" variant="body2">
                You are welcome to link to our homepage as long as it&apos;s done in a manner
                that&apos;s fair, legal, and doesn&apos;t harm or exploit our reputation.
              </Typography>
              <Typography component="h4" variant="body2">
                You should not establish a link that implies any form of association, approval, or
                endorsement from us if such a relationship does not exist.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Mobile App
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                When you access our platform through one of our mobile apps, you are also subject to
                our privacy policy, in addition to these terms of use.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Competitions and Prizes
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Entering any of our competitions means you agree to these terms of use, as well as
                the specific terms and conditions applicable to the competition, which can be found
                on the competition details page.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Governing Law and Jurisdiction
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                These terms of use, their subject matter, and formation are all governed by American
                Legal Statutes. All parties agree that the courts of Delaware will have exclusive
                jurisdiction over any disputes.
              </Typography>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Disclaimer
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                The law grants consumers certain inalienable rights that contracts cannot override.
                Our terms do not affect any rights you may possess as a consumer. Beyond what is
                explicitly stated within these terms or mandated by law, Reveel does not extend any
                specific guarantees regarding the Service. This includes, but is not limited to, no
                guarantees concerning: the content made available through the Service; the
                Service&apos;s specific functions, or its reliability, availability, accuracy, or
                suitability for your needs; or the assurance that any content you contribute will be
                retained or remain accessible on the Service.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  For issues related to technical support, billing, installation, or marketing,
                  please direct your inquiries to us at support@reveel.net. Please be patient, as
                  resolving these matters can sometimes be a lengthy process.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={0} direction="column">
            <Container maxWidth="md">
              <Typography variant="h5">
                <Box mb={2} sx={{ fontWeight: 'bold' }}>
                  Miscellaneous
                </Box>
              </Typography>
              <Typography component="h4" variant="body2">
                Our failure to insist on the performance of your obligations under these terms, our
                lack of enforcement of our rights against you, or any delay in such enforcement,
                does not constitute a waiver of our rights. Any waiver on our part will be
                explicitly acknowledged in writing and should not be interpreted as a blanket waiver
                for any subsequent breaches by you. Should any part of these terms be deemed
                unlawful, void, or for any reason unenforceable, it will not impact the validity and
                enforceability of the remaining terms.
              </Typography>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  These terms, including any rights and licenses granted within them, cannot be
                  transferred or assigned by you, but can be assigned by Reveel without any
                  restrictions. Any attempts to assign these terms contrary to this provision will
                  be considered null and void. Certain provisions of these terms are intended to
                  remain in effect even after termination or expiration, particularly those that by
                  their nature or explicitly should survive such events.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography component="h4" variant="body2">
                  The agreement constituted by these terms, which includes the Privacy Policy,
                  represents the complete understanding between you and Reveel regarding the Service
                  and supersedes all previous agreements relating to the same subject matter.
                  Modifications to this agreement must be made in writing and signed by both
                  parties, except for changes to these terms made by Reveel as allowed within these
                  provisions. The rights and obligations outlined in these terms are established
                  solely between you and Reveel, and no third party is entitled to enforce any of
                  these terms.
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Terms;
