import { Chip, makeStyles, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pageStyle from '../theme/styles/pages/FormPage';
import { createTags } from '../services/Filmmakers';

const useStyles = makeStyles(pageStyle);
const filter = createFilterOptions();

const SearchableAutocomplete = ({
  value,
  label,
  defaultValue,
  loadOptions,
  onChange,
  allowCustomValue,
  size,
  singleValue,
  className,
  error,
  helperText,
  disabled,
  clearOptionsOnClose,
  style,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState(value || []);

  useEffect(() => {
    loadOptions('', setOptions);
  }, []);

  return (
    <Autocomplete
      className={`${classes.formInput} ${className}`}
      disabled={disabled}
      multiple={!singleValue}
      freeSolo
      disableCloseOnSelect={!singleValue}
      size={size}
      options={options}
      style={style}
      value={value || (singleValue ? null : [])} // for singleValue input use null
      defaultValue={defaultValue || (singleValue ? null : [])}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // option created dynamically
        if (option.customValue) {
          return option.customValue;
        }
        // Regular option
        return option.name || option.countryName;
      }}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            variant="outlined"
            label={
              option.customValue && allowCustomValue
                ? option.customValue
                : option.name || option.countryName
            }
            color={option?.status === 0 ? 'primary' : 'default'}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(option) => <>{option.name || option.countryName}</>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          onChange={(e) => {
            loadOptions(e.target.value, setOptions);
          }}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(e, newValue, reason) => {
        if (reason === 'clear') {
          loadOptions('', setOptions);
        }
        if (reason !== 'create-option') onChange(newValue);
        if (allowCustomValue) {
          if (newValue[newValue?.length - 1]?.customValue) {
            const payload = {
              name: newValue[newValue?.length - 1]?.customValue,
              status: 1,
            };
            createTags(payload)
              .then(() => {
                // Skipped
              })
              .catch(() => {
                // Skipped
              });
          }
        }
      }}
      filterOptions={(opt, params) => {
        const filtered = filter(opt, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '' && allowCustomValue) {
          filtered.push({
            id: Date.now(),
            name: `Add "${params.inputValue}"`,
            customValue: params.inputValue,
          });
        }
        return filtered;
      }}
      onFocus={() => {
        if (clearOptionsOnClose) {
          loadOptions('', setOptions);
        }
      }}
      onClose={() => {
        if (clearOptionsOnClose) {
          setOptions([]);
        } else {
          loadOptions('', setOptions);
        }
      }}
    />
  );
};

export default SearchableAutocomplete;

SearchableAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(),
  value: PropTypes.arrayOf(),
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  allowCustomValue: PropTypes.bool,
  size: PropTypes.string,
  singleValue: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  clearOptionsOnClose: PropTypes.bool,
  style: PropTypes.objectOf,
};
SearchableAutocomplete.defaultProps = {
  allowCustomValue: false,
  size: 'medium',
  onChange: () => true,
  defaultValue: [],
  value: [],
  singleValue: false,
  className: '',
  error: false,
  helperText: '',
  disabled: false,
  clearOptionsOnClose: false,
  style: {},
};
