import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SnackbarMessage from '../../components/SnackbarMessage';
import Validations from '../../utils/Validations';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo-red.png';
import RoutePaths from '../../config/Routes';
import loginStyle from '../../theme/styles/pages/Login';
import { signInUser } from '../../services/Auth';

const useStyles = makeStyles(loginStyle);

// Login page
const Login = () => {
  const pageName = 'Login';
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    signInUser({ ...data, filmmakerLogin: true })
      .then((res) => {
        setIsSubmitted(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: false,
        });
        window.localStorage.setItem('isLoggedIn', true);
        window.localStorage.setItem('user', JSON.stringify(res.user));
        window.location.assign(RoutePaths.HOME);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />

          <Container component="div" className={classes.authPageText}>
            <Typography component="h1" variant="h5">
              {pageName}
            </Typography>
            <Typography component="p">Login to your account</Typography>
          </Container>

          <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_EMAIL}
                name="email"
                id="email"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Email Address"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="email"
                    autoFocus
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                className={classes.authInput}
                rules={Validations.REQUIRED}
                name="password"
                id="password"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Password"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="password"
                  />
                )}
              />
            </FormControl>
            <Box textAlign="right" color="primary">
              <Link className={classes.loadingContainer} to={RoutePaths.FORGOT_PASSWORD}>
                Forgot your Password?
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.authSubmitBtn}
              disabled={isSubmitted}
            >
              Login
            </Button>
          </form>
          <Box>
            Don&apos;t have an account yet? <Link to={RoutePaths.SIGNUP}>Register here</Link>
          </Box>
        </Container>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Grid>
    </PublicWrapper>
  );
};

export default Login;
