import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';

const ViewResource = ({ onClose, title, fileUrl, type }) => (
  <Dialog open onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>{title}</DialogTitle>
    {type === 'Video' && (
      <DialogContent width="100%">
        <VideoPlayer controls src={fileUrl} height="500" />
      </DialogContent>
    )}
    {type === 'Image' && (
      <DialogContent width="100%">
        <img src={fileUrl} width="100%" height="500" alt="resource" />
      </DialogContent>
    )}
    <DialogActions>
      <Button variant="contained" onClick={onClose} disableElevation>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
ViewResource.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ViewResource;
