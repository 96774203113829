import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {
  makeStyles,
  CircularProgress,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@material-ui/core';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import listingStyle from '../../theme/styles/pages/Listing';
import ConfirmDialog from '../ConfirmDialog';
import SearchableAutocomplete from '../SearchableAutocomplete';
import langCodes from '../../config/Languages';
import UploadFile from './UploadSrtFile';
import { filmmakerAssetsStatus, filmmkaerFilmsStatus } from '../../config/Constants';
import { uploadFilmAsset } from '../../services/Programs';
import { updateFilmsAssetStatus } from '../../services/Filmmakers';

const useStyles = makeStyles(listingStyle);

const ManageSubtitles = ({
  closeModal,
  onSuccess,
  title,
  data,
  assetType,
  filmStatus,
  chapterId,
  programId,
  isSeries,
  filmmakerFilmId,
}) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);

  const [rows, setRows] = useState(data);

  const [srtFilePath, setSrtFilePath] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [removedSubtitleData, setRemovedSubtitleData] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const { handleSubmit, control, setValue, reset } = useForm();
  useEffect(() => {
    setValue('language', '');
  }, []);

  const handleDelete = (rowIndex) => {
    const updatedRows = [...rows];
    const row = rows[rowIndex];

    const assetStatus =
      filmStatus === filmmkaerFilmsStatus.PUBLISHED && row.assetStatus !== 2
        ? filmmakerAssetsStatus.REQUESTED_DELETION
        : filmmakerAssetsStatus.DELETED;
    updateFilmsAssetStatus(row.id, { assetStatus })
      .then((res) => {
        if (res.data.success) {
          updatedRows.splice(rowIndex, 1);
          setRows(updatedRows);

          setSnackbarMeesage({
            message: 'Subtitle removed successfully.',
            type: 'success',
            show: true,
          });
        }
      })
      .catch((e) => {
        setSnackbarMeesage({
          message: e.message,
          type: 'error',
          show: true,
        });
      });
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setDeleteProcessing(false);
    setConfirmDialog(false);
  };

  const submitData = async (srtFileData) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    const sameLanguageSrt = rows?.some(
      (row) =>
        row?.assetStatus !== filmmakerAssetsStatus.DELETED &&
        row?.assetStatus !== filmmakerAssetsStatus.REJECTED &&
        row?.name === srtFileData?.language?.name
    );
    if (sameLanguageSrt) {
      setSnackbarMeesage({
        message: 'For selected language sub-title file is already uploaded',
        type: 'error',
        show: true,
      });
    } else {
      const programDetail = {
        lanCode: srtFileData?.language?.id,
        name: srtFileData?.language?.name,
      };
      const payload = {
        assetType,
        assetUrl: srtFilePath,
        assetStatus: 2,
        programDetail,
        programChapterId: chapterId,
        programId,
        programStatus: filmStatus,
        isSeries,
      };
      const uploadResponse = await uploadFilmAsset(filmmakerFilmId, payload);
      setRows([
        ...rows,
        {
          lanCode: srtFileData?.language?.id,
          assetUrl: srtFilePath,
          name: srtFileData?.language?.name,
          assetType,
          assetStatus: filmmakerAssetsStatus.PENDING,
          id: uploadResponse.data || null,
        },
      ]);
    }
    onSuccess(
      [
        ...rows,
        {
          lanCode: srtFileData?.language?.id,
          assetUrl: srtFilePath,
          name: srtFileData?.language?.name,
          assetType,
          assetStatus: filmmakerAssetsStatus.PENDING,
        },
      ],
      assetType
    );
    reset();
    setProcessing(false);
    setSrtFilePath(null);
  };

  const handleSave = () => {
    onSuccess(rows, assetType);
    closeModal();
  };

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, []);

  const loadLanguages = (inputText, callBack) => {
    callBack(langCodes.map((r) => ({ id: r.code, name: `${r.name} (${r.code})` })));
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <>
          <form id="add-subtitle" onSubmit={handleSubmit(submitData)}>
            <TableContainer>
              <Table className={classes.tableData} aria-label="simple table">
                <TableBody>
                  {(filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                    filmStatus === filmmkaerFilmsStatus.PUBLISHED) && (
                    <>
                      <TableRow style={{ verticalAlign: 'bottom' }}>
                        <TableCell width="40%" style={{ border: 0 }}>
                          <Controller
                            control={control}
                            rules={Validations.REQUIRED}
                            name="language"
                            id="language"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <SearchableAutocomplete
                                loadOptions={loadLanguages}
                                onChange={onChange}
                                label="Language"
                                singleValue
                                value={value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                style={{ marginBottom: 0 }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell width="40%" style={{ border: 0 }}>
                          <div className={classes.formInput}>
                            <UploadFile
                              name="subtitleFile"
                              caption="Subtitle File"
                              updateSubtitleFile={(val) => setSrtFilePath(val)}
                              srtFilePath={srtFilePath}
                              programId={programId}
                            />
                          </div>
                        </TableCell>
                        <TableCell width="20%" style={{ border: 0 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            type="submit"
                            id="ad-submit-btn"
                            endIcon={processing && <CircularProgress size={25} />}
                            disabled={processing || !srtFilePath}
                            form="add-subtitle"
                          >
                            Add New
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} style={{ paddingTop: 0, color: 'grey' }}>
                          <Typography variant="caption">
                            Required to upload English subtitles; other languages optional.
                            Timestamp format should be in hours:minutes:seconds,milliseconds
                            (00:00:00,000).
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </>
        <TableContainer>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="40%">Language</TableCell>
                <TableCell width="45%">Url</TableCell>
                <TableCell width="15%">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.filter(
                  (item) =>
                    item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                    item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                ).length === 0 && (
                  <TableCell
                    align="center"
                    size="medium"
                    colSpan={3}
                    className={classes.noRecordFoundText}
                  >
                    No records found
                  </TableCell>
                )}
              {rows && (
                <>
                  {rows &&
                    rows?.length > 0 &&
                    rows?.map(
                      (row, index) =>
                        row.assetUrl &&
                        row.assetStatus !== filmmakerAssetsStatus.DELETED &&
                        row.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION && (
                          <TableRow key={`rv-${row?.url}`}>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.url || row?.assetUrl}</TableCell>
                            <TableCell>
                              {row.assetStatus !== filmmakerAssetsStatus.REJECTED && (
                                <IconButton
                                  aria-label="delete"
                                  className={classes.deleteBtn}
                                  onClick={() => {
                                    setConfirmDialog(true);
                                    setRemovedSubtitleData(index);
                                  }}
                                  disabled={
                                    filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                                    filmStatus ===
                                      filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                              {row.assetStatus === filmmakerAssetsStatus.REJECTED && (
                                <Box style={{ color: 'red', fontWeight: 'bold' }}>REJECTED</Box>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {confirmDialog && (
          <ConfirmDialog
            title="Delete Subtitle"
            message="Do you want to delete this language subtitle? This action can not be undone."
            onClose={() => {
              setConfirmDialog(false);
            }}
            onApprove={() => handleDelete(removedSubtitleData)}
            approveProcessing={deleteProcessing}
          />
        )}

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </DialogContent>
      <DialogActions>
        {(filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
          filmStatus === filmmkaerFilmsStatus.PUBLISHED ||
          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL) && (
          <Button onClick={handleSave} variant="contained" disabled={processing}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ManageSubtitles.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf.isRequired,
  assetType: PropTypes.number.isRequired,
  filmStatus: PropTypes.number.isRequired,
  chapterId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  isSeries: PropTypes.bool.isRequired,
  filmmakerFilmId: PropTypes.number.isRequired,
};

export default ManageSubtitles;
