const isPageInIframe = () => {
  try {
    if (window.location !== window.parent.location) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export default isPageInIframe;
