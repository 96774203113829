import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, Button, Grid } from '@material-ui/core';
import {
  // Facebook,
  // Twitter,
  // Instagram,
  // YouTube,
  // Pinterest,
  // LinkedIn,
  Check,
  ArrowRightAlt,
} from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PrivateWrapper from '../../layout/Private';
import { getFilmmakerProfile, updateFilmmakerProfile } from '../../services/Filmmakers';
import SnackbarMessage from '../../components/SnackbarMessage';
import RoutePaths from '../../config/Routes';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';

const ProfilePage = () => {
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
      pinterest: '',
      tiktok: '',
      linkedin: '',
      website: '',
    },
  });

  const pageName = 'My Profile';

  const history = useHistory();

  const [userDetails, setUserDetails] = useState({
    id: '',
    name: '',
    email: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  // const TikTokIcon = () => (
  //   <svg
  //     fill="#000000"
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 50 50"
  //     width="100%"
  //     height="100%"
  //   >
  //     <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
  //   </svg>
  // );

  const logoutUser = () => {
    history.push(RoutePaths.LOGOUT);
  };

  const onFormSubmit = (data) => {
    setSnackbarMeesage({ ...snackbarMeesage, show: false });
    setFormSubmitted(true);

    const payload = {
      email: data.email || '',
      phone: data.phone || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      facebook: data.facebook || '',
      twitter: data.twitter || '',
      instagram: data.instagram || '',
      youtube: data.youtube || '',
      pinterest: data.pinterest || '',
      tiktok: data.tiktok || '',
      linkedin: data.linkedin || '',
      website: data.website || '',
    };

    updateFilmmakerProfile(userDetails.id, payload)
      .then((res) => {
        if (res.data) {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Profile updated successfully.',
            type: 'success',
            show: true,
          });
        }
        setFormSubmitted(false);
      })
      .catch((e) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: e.response?.data?.message,
          type: 'error',
          show: true,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    try {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (!isLoggedIn) {
        logoutUser();
      } else {
        const user = window.localStorage.getItem('user');
        const userNameObj = user ? JSON.parse(user) : null;
        setUserDetails({
          ...userDetails,
          id: userNameObj ? userNameObj.id : '',
          name: userNameObj ? userNameObj.name : '',
          email: userNameObj ? userNameObj.email : '',
        });

        getFilmmakerProfile(userNameObj.id)
          .then((res) => {
            setValue('email', res.data?.email || '');
            setValue('phone', res.data?.phone || '');
            setValue('firstName', res.data?.firstName || '');
            setValue('lastName', res.data?.lastName || '');
            setValue('facebook', res.data?.facebook || '');
            setValue('twitter', res.data?.twitter || '');
            setValue('instagram', res.data?.instagram || '');
            setValue('youtube', res.data?.youtube || '');
            setValue('pinterest', res.data?.pinterest || '');
            setValue('tiktok', res.data?.tiktok || '');
            setValue('linkedin', res.data?.linkedin || '');
            setValue('website', res.data?.website || '');
          })
          .catch(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Error in loading the data.',
              type: 'error',
              show: true,
            });
          });
      }
    } catch (e) {
      logoutUser();
    }
  }, []);

  return (
    <PrivateWrapper pageName={pageName}>
      <Box mt={10}>
        <form id="filmmaker-profile-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                control={control}
                name="email"
                id="email"
                rules={{ ...Validations.REQUIRED_EMAIL, ...MaxLengthValidationFunc(120) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Email"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Controller
                control={control}
                name="phone"
                id="phone"
                rules={MaxLengthValidationFunc(20)}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    type="number"
                    label="Phone"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="firstName"
                id="firstName"
                rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="First Name"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="lastName"
                id="lastName"
                rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Last Name"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            {/* <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="facebook"
                id="facebook"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Facebook />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="twitter"
                id="twitter"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Twitter />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="instagram"
                id="instagram"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Instagram />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="youtube"
                id="youtube"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <YouTube />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="pinterest"
                id="pinterest"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Pinterest />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="tiktok"
                id="tiktok"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ width: '24px' }}>
                            <TikTokIcon />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="linkedin"
                id="linkedin"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedIn />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={2} md={2} />
            <Grid item xs={12} sm={9} md={9}>
              <Controller
                control={control}
                name="website"
                id="website"
                rules={{ ...Validations.OPTIONAL_URL, ...MaxLengthValidationFunc(250) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Website"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} /> */}
          </Grid>
        </form>
      </Box>

      <Box mt={4}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={2} md={2} />
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            style={{ display: 'flex' }}
            justifyContent="space-between"
          >
            <Button
              type="submit"
              form="filmmaker-profile-form"
              color="primary"
              variant="contained"
              endIcon={<Check />}
              disabled={formSubmitted}
            >
              SAVE CHANGES
            </Button>

            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRightAlt />}
              onClick={() => history.push(RoutePaths.CHANGE_PASSWORD)}
            >
              CHANGE PASSWORD
            </Button>
          </Grid>
          <Grid item xs={12} sm={1} md={1} />
        </Grid>
      </Box>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </PrivateWrapper>
  );
};

export default ProfilePage;
