import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

const PromotionPreferences = ({
  closePromotionPreferencesDialog,
  showConfirmDialog,
  filmDetail,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>
          <Box>{`Promotions Preferences - ${filmDetail.filmTitle}`}</Box>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={1} md={1} />
            <Grid item xs={12} sm={10} md={10}>
              <FormControlLabel
                onChange={(e) => setIsChecked(e.target.checked)}
                control={<Switch color="primary" />}
                label="Opt out of promotions"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />

            <Grid item xs={12} sm={1} md={1} />
            <Grid item xs={12} sm={10} md={10} style={{ fontSize: '15px' }}>
              <Box>
                Opting out of promotions excludes your film from any type of Reveel campaign
                including, but not limited to, newsletters, categories, collections, blogs, press
                releases, and social media campaigns. Users can still find your title via its unique
                promo link and by Reveel title search.
              </Box>
              <br />
              <Box>
                If you opt out of promotions after your film has already been published in Reveel,
                do note that there is a 30-day processing period to give our team time to revisit
                campaigns. Your film may still be part of campaigns before and during this time
                frame.
              </Box>
            </Grid>
            <Grid item xs={12} sm={1} md={1} />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ marginRight: 10 }}
            onClick={() => showConfirmDialog()}
            disabled={!isChecked}
          >
            Save
          </Button>
          <Button variant="contained" disableElevation onClick={closePromotionPreferencesDialog}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PromotionPreferences.propTypes = {
  closePromotionPreferencesDialog: PropTypes.func.isRequired,
  showConfirmDialog: PropTypes.func.isRequired,
  filmDetail: PropTypes.objectOf.isRequired,
};

export default PromotionPreferences;
