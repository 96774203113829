import { getApiCall, postApiCall } from '../utils/Api';

export const updateProgramImages = async (id, chapterId, data) => {
  const result = await postApiCall(
    `/filmmakers/update-image/${id}${chapterId ? `/${chapterId}` : ''}`,
    data,
    true
  );
  return result.data;
};

export const updateProgramVideos = async (data) => {
  const result = await postApiCall(`/filmmakers/update-video`, data, true);
  return result.data;
};

export const uploadFilmAsset = async (id, data) => {
  const result = await postApiCall(`/filmmakers/films/${id}/upload-assets`, data, true);
  return result.data;
};

export const removeProgramImages = async (id, chapterId, data) => {
  const result = await postApiCall(
    `/filmmakers/remove-image/${id}${chapterId ? `/${chapterId}` : ''}`,
    data,
    true
  );
  return result.data;
};

export const removeProgramVideos = async (id, chapterId, data) => {
  const result = await postApiCall(
    `/filmmakers/remove-video/${id}${chapterId ? `/${chapterId}` : ''}`,
    data,
    true
  );
  return result.data;
};

export const getTrailerPreview = async (id, action = 'preview') => {
  const result = await getApiCall(`/filmmakers/${id}/trailer?action=${action}`, true);
  return result.data;
};

export const getClipPreview = async (id, action = 'preview') => {
  const result = await getApiCall(`/programs/${id}/preview?action=${action}`, true);
  return result.data;
};

export const getVideoPreview = async (id, chapterId, action = 'preview') => {
  const result = await getApiCall(`/filmmakers/${id}/video/${chapterId}?action=${action}`, true);
  return result.data;
};

export const updatePromotionOptOut = async (id) => {
  const result = await postApiCall(`/programs/${id}/promotion-opt-out/update`, null);
  return result.data;
};
