import React, { useEffect, useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { resourcesCategory } from '../../config/Constants';
import Private from '../../layout/Private';
import ResourcesCategory from './ResourcesCategory';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useQuery = () => new URLSearchParams(useLocation().search);

const Resources = () => {
  const pageName = 'Resources';
  const query = useQuery();
  const activetab = query.get('activetab') || '';
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState(activetab || 'brandingAssets');

  useEffect(() => {
    resourcesCategory.forEach((item, index) => {
      if (tabValue === item.value) {
        setActiveTab(index);
      }
    });
  }, [tabValue]);

  return (
    <Private pageName={pageName}>
      <Box component={Paper} mt={2} p={2} minHeight="80vh">
        <Box>
          <Tabs
            value={tabValue}
            aria-label="tabs"
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            {resourcesCategory.map((item, index) => (
              <Tab value={item.value} label={item.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        <Box>
          {resourcesCategory.map(
            (item, index) =>
              tabValue === item.value && (
                <TabPanel value={activeTab} index={index}>
                  <ResourcesCategory tabValue={tabValue} />
                </TabPanel>
              )
          )}
        </Box>
      </Box>
    </Private>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default Resources;
