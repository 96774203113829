import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { getAWSUploadUrl } from '../../services/Assets';
import SnackbarMessage from '../SnackbarMessage';
import imageSize from '../../utils/ImageValidation';
import { filmmakerFilmsAssetsType } from '../../config/Constants';
import { uploadFilmAsset } from '../../services/Programs';
import { getCurrentTimestamp } from '../../utils/Datetime';

const ImageUpload = ({
  caption,
  filmId,
  onUploadComplete,
  assetType,
  programId,
  chapterId,
  filmStatus,
  filmmakerFilmId,
  isSeries,
  newEpisode,
  filmmakerId,
}) => {
  const hiddenFileInput = useRef(null);
  const buttonId = Date.now();

  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
    setProgress(Math.round((p.loaded / p.total) * 100));
  };

  const onFinish = (data) => {
    const URL = data.signedUrl.split('?').shift();
    setFileSelected(false);
    setIsFileUploading(false);
    const payload = {
      assetType,
      assetUrl: URL,
      assetStatus: 2,
      programDetail: null,
      programChapterId: chapterId,
      programId,
      programStatus: filmStatus,
      isSeries,
      newEpisode,
    };
    uploadFilmAsset(filmmakerFilmId, payload).then((res) =>
      onUploadComplete({ url: URL, id: res?.data })
    );
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          onProgress(p);
        },
      });
      onFinish(data);
    } catch {
      onError();
    }
  };

  const handleChange = async (e) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    const acceptedImages = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
    const file = e.target.files[0];
    if (acceptedImages.includes(file?.type)) {
      const resolution = await imageSize(file);

      if (assetType === filmmakerFilmsAssetsType.THUMBNAIL_WITH_TEXT && resolution.length > 0) {
        setIsFileUploading(false);
        setSnackbarMeesage({
          message: resolution,
          type: 'error',
          show: true,
        });
        hiddenFileInput.current.value = '';
      } else {
        const fileExtension = file?.name.split('.')[file?.name.split('.').length - 1];
        const currentTime = getCurrentTimestamp();
        const fileName = `${filmmakerId}_${currentTime}.${fileExtension}`;
        const params = {
          objectName: fileName,
          contentType: file.type,
          path: `films/${filmId}/assets/`,
        };
        getAWSUploadUrl(params)
          .then((res) => {
            uploadFile(res, file);
            setFileSelected(true);
          })
          .catch(() => {
            setHasError(true);
            setErrorMessage('Something went wrong.');
          });
      }
    } else {
      setSnackbarMeesage({
        message: `Please select image of type jpeg, jpg, png or gif file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  return (
    <>
      <input
        accept=".png, .jpg, .jpeg, .gif"
        id={`upload-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading}
      />
      <label htmlFor={`upload-button-${buttonId}`}>
        <Button
          onClick={handleClick}
          fullWidth
          variant="outlined"
          startIcon={!fileSelected ? <AddIcon /> : undefined}
          title={errorMessage}
        >
          {!fileSelected && caption}
          {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
          {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
          {hasError && 'Error'}
        </Button>
        {isFileUploading && (
          <div style={{ marginBottom: 20, color: 'grey', fontSize: 14 }}>
            Do not close the window or save the details until whole file is uploaded.
          </div>
        )}
      </label>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

ImageUpload.propTypes = {
  caption: PropTypes.string.isRequired,
  filmId: PropTypes.number.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  assetType: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  chapterId: PropTypes.number.isRequired,
  filmStatus: PropTypes.number.isRequired,
  filmmakerFilmId: PropTypes.number.isRequired,
  isSeries: PropTypes.bool.isRequired,
  newEpisode: PropTypes.bool.isRequired,
  filmmakerId: PropTypes.number.isRequired,
};

export default ImageUpload;
