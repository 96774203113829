/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  Box,
  Typography,
  Checkbox,
  Grid,
  Link,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Validations from '../../utils/Validations';
import {
  createFilmmakerProgram,
  getFilmmakerProgramById,
  updateFilmmakerProgram,
} from '../../services/Filmmakers';
import pageStyle from '../../theme/styles/pages/FormPage';
import VideoTypes from '../../config/VideoTypes';
import SnackbarMessage from '../SnackbarMessage';
import UploadAssets from '../assets/UploadAsset';
import RoutePaths from '../../config/Routes';
import AppLoader from '../AppLoader';

const useStyles = makeStyles(pageStyle);

const ManageFilmmakerFilm = ({ filmmakerProgramId, closeModal, onSuccess }) => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, getValues, watch } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'chapters',
  });

  const filmType = watch('type');
  const [loading, setLoading] = useState(true);
  const [isSeries, setIsSeries] = useState(filmType === 'SERIES');
  const [enabledSaveBtn, setEnabledSaveBtn] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [episodeDetail, setEpisodeDetail] = useState([]);
  const [filmId, setFilmId] = useState(filmmakerProgramId);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModal();
  };

  const handleCheckBox = (e) => {
    setEnabledSaveBtn(e.target.checked);
  };

  const onUploadComplete = (v, index) => {
    setProcessing(false);
    const videoData = episodeDetail;
    if (isSeries) {
      videoData[index - 1] = { episodeNo: index, episodeUrl: v?.videoUrl };
      setEpisodeDetail(videoData);
    } else {
      setVideoUrl(v?.videoUrl);
    }
    if (v?.filmId) {
      setFilmId(v?.filmId);
    }
    if (v?.data?.id || filmId) {
      const data = getValues();
      const { chapters } = data;
      episodeDetail.forEach((item) => {
        if (item?.episodeNo === 1) {
          chapters[0].episodeUrl = item.episodeUrl;
          chapters[0].title = chapters[0].title || 'Untitled - Episode';
        } else if (item?.episodeNo === 2) {
          chapters[1].episodeUrl = item.episodeUrl;
          chapters[1].title = chapters[1].title || 'Untitled - Episode';
        } else if (item?.episodeNo === 3) {
          chapters[2].episodeUrl = item.episodeUrl;
          chapters[2].title = chapters[2].title || 'Untitled - Episode';
        }
      });
      if (v?.data?.id) {
        setFilmId(v?.data?.id);
      }
      if (!data?.title) {
        setValue('title', v?.data?.title);
      }
      if (!data?.shortDescription) {
        setValue('shortDescription', v?.data?.shortDescription);
      }
      setValue('type', data?.type || v?.data?.type);
      setValue('chapters', chapters);
      setIsSeries(v?.data?.type === 'SERIES');
      const payload = {
        title: !data?.title ? v?.data?.title : data.title || '',
        type: data?.type || v?.data?.type || 'SHORT_FILM',
        shortDescription: !data?.shortDescription
          ? v?.data?.shortDescription
          : data?.shortDescription || '',
        videoUrl: v?.data?.type !== 'SERIES' ? v?.videoUrl : null,
        chapters: v?.data?.type === 'SERIES' ? chapters : [],
        status: 0,
      };
      updateFilmmakerProgram(v?.data?.id || filmId, payload);
    }
  };

  const manageFilmApiCall = (payload) => {
    if (filmId) {
      updateFilmmakerProgram(filmId, payload)
        .then(() => {
          setSnackbarMeesage({
            message: 'Updated successfully.',
            type: 'success',
            show: true,
          });
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response?.data?.message || 'Something went wrong. Please try again.',
            type: 'error',
            show: true,
          });
          handleDialog();
        });
    } else {
      createFilmmakerProgram(payload)
        .then(() => {
          setSnackbarMeesage({
            message: 'Created successfully.',
            type: 'success',
            show: true,
          });
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response?.data?.message || 'Something went wrong. Please try again.',
            type: 'error',
            show: true,
          });
          handleDialog();
        });
    }
  };

  const handleSaveSubmitNow = (data) => {
    episodeDetail.forEach((item, index) => {
      if (data.chapters[0] && index === 0) {
        data.chapters[0].episodeUrl = item?.episodeUrl || null;
      } else if (data.chapters[1] && index === 1) {
        data.chapters[1].episodeUrl = item?.episodeUrl || null;
      } else if (data.chapters[2] && index === 2) {
        data.chapters[2].episodeUrl = item?.episodeUrl || null;
      }
    });

    if (!videoUrl && !isSeries) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        show: false,
      });
      setTimeout(() => {
        setSnackbarMeesage({
          message: 'Please upload film',
          type: 'error',
          show: true,
        });
      }, 250);
      return;
    }
    if (
      isSeries &&
      !data?.chapters[0]?.episodeUrl &&
      !data?.chapters[1]?.episodeUrl &&
      !data?.chapters[2]?.episodeUrl
    ) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        show: false,
      });
      setTimeout(() => {
        setSnackbarMeesage({
          message: 'Please upload at least one episode',
          type: 'error',
          show: true,
        });
      }, 250);
      return;
    }
    const payload = {
      title: data.title || '',
      type: data.type || '',
      shortDescription: data.shortDescription || '',
      videoUrl: !isSeries ? videoUrl : null,
      chapters: isSeries ? data.chapters : [],
      status: 1,
    };
    manageFilmApiCall(payload);
  };

  const handleSaveFinishLater = (data) => {
    episodeDetail.forEach((item, index) => {
      if (data.chapters[0] && index === 0) {
        data.chapters[0].episodeUrl = item?.episodeUrl || null;
        data.chapters[0].episodeNumber = 1;
      } else if (data.chapters[1] && index === 1) {
        data.chapters[1].episodeUrl = item?.episodeUrl || null;
        data.chapters[0].episodeNumber = 2;
      } else if (data.chapters[2] && index === 2) {
        data.chapters[2].episodeUrl = item?.episodeUrl || null;
        data.chapters[0].episodeNumber = 3;
      }
    });

    if (!videoUrl && !isSeries) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        show: false,
      });
      setTimeout(() => {
        setSnackbarMeesage({
          message: 'Please upload film',
          type: 'error',
          show: true,
        });
      }, 250);
      return;
    }
    if (
      isSeries &&
      !data?.chapters[0]?.episodeUrl &&
      !data?.chapters[1]?.episodeUrl &&
      !data?.chapters[2]?.episodeUrl
    ) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        show: false,
      });
      setTimeout(() => {
        setSnackbarMeesage({
          message: 'Please upload at least one episode',
          type: 'error',
          show: true,
        });
      }, 250);
      return;
    }
    const payload = {
      title: data.title || '',
      type: data.type || '',
      shortDescription: data.shortDescription || '',
      videoUrl: !isSeries ? videoUrl : null,
      chapters: isSeries ? data.chapters : [],
      status: 0,
    };
    manageFilmApiCall(payload);
  };

  useEffect(() => {
    if (filmType === 'SERIES') {
      setIsSeries(true);
    } else {
      setIsSeries(false);
    }
  }, [filmType]);

  useEffect(() => {
    if (filmmakerProgramId) {
      getFilmmakerProgramById(filmmakerProgramId).then((res) => {
        setVideoUrl(res.data.videoUrl);

        setValue('title', res.data.title);
        setValue('type', res.data.type);
        setIsSeries(res.data.type === 'SERIES');
        setValue('shortDescription', res.data.shortDescription);
        setValue(
          'chapters',
          res.data?.chapters.filter((item) => item.title)
        );
        setEpisodeDetail([
          { episodeUrl: res.data.ep1Url },
          { episodeUrl: res.data.ep2Url },
          { episodeUrl: res.data.ep3Url },
        ]);
        setLoading(false);
      });
    } else {
      append();
      setLoading(false);
    }
  }, []);

  const defaultFormValues = getValues();
  return (
    <Dialog open fullWidth maxWidth="md">
      {loading && <AppLoader height="50vh" />}

      {!loading && (
        <>
          <DialogTitle>{filmmakerProgramId ? 'Edit' : 'Submit New'} Film</DialogTitle>
          <DialogContent>
            <form id="edit-submitted-program-details">
              <Controller
                control={control}
                id="title"
                name="title"
                rules={{ ...Validations.REQUIRED }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Film Title*"
                    className={classes.formInput}
                    inputProps={{ maxLength: 200 }}
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                control={control}
                rules={{ ...Validations.REQUIRED }}
                name="type"
                id="type"
                defaultValue="SHORT_FILM"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Film Type*"
                    SelectProps={{
                      native: true,
                    }}
                    fullWidth
                    select
                    className={classes.formInput}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  >
                    {Object.keys(VideoTypes).map((k) => (
                      <option key={k} value={k}>
                        {VideoTypes[k]}
                      </option>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="shortDescription"
                id="shortDescription"
                rules={{ ...Validations.REQUIRED }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Short Description"
                    className={classes.formInput}
                    inputProps={{ maxLength: 100 }}
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />

              {!isSeries && (
                <>
                  <UploadAssets
                    caption="Film"
                    name="Upload Film"
                    value={videoUrl}
                    onUploadComplete={onUploadComplete}
                    remove={false}
                    updateProcessing={() => setProcessing(true)}
                    filmmakerProgramId={filmId}
                    filmData={{
                      title: watch('title'),
                      shortDescription: watch('shortDescription'),
                      type: watch('type'),
                      chapters: watch('chapters') || [],
                    }}
                    assetType={2}
                    isSeries={isSeries}
                    initialFilm
                  />
                  <Typography variant="caption" display="block" style={{ color: 'gray' }}>
                    Please upload the highest quality video in mp4 format. If approved, this will be
                    the file that will be streamed on Reveel.
                  </Typography>
                </>
              )}

              {isSeries && (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" className="text-black">
                      Episodes
                    </Typography>
                    <div>
                      <Button
                        type="button"
                        onClick={() => {
                          append();
                        }}
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        disabled={fields.length > 2}
                      >
                        Add
                      </Button>
                    </div>
                  </Box>
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.id} className="my-2 px-1">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          my={1}
                        >
                          <Typography variant="p" style={{ fontSize: 18 }}>
                            Episode {index + 1}
                          </Typography>
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => {
                                remove(index);
                                episodeDetail.splice(index, 1);
                              }}
                            >
                              remove
                            </Button>
                          </div>
                        </Box>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                              <Controller
                                control={control}
                                id={`chapters[${index}].title`}
                                name={`chapters[${index}].title`}
                                rules={{ ...Validations.REQUIRED }}
                                defaultValue={defaultFormValues?.chapters[index]?.title || ''}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Episode Title*"
                                    className={classes.formInput}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    style={{ width: '70%' }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Box mt={0.7}>
                                <UploadAssets
                                  caption={`Episode ${index + 1}`}
                                  value={episodeDetail[index]?.episodeUrl || ''}
                                  onUploadComplete={(v) => onUploadComplete(v, index + 1)}
                                  updateProcessing={() => setProcessing(true)}
                                  filmmakerProgramId={filmId}
                                  filmData={{
                                    title: watch('title'),
                                    shortDescription: watch('shortDescription'),
                                    type: watch('type'),
                                    chapters: watch('chapters') || [],
                                  }}
                                  assetType={2}
                                  isSeries={isSeries}
                                  initialFilm
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <Box>
                {isSeries && (
                  <Typography variant="caption" display="block" style={{ color: 'gray' }}>
                    Please upload the highest quality video in mp4 format. If approved, this will be
                    the file that will be streamed on Reveel.
                  </Typography>
                )}
                <FormControlLabel
                  control={
                    <Checkbox label="Outlined" variant="outlined" onChange={handleCheckBox} />
                  }
                  label={
                    <Box>
                      I certify that I have read and agree to the{' '}
                      <Link href={RoutePaths.TERMS} target="_blank">
                        Reveel Filmmaker Terms and Conditions
                      </Link>
                    </Box>
                  }
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box mb={3}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit(handleSaveSubmitNow)}
                disableElevation
                disabled={!enabledSaveBtn || processing}
                style={{ marginLeft: 16 }}
              >
                Save and Submit Now
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit(handleSaveFinishLater)}
                disableElevation
                disabled={processing}
                style={{ marginLeft: 8 }}
              >
                Save and Finish Later
              </Button>
            </Box>
            <Button
              variant="contained"
              disabled={processing}
              onClick={handleDialog}
              disableElevation
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ManageFilmmakerFilm.propTypes = {
  filmmakerProgramId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ManageFilmmakerFilm;
