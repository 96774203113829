import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import formInput from '../../theme/styles/FormInput';
import UploadAsset from '../assets/UploadAsset';
import UploadImage from '../assets/UploadImage';
import { getFilmAssets } from '../../services/Filmmakers';
import { filmmakerProgramStatus } from '../../config/Constants';
import VideoInlinePreview from './VideoInlinePreview';
import ImageInlinePreview from './ImageInlinePreview';

const useStyles = makeStyles(formInput);

const ManageFilmAssets = ({ closeModal, filmId }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [isSeries, setIsSeries] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [filmData, setFilmData] = useState({ chapters: [] });
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getFilmAssets(filmId).then((res) => {
      setIsSeries(res.data.type === 'SERIES');
      setIsActive(
        [filmmakerProgramStatus.ACTIVE, filmmakerProgramStatus.INACTIVE].includes(res.data.status)
      );
      setFilmData({ ...res.data, chapters: res.data.chapters });
      setDataLoaded(true);
    });
  }, [reloadData]);

  const refreshData = () => {
    setReloadData(!reloadData);
  };
  const commonXsRatio = isActive ? 3 : 6;

  return (
    <Dialog open onClose={closeModal} fullWidth maxWidth="lg">
      <DialogTitle>Manage Assets</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!dataLoaded && (
          <Box display="flex" alignItems="center" justifyContent="center" py={3}>
            <CircularProgress fontSize={12} />
          </Box>
        )}
        {dataLoaded && (
          <>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={3} style={{ backgroundColor: '#dfdfdf' }}>
                <strong>Asset</strong>
              </Grid>
              {isActive && (
                <Grid item xs={commonXsRatio} style={{ backgroundColor: '#dfdfdf' }}>
                  <strong>Live Asset</strong>
                </Grid>
              )}
              <Grid
                item
                xs={commonXsRatio}
                style={{ backgroundColor: '#dfdfdf', textAlign: 'center' }}
              >
                <strong>Upload New Asset</strong>
              </Grid>
              <Grid item xs={3} style={{ backgroundColor: '#dfdfdf' }}>
                <strong>Status</strong>
              </Grid>
            </Grid>
            {!isSeries && (
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={3}>
                  <strong>Upload Video</strong>
                  <p>Upload a full video of the program.</p>
                </Grid>
                {isActive && (
                  <Grid item xs={commonXsRatio}>
                    {filmData?.videoLivePreview && (
                      <VideoInlinePreview url={filmData.videoLivePreview} />
                    )}
                  </Grid>
                )}
                <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                  <UploadAsset
                    name="video"
                    caption="Video"
                    trailer={false}
                    value={filmData?.videoUrl}
                    filmId={filmId}
                    onUploadComplete={refreshData}
                  />
                </Grid>
                {isActive && (
                  <Grid item xs={3}>
                    <div>{filmData?.videoUrlStatus}</div>
                    <Typography variant="caption">{filmData?.videoUrlNote}</Typography>
                  </Grid>
                )}
                {!isActive && (
                  <Grid item xs={3}>
                    {filmData.videoUrlRejectMsg && (
                      <>
                        <div>Rejected</div>
                        <Typography variant="caption">{filmData.videoUrlRejectMsg}</Typography>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={3}>
                <strong> Upload Trailer</strong>
                <p>Choose a video to be used as a trailer for the program.</p>
              </Grid>
              {isActive && (
                <Grid item xs={commonXsRatio}>
                  {filmData?.trailerLivePreview && (
                    <VideoInlinePreview url={filmData.trailerLivePreview} />
                  )}
                </Grid>
              )}
              <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                <UploadAsset
                  name="trailer"
                  caption="Trailer Video"
                  value={filmData?.trailerUrl}
                  autoCreateRecord={() => true}
                  filmId={filmId}
                  trailer
                  onUploadComplete={refreshData}
                />
              </Grid>
              {isActive && (
                <Grid item xs={3}>
                  <div>{filmData?.trailerUrlStatus}</div>
                  <Typography variant="caption">{filmData?.trailerUrlNote}</Typography>
                </Grid>
              )}
              {!isActive && (
                <Grid item xs={3}>
                  {filmData.trailerUrlRejectMsg && (
                    <>
                      <div>Rejected</div>
                      <Typography variant="caption">{filmData.trailerUrlRejectMsg}</Typography>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={3}>
                <strong>Standard Image</strong>
                <p>This image will appear as a thumbnail of the program in landscape view.</p>
              </Grid>
              {isActive && (
                <Grid item xs={commonXsRatio}>
                  {filmData?.standardImageLivePreview && (
                    <ImageInlinePreview url={filmData?.standardImageLivePreview} />
                  )}
                </Grid>
              )}
              <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                <UploadImage
                  name="standardImage"
                  caption="Standard Image"
                  value={filmData?.standardImage}
                  filmId={filmId}
                  onUploadComplete={refreshData}
                />
              </Grid>
              {isActive && (
                <Grid item xs={3}>
                  <div>{filmData?.standardImageStatus}</div>
                  <Typography variant="caption">{filmData?.standardImageNote}</Typography>
                </Grid>
              )}
              {!isActive && (
                <Grid item xs={3}>
                  {filmData.standardImageRejectMsg && (
                    <>
                      <div>Rejected</div>
                      <Typography variant="caption">{filmData.standardImageRejectMsg}</Typography>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={3}>
                <strong>Featured Image</strong>
                <p>This image will appear as a banner of the program in featured list/carousel.</p>
              </Grid>
              {isActive && (
                <Grid item xs={commonXsRatio}>
                  {filmData?.featuredImageLivePreview && (
                    <ImageInlinePreview url={filmData?.featuredImageLivePreview} />
                  )}
                </Grid>
              )}
              <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                <UploadImage
                  name="featuredImage"
                  caption="Featured Image"
                  value={filmData?.featuredImage}
                  filmId={filmId}
                  onUploadComplete={refreshData}
                />
              </Grid>
              {isActive && (
                <Grid item xs={3}>
                  <div>{filmData?.featuredImageStatus}</div>
                  <Typography variant="caption">{filmData?.featuredImageNote}</Typography>
                </Grid>
              )}
              {!isActive && (
                <Grid item xs={3}>
                  {filmData.featuredImageRejectMsg && (
                    <>
                      <div>Rejected</div>
                      <Typography variant="caption">{filmData.featuredImageRejectMsg}</Typography>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            {isSeries && filmData.chapters.length > 0 && (
              <Typography variant="h6" style={{ margin: '20px 0' }}>
                Episodes Assets
              </Typography>
            )}
            {isSeries &&
              filmData.chapters.length > 0 &&
              filmData.chapters.map((chapter) => (
                <>
                  <Accordion
                    expanded={expanded === chapter.chapterId}
                    onChange={handleChange(chapter.chapterId)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>{chapter.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" width="100%">
                        <Grid container style={{ marginBottom: 10 }}>
                          <Grid item xs={3} style={{ backgroundColor: '#dfdfdf' }}>
                            <strong>Asset</strong>
                          </Grid>
                          {isActive && (
                            <Grid item xs={commonXsRatio} style={{ backgroundColor: '#dfdfdf' }}>
                              <strong>Live Asset</strong>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={commonXsRatio}
                            style={{ backgroundColor: '#dfdfdf', textAlign: 'center' }}
                          >
                            <strong>Upload New Asset</strong>
                          </Grid>
                          <Grid item xs={3} style={{ backgroundColor: '#dfdfdf' }}>
                            <strong>Status</strong>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: 10 }}>
                          <Grid item xs={3}>
                            <strong>Upload Video</strong>
                            <p>Upload a full video of the program.</p>
                          </Grid>
                          {isActive && (
                            <Grid item xs={commonXsRatio}>
                              {chapter?.videoLivePreview && (
                                <VideoInlinePreview url={chapter.videoLivePreview} />
                              )}
                            </Grid>
                          )}
                          <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                            <UploadAsset
                              name="video"
                              caption="Video"
                              chapterId={chapter.chapterId}
                              trailer={false}
                              value={chapter?.videoUrl}
                              filmId={filmId}
                              onUploadComplete={refreshData}
                            />
                          </Grid>
                          {isActive && (
                            <Grid item xs={3}>
                              <div>{chapter?.videoUrlStatus}</div>
                              <Typography variant="caption">{chapter?.videoUrlNote}</Typography>
                            </Grid>
                          )}
                          {!isActive && (
                            <Grid item xs={3}>
                              {chapter.videoUrlRejectMsg && (
                                <>
                                  <div>Rejected</div>
                                  <Typography variant="caption">
                                    {chapter.videoUrlRejectMsg}
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          )}
                        </Grid>
                        <Grid container style={{ marginBottom: 10 }}>
                          <Grid item xs={3}>
                            <strong>Standard Image</strong>
                            <p>
                              This image will appear as a thumbnail of the program in landscape
                              view.
                            </p>
                          </Grid>
                          {isActive && (
                            <Grid item xs={commonXsRatio}>
                              {chapter?.standardImageLivePreview && (
                                <ImageInlinePreview url={chapter?.standardImageLivePreview} />
                              )}
                            </Grid>
                          )}
                          <Grid item xs={commonXsRatio} style={{ padding: '0 30px' }}>
                            <UploadImage
                              name="standardImage"
                              caption="Standard Image"
                              chapterId={chapter.chapterId}
                              value={chapter?.standardImage}
                              filmId={filmId}
                              onUploadComplete={refreshData}
                            />
                          </Grid>
                          {isActive && (
                            <Grid item xs={3}>
                              <div>{chapter?.standardImageStatus}</div>
                              <Typography variant="caption">
                                {chapter?.standardImageNote}
                              </Typography>
                            </Grid>
                          )}
                          {!isActive && (
                            <Grid item xs={3}>
                              {chapter.standardImageRejectMsg && (
                                <>
                                  <div>Rejected</div>
                                  <Typography variant="caption">
                                    {chapter.standardImageRejectMsg}
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeModal} disableElevation>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManageFilmAssets.propTypes = {
  closeModal: PropTypes.func.isRequired,
  filmId: PropTypes.number.isRequired,
};

export default ManageFilmAssets;
