import { getApiCall, postApiCall } from '../utils/Api';

export const uploadTrailer = async (data) => {
  const result = await postApiCall('/upload/trailer', data);
  return result.data;
};

export const uploadVideo = async (data) => {
  const result = await postApiCall('/upload/video', data);
  return result.data;
};

export const uploadFile = async (data) => {
  const result = await postApiCall('/upload/subtitle', data);
  return result.data;
};

export const getAWSUploadUrl = async (data) => {
  const result = await getApiCall(
    `/s3/sign?objectName=${data.objectName}&contentType=${data.contentType}&path=${data.path || ''}`
  );
  return result.data;
};

// Multipart upload

export const getAWSMultipartUploadUrl = async (data) => {
  const result = await postApiCall('/filmmakers/s3/multipart-upload', data);
  return result.data;
};

export const getVideoUploadURL = async (data) => {
  const result = await getApiCall(
    `/filmmakers/s3/get-upload-url?fileName=${data.fileName}&partNumber=${data.partNumber}&uploadId=${data.uploadId}&path=${data.path}`
  );

  return result;
};

export const completeVideoUpload = async (data) => {
  const result = await postApiCall('/filmmakers/s3/complete-upload', data);
  return result.data;
};
