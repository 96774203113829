import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { getFilmmakerProgramById } from '../../services/Filmmakers';
import VideoTypes from '../../config/VideoTypes';
import ViewVideo from '../assets/ViewVideo';
import AppLoader from '../AppLoader';

const useStyles = makeStyles(() => ({
  viewTable: {
    '& .MuiTableCell-root': {
      borderBottom: '0px',
      padding: 8,
      fontSize: '1rem',
    },
  },
  viewLabel: {
    fontWeight: 'bold',
    verticalAlign: 'baseline',
  },
  detailsCell: {
    wordBreak: 'break-all',
  },
}));

const ViewFilmmakerFilm = ({ open, viewId, closeModal, rejectedRowData }) => {
  const classes = useStyles();

  const tableData = {
    columns: [
      { name: 'Type', key: 'type' },
      { name: 'Title', key: 'title' },
      { name: 'Short Description', key: 'shortDescription' },
    ],
    data: [],
  };

  const [viewData, setViewData] = useState(tableData);
  const [viewVideo, setViewVideo] = useState(false);
  const [episodeUrl, setEpisodeUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const handlePreviewFilm = () => {
    setViewVideo(true);
  };

  const handlePreviewEpisode = (value = null) => {
    if (value) {
      setEpisodeUrl(viewData.data?.chapters[value - 1].episodeUrl);
    }
    setViewVideo(true);
  };

  const handleViewModel = () => {
    setViewVideo(false);
  };

  useEffect(() => {
    if (rejectedRowData.length > 0) {
      setViewData({
        ...tableData,
        columns: [...tableData.columns, { name: 'Rejection Note', key: 'remarks' }],
        data: rejectedRowData[0],
      });
      setLoading(false);
    } else {
      getFilmmakerProgramById(viewId).then((res) => {
        setViewData({
          ...tableData,
          columns: [...tableData.columns, { name: 'Video Preview', key: 'videoUrl' }],
          data: {
            ...res.data,
            type: VideoTypes[res.data?.type],
          },
        });
        setLoading(false);
      });
    }
  }, []);

  const handleDialog = () => {
    closeModal();
  };

  return (
    <>
      <Dialog open={open} onClose={handleDialog} fullWidth maxWidth="md">
        {loading && <AppLoader height="50vh" />}

        {!loading && (
          <>
            <DialogTitle>{`View ${!viewData.data.videoUrl ? 'Series' : 'Film'}`}</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table className={classes.viewTable}>
                  {viewData.columns.map((row) => (
                    <TableRow>
                      <TableCell width="30%" className={classes.viewLabel}>
                        {row.name}
                      </TableCell>
                      <TableCell width="70%" className={classes.detailsCell}>
                        {row.key === 'videoUrl' && viewData.data?.videoUrl ? (
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              width: '100%',
                            }}
                          >
                            <Button
                              aria-haspopup="true"
                              variant="outlined"
                              onClick={handlePreviewFilm}
                              style={{
                                marginBottom: 20,
                                color: 'green',
                                borderColor: 'green',
                                width: '200px',
                              }}
                            >
                              Preview Film
                            </Button>
                            <Box ml="25px">{viewData.data?.title}</Box>
                          </Box>
                        ) : (
                          ''
                        )}

                        {row.key === 'videoUrl' &&
                          !viewData.data?.videoUrl &&
                          viewData.data?.chapters?.map(
                            (item, index) =>
                              item.episodeUrl && (
                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    width: '100%',
                                  }}
                                >
                                  <Button
                                    aria-haspopup="true"
                                    variant="outlined"
                                    onClick={() => handlePreviewEpisode(index + 1)}
                                    style={{
                                      marginBottom: 20,
                                      color: 'green',
                                      borderColor: 'green',
                                      width: '200px',
                                    }}
                                  >
                                    {`Preview Episode ${index + 1}`}
                                  </Button>
                                  <Box ml="25px">{item.title}</Box>
                                </Box>
                              )
                          )}

                        {row.key !== 'videoUrl' && (viewData.data[row.key] || '-')}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
                {rejectedRowData.length === 0 && (
                  <TableRow>
                    Your submission is under review. Please stay tuned for updates.
                  </TableRow>
                )}
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleDialog} disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {viewVideo && (
        <ViewVideo
          handleViewModel={handleViewModel}
          viewModelOpen={viewVideo}
          url={episodeUrl || viewData.data?.videoUrl || ''}
        />
      )}
    </>
  );
};

ViewFilmmakerFilm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  viewId: PropTypes.number.isRequired,
  rejectedRowData: PropTypes.arrayOf.isRequired,
};

export default ViewFilmmakerFilm;
