// List of validations
const Validations = {
  REQUIRED: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
  REQUIRED_EMAIL: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email address.',
    },
  },
  OPTIONAL_URL: {
    pattern: {
      value:
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
      message: 'Please enter a valid url.',
    },
  },
  REQUIRED_PASSWORD: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    minLength: {
      value: 6,
      message: 'Password must be at least 6 characters long.',
    },
  },
  SELECT: {
    pattern: {
      value: /^[^ ]*[^ ]/,
      message: 'This field is required.',
    },
  },
};

export default Validations;

export const MaxLengthValidationFunc = (value) => ({
  maxLength: {
    value,
    message: `Please enter the value must be less than or equal to ${value} characters.`,
  },
});

export const ExactLengthValidationFunc = (value) => ({
  minLength: {
    value,
    message: `Please enter exactly ${value} digits.`,
  },
  max: {
    value: new Date().getFullYear(),
    message: `Production year must be less than or equal to ${new Date().getFullYear()}.`,
  },
});

export const noSpacesValidationFunc = (value) =>
  value.trim() ? true : 'Please enter a valid name.';
