const COOKIE_AUTH = 'is-logged-in-v2';

export const filmmakerProgramStatus = {
  PENDING: 0,
  0: 'Pending',
  CONTRACT_SENT: 1,
  1: 'Contract Sent',
  CONTRACT_SIGNED: 2,
  2: 'Contract Signed',
  REVIEW: 3,
  3: 'Review',
  ACTIVE: 4,
  4: 'Active',
  INACTIVE: 5,
  5: 'Inactive',
  REJECTED: 6,
  6: 'Rejected',
  DELETED: 7,
  7: 'Deleted',
  CONTRACT_CANCELLED: 8,
  8: 'Contract Cancelled',
};

export const filmmakerFilmsAssetsType = {
  1: 'Program details',
  PROGRAM_DETAILS: 1,
  2: 'Film video',
  FILM_VIDEO: 2,
  3: 'Trailer video',
  TRAILER_VIDEO: 3,
  4: 'Thumbnail with text',
  THUMBNAIL_WITH_TEXT: 4,
  5: 'Thumbnail without text',
  THUMBNAIL_WITHOUT_TEXT: 5,
  6: 'Video SRT',
  VIDEO_SRT: 6,
  7: 'BTS video',
  BTS_VIDEO: 7,
  8: 'Transparent logo',
  TRANSPARENT_LOGO: 8,
  9: 'Trailer SRT',
  TRAILER_SRT: 9,
};

export const filmmakerAssetsStatus = {
  0: 'Rejected',
  REJECTED: 0,
  1: 'Approved',
  APPROVED: 1,
  2: 'Pending',
  PENDING: 2,
  3: 'Replaced',
  REPLACED: 3,
  4: 'Deleted',
  DELETED: 4,
  5: 'Requested Deletion',
  REQUESTED_DELETION: 5,
};

export const filmmkaerFilmsStatus = {
  SAVED: 0,
  SUBMITTED: 1,
  ASSET_UPLOAD: 2,
  FOR_APPROVAL: 3,
  PUBLISHED: 4,
  PUBLISHED_WITH_PENDING_UPDATE: 5,
  DEACTIVATED: 6,
  REJECTED: 7,
};

export const TOTAL_RECORDS_PER_PAGE = 20;

export const promotionSubmissionType = {
  VIDEO_INTERVIEW_FILE: 1,
  WRITTEN_INTERVIEW_FILE: 2,
  OTHER_FILE: 3,
};

export const promotionStatus = {
  NO_ACTION: null,
  INVITED: 1,
  SAVED: 2,
  SUBMMITED: 3,
};

export const resourcesCategory = [{ label: 'Branding Assets', value: 'brandingAssets' }];

export const uploadVideoFileSize = 500000000;

export default COOKIE_AUTH;
