import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';

// Style for table listing
const Style = (theme) => ({
  filterToolbar: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 10,
  },
  filterLeft: {
    width: '80%',
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  filterRight: {
    width: '30%',
    float: 'left',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  searchInput: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiFormLabel-root': {
      top: -20,
    },
  },
  searchFilterBtn: {
    marginLeft: 10,
    minHeight: 36,
    padding: '3px 10px',
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  addNewBtn: {
    minHeight: 36,
    marginLeft: 8,
    padding: '3px 16px',
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  tableData: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: 8,
      fontSize: '0.85rem',
    },
    '& .MuiTableHead-root': {
      backgroundColor: grey[100],
    },
  },
  tablePagination: {
    marginTop: 20,
    marginBottom: 20,
  },
  statusActive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: green[600],
    color: 'white',
  },
  statusPending: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: yellow[500],
    color: 'black',
  },
  statusScheduled: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: yellow[700],
    color: 'black',
  },
  statusInactive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: red[500],
    color: 'white',
  },
  statusDraft: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[500],
    color: 'white',
  },
  statusNa: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[500],
    color: 'white',
  },
  statusUploading: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: yellow[900],
    color: 'white',
  },
  statusFailed: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: red[500],
    color: 'white',
  },
  deleteBtn: {
    padding: 8,
  },
  editBtn: {
    padding: 8,
    marginRight: 5,
  },
  noRecordFoundText: {
    color: 'red',
    padding: '2rem !important',
    fontSize: '1rem !important',
  },
  mb18: {
    marginBottom: '18px',
  },
  assignButton: {
    width: '12%',
  },
  sortDefaultIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  draggableTableRow: {
    display: 'block',
  },
  draggableTableCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'all-scroll',
    width: '100%',
  },
  sortUl: {
    padding: 0,
    margin: 0,
  },
  sortTableCell: {
    display: 'table-cell',
    float: 'left',
    padding: 8,
    color: 'rgba(0, 0, 0, 0.87)',
    verticalAlign: 'middle',
    fontWeight: 400,
    letterSpacing: ' 0.01071em',
  },
  sortRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  form50Section1: {
    width: '49%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  form50Section2: {
    width: '49%',
    float: 'left',
    marginLeft: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  styledLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: 'red',
    },
  },
});

export default Style;
