import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ErrorWrapper from '../../layout/Error';
import ErrorStyle from '../../theme/styles/Error';
import isPageInIframe from '../../utils/IsPageInIFrame';
import { updateContractStatus } from '../../services/Filmmakers';
import { filmmakerProgramStatus } from '../../config/Constants';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(ErrorStyle);

const ContractCancelledFrame = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    const doCheck = isPageInIframe();
    if (doCheck) {
      const data = {
        status: filmmakerProgramStatus.CONTRACT_CANCELLED,
      };
      updateContractStatus(token, data)
        .then(() => {
          history.replace(RoutePaths.HOME);
        })
        .catch(() => history.replace(RoutePaths.HOME));
    }
  }, []);

  return (
    <ErrorWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <Container component="div" className={classes.errorPageText}>
            <Typography component="h1" variant="h1">
              Cancelled
            </Typography>
            <Typography component="p">
              Looks like you&apos;ve cancelled the agreement/contract.
            </Typography>
            <Typography component="p">
              Do not close the window, the page will redirect you to the reveel page.
            </Typography>
          </Container>
        </Container>
      </Grid>
    </ErrorWrapper>
  );
};

export default ContractCancelledFrame;
