/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown, CloudUpload } from '@material-ui/icons';
import axios from 'axios';
import {
  getTrailerPreview,
  getVideoPreview,
  removeProgramVideos,
  uploadFilmAsset,
} from '../../services/Programs';
import {
  completeVideoUpload,
  getAWSMultipartUploadUrl,
  getAWSUploadUrl,
  getVideoUploadURL,
} from '../../services/Assets';
import ConfirmDialog from '../ConfirmDialog';
import SnackbarMessage from '../SnackbarMessage';
import ViewVideo from './ViewVideo';
import { createFilmmakerProgram } from '../../services/Filmmakers';
import { filmmkaerFilmsStatus, uploadVideoFileSize } from '../../config/Constants';
import getLocalStorageValue from '../../utils/LocalStorage';
import { getCurrentTimestamp } from '../../utils/Datetime';

const UploadAssets = ({
  caption,
  name,
  value,
  programId,
  chapterId,
  trailer,
  onUploadComplete,
  filmStatus,
  remove,
  updateProcessing,
  filmmakerProgramId,
  filmData,
  assetId,
  assetType,
  isSeries,
  disabled,
  isError,
  initialFilm,
  assetStatus,
  rejectionNote,
  removeTrailer,
}) => {
  const hiddenFileInput = useRef(null);
  const buttonId = Date.now();

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [filmmakerFilmId, setFilmmakerFilmId] = useState(filmmakerProgramId);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [dataAssetId, setDataAssetId] = useState(0);
  const [viewModel, setViewModel] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [filmmakerId, setFilmmakerId] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  let title;
  if (filmData?.type === 'SERIES') {
    if (filmData?.title) {
      title = filmData.title;
    } else {
      title = 'Untitled - series';
    }
  } else if (filmData?.title) {
    title = filmData.title;
  } else {
    title = 'Untitled - film';
  }
  const payload = {
    title: title || 'Untitled - film',
    type: filmData?.type || 'SHORT_FILM',
    shortDescription: filmData?.shortDescription || '',
    status: 0,
    videoUrl: null,
    chapters: [],
  };

  useEffect(() => {
    const localValues = getLocalStorageValue('user');
    setFilmmakerId(localValues.id);
    setHasError(isError);
    setVideoUrl(value);
    setDataAssetId(assetId);
  }, [value, isError]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
    setProgress(Math.round((p.loaded / p.total) * 100));
  };

  const onFinish = async (data, isMulti = false) => {
    let URL = '';
    if (isMulti) {
      URL = data.Location;
    } else {
      URL = data.signedUrl.split('?').shift();
    }

    setProgress(100);
    setProgress(0);
    setFileSelected(false);
    setVideoUrl(URL);
    setIsFileUploading(false);

    payload.id = data.id;
    const assetPayload = {
      assetType,
      assetUrl: URL,
      assetStatus: 2,
      programDetail: null,
      programChapterId: chapterId,
      programId,
      programStatus: filmStatus || 0,
      isSeries,
      id: assetId,
    };
    if (!initialFilm) {
      uploadFilmAsset(filmmakerProgramId || data?.id, assetPayload).then((res) =>
        onUploadComplete({ videoUrl: URL, id: res?.data, data: payload })
      );
    } else {
      onUploadComplete({ videoUrl: URL, data: payload });
    }
    hiddenFileInput.current = null;
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      updateProcessing();
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          onProgress(p);
        },
      });
      onFinish(data);
    } catch {
      onError();
    }
  };

  const downloadFile = async () => {
    setDownloading(true);

    let downloadUrl = videoUrl;
    if (typeof videoUrl === 'number') {
      if (trailer) {
        const response = await getTrailerPreview(programId, 'download');
        downloadUrl = response.data?.mediumUrl;
      } else {
        const response = await getVideoPreview(programId, chapterId, 'download');
        downloadUrl = response.data?.mediumUrl;
      }
    }
    fetch(downloadUrl)
      .then((resp) => resp.blob())
      .then((blobobject) => {
        const blob = window.URL.createObjectURL(blobobject);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        anchor.download = videoUrl.substring(videoUrl.lastIndexOf('/') + 1);
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        window.open(downloadUrl, '_blank');
      });
  };

  const uploadChunk = async (selectedFile, params, uploadId) => {
    updateProcessing();
    const FILE_CHUNK_SIZE = 1024 * 1024 * 50; // 50MB
    const fileSize = selectedFile.size;
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    const promisesArray = [];
    let start;
    let end;
    let blob;
    let completedBytes = 0;

    for (let index = 1; index < NUM_CHUNKS + 1; index += 1) {
      start = (index - 1) * FILE_CHUNK_SIZE;
      end = index * FILE_CHUNK_SIZE;
      blob = index < NUM_CHUNKS ? selectedFile.slice(start, end) : selectedFile.slice(start);

      // (1) Generate presigned URL for each part
      const getUploadUrlResp = await getVideoUploadURL({
        fileName: params.objectName,
        partNumber: index,
        path: params.path,
        uploadId,
      });

      const { presignedUrl } = getUploadUrlResp.data.data;

      // (2) Puts each file part into the storage server
      const uploadResp = await axios.put(presignedUrl, blob, {
        headers: { 'Content-Type': selectedFile.type },
        onUploadProgress: (evt) => {
          const uploadedBytes = evt.loaded + completedBytes;
          const uploadProgress = Math.round((uploadedBytes * 100) / selectedFile.size);
          setProgress(uploadProgress);
        },
      });

      // Updating the uploaded blob size
      completedBytes += blob.size;

      promisesArray.push(uploadResp);
    }

    const resolvedArray = await Promise.all(promisesArray);

    const uploadPartsArray = [];
    resolvedArray.forEach((resolvedPromise, index) => {
      uploadPartsArray.push({
        ETag: resolvedPromise.headers.etag,
        PartNumber: index + 1,
      });
    });

    // (3) Calls the completeVideoUpload endpoint in the backend server
    const completeUploadRes = await completeVideoUpload({
      fileName: params.objectName,
      parts: uploadPartsArray,
      path: params.path,
      uploadId,
    });
    onFinish(completeUploadRes.data, true);
  };

  const handleChange = async (e) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setHasError(false);
    const file = e.target.files[0];
    if (file?.type?.split('/')[0] === 'video') {
      setHasError(false);
      let resp;
      let error = false;
      if (!filmmakerProgramId) {
        await createFilmmakerProgram(payload)
          .then((response) => {
            resp = response;
            onUploadComplete({ filmId: resp.data });
          })
          .catch(({ response }) => {
            error = true;
            hiddenFileInput.current.value = null;
            setSnackbarMeesage({
              ...snackbarMeesage,
              message:
                response?.data?.message.replace(/["\\]/g, '') ||
                'Something went wrong. Please try again.',
              type: 'error',
              show: true,
            });
          });
      }

      const fileExtension = file?.name.split('.')[file?.name.split('.').length - 1];
      const currentTime = getCurrentTimestamp();
      const fileName = `${filmmakerId}_${currentTime}.${fileExtension}`;
      const params = {
        objectName: fileName,
        contentType: file.type,
      };
      if (!error) {
        if (file.size > uploadVideoFileSize) {
          params.path = `films/${filmmakerProgramId || resp?.data}/assets`;
          getAWSMultipartUploadUrl(params)
            .then((res) => {
              const uploadId = res?.data?.uploadId;
              setFileSelected(true);
              uploadChunk(file, params, uploadId);
            })
            .catch(() => {
              setHasError(true);
              setErrorMessage('Something went wrong.');
            });
        } else {
          params.path = `films/${filmmakerProgramId || resp?.data}/assets/`;
          getAWSUploadUrl(params)
            .then((res) => {
              res.id = resp?.data;
              uploadFile(res, file);
              setFileSelected(true);
            })
            .catch(() => {
              setHasError(true);
              setErrorMessage('Something went wrong.');
            });
        }
      }
      error = false;
    } else {
      setSnackbarMeesage({
        message: `Please select video file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeImage = () => {
    removeProgramVideos(filmmakerProgramId, 0, { image: name, src: null }).then(() => {
      setVideoUrl('');
      setDataAssetId(0);
      setConfirmDialog(false);
      removeTrailer();
    });
  };

  return (
    <>
      <input
        accept="video/*"
        id={`upload-vid-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading || filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL}
      />

      {videoUrl || dataAssetId > 0 ? (
        <div>
          <Button
            aria-controls={name}
            aria-haspopup="true"
            variant="outlined"
            fullWidth
            color={hasError || assetStatus === 0 ? 'primary' : ''}
            endIcon={<ArrowDropDown />}
            onClick={handleManageClick}
            style={{
              marginBottom: 20,
              color: hasError || assetStatus === 0 ? '#f44336' : 'green',
              borderColor: hasError || assetStatus === 0 ? '#f44336' : 'green',
            }}
          >
            {downloading && (
              <CircularProgress color="inherit" size={20} style={{ marginRight: '5px' }} />
            )}{' '}
            {`Manage ${caption}`}
          </Button>
          {rejectionNote && (
            <Box mt={-2}>
              <div
                style={{
                  color: '#f44336',
                }}
              >
                {rejectionNote}
              </div>
            </Box>
          )}
          <Menu
            id={name}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setViewModel(true);
              }}
              button
            >
              Preview Video
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                downloadFile();
              }}
              button
              disabled={downloading}
            >
              {downloading && (
                <CircularProgress color="inherit" size={20} style={{ marginRight: '5px' }} />
              )}{' '}
              Download Video
            </MenuItem>

            {(filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
              filmStatus !== filmmkaerFilmsStatus.ASSET_UPLOAD) ||
              (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD && trailer === 1 && (
                <>
                  {remove && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setConfirmDialog(true);
                      }}
                      button
                    >
                      Remove Video
                    </MenuItem>
                  )}
                </>
              ))}
            {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
              filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
                <label htmlFor={`upload-vid-button-${buttonId}`}>
                  <MenuItem button>
                    {!fileSelected && `Upload New Video`}
                    {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
                    {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                    {hasError && errorMessage}
                  </MenuItem>
                </label>
              )}
          </Menu>
        </div>
      ) : (
        <label htmlFor={`upload-vid-button-${buttonId}`}>
          <Button
            fullWidth
            onClick={handleClick}
            variant="outlined"
            color={hasError || !assetStatus ? 'primary' : ''}
            startIcon={<CloudUpload />}
            title={errorMessage}
            disabled={filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL || disabled}
          >
            {!fileSelected && `Upload ${caption}`}
            {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
            {hasError && errorMessage}
          </Button>
          {isFileUploading && (
            <div style={{ marginBottom: 20, color: 'grey' }}>
              Do not close the window or save the details until whole file is uploaded.
            </div>
          )}
        </label>
      )}

      {confirmDialog && (
        <ConfirmDialog
          title={`Remove ${trailer ? 'Trailer' : 'Video'} `}
          message="Do you want to delete this record? This action can not be undone."
          onClose={() => {
            setConfirmDialog(false);
          }}
          onApprove={() => removeImage()}
        />
      )}

      {viewModel && (
        <ViewVideo
          caption={caption}
          url={videoUrl}
          viewModelOpen={viewModel}
          trailer={trailer}
          handleViewModel={(flag) => setViewModel(flag)}
          programId={programId}
          chapterId={chapterId}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadAssets.propTypes = {
  caption: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  programId: PropTypes.number,
  chapterId: PropTypes.number,
  trailer: PropTypes.bool,
  remove: PropTypes.bool,
  onUploadComplete: PropTypes.func.isRequired,
  saveVideoUrl: PropTypes.bool,
  filmStatus: PropTypes.number,
  updateProcessing: PropTypes.func,
  filmmakerProgramId: PropTypes.number,
  filmData: PropTypes.objectOf,
  assetId: PropTypes.number,
  assetType: PropTypes.number.isRequired,
  isSeries: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isError: PropTypes.bool.isRequired,
  initialFilm: PropTypes.bool,
  assetStatus: PropTypes.number,
  rejectionNote: PropTypes.string,
  removeTrailer: PropTypes.func,
};
UploadAssets.defaultProps = {
  value: '',
  programId: 0,
  chapterId: 0,
  trailer: false,
  saveVideoUrl: true,
  filmStatus: null,
  remove: true,
  updateProcessing: () => true,
  filmmakerProgramId: 1,
  filmData: {},
  assetId: 0,
  disabled: false,
  initialFilm: false,
  assetStatus: 9,
  rejectionNote: '',
  removeTrailer: () => {},
};

export default UploadAssets;
