import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const listAllFilms = async (page, params) => {
  const result = await getApiCall(`/filmmakers/films?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const getFilmmakerProgramById = async (filmmakerProgramId) => {
  const result = await getApiCall(`/filmmakers/films/${filmmakerProgramId}/view`);
  return result.data;
};

export const createFilmmakerProgram = async (data) => {
  const result = await postApiCall(`/filmmakers/films/create`, data);
  return result.data;
};

export const updateFilmmakerProgram = async (filmmakerProgramId, data) => {
  const result = await postApiCall(`/filmmakers/films/${filmmakerProgramId}/update`, data);
  return result.data;
};

export const deleteFilmById = async (filmmakerProgramId) => {
  const result = await deleteApiCall(`/filmmakers/films/${filmmakerProgramId}/remove`);
  return result.data;
};

export const getFilmAssets = async (filmId) => {
  const result = await getApiCall(`/filmmakers/films/${filmId}/assets`);
  return result.data;
};

export const viewContractByToken = async (token) => {
  const result = await getApiCall(`/filmmaker-films/contract/${token}/view`);
  return result.data;
};

export const updateContractStatus = async (token, data) => {
  const result = await postApiCall(`/filmmaker-films/contract/${token}/status-change`, data);
  return result.data;
};

export const getBtsFiles = async (page, params, filmId) => {
  const result = await getApiCall(
    `/filmmakers/films/${filmId}/bts-docs?page=${page}${params ? `&${params}` : ''}`
  );
  return result.data;
};

export const createBtsFile = async (data) => {
  const result = await postApiCall(`/filmmakers/films/bts-docs/create`, data);
  return result.data;
};

export const updateBtsFile = async (fileId, data) => {
  const result = await postApiCall(`/filmmakers/films/bts-docs/${fileId}/update`, data);
  return result.data;
};

export const deleteBtsFile = async (fileId) => {
  const result = await deleteApiCall(`/filmmakers/films/bts-docs/${fileId}/remove`);
  return result.data;
};

export const getFilmmakerProfile = async (id) => {
  const result = await getApiCall(`/filmmakers/profile/${id}/view`);
  return result.data;
};

export const updateFilmmakerProfile = async (id, data) => {
  const result = await postApiCall(`/filmmakers/profile/${id}/update`, data);
  return result.data;
};

export const changeFilmmakerPassword = async (data) => {
  const response = await postApiCall(`/filmmakers/change-password`, data);
  return response.data;
};

export const updateFilmmakerAssets = async (filmmakerProgramId, data) => {
  const result = await postApiCall(`/filmmakers/films/${filmmakerProgramId}/update-assets`, data);
  return result.data;
};

export const getCountriesList = async (searchText) => {
  const result = await getApiCall(
    `/filmmakers/countries/search${searchText ? `?searchText=${searchText}` : ''}`
  );
  return result.data;
};

export const getNewEpisodeId = async (filmmakerProgramId) => {
  const result = await getApiCall(`/filmmakers/films/${filmmakerProgramId}/new-episode-id`);
  return result.data;
};

export const updateFilmsAssetStatus = async (assetId, data) => {
  const result = await postApiCall(`/filmmakers/asset/status/${assetId}/update`, data);
  return result.data;
};

export const updateFilmmakerNotificationById = async (id, data) => {
  const result = await postApiCall(`/filmmakers/notification/${id}/update`, data);
  return result.data;
};

export const deleteChapter = async (chapterId, data) => {
  const result = await postApiCall(`/filmmakers/${chapterId}/delete-chapter`, data);
  return result.data;
};

export const getPromotionPrompts = async () => {
  const result = await getApiCall('/filmmaker-promotions/prompts');
  return result.data;
};

export const getPromotionAssetsById = async (programId, filmPromotionStatus) => {
  const result = await getApiCall(
    `/filmmaker-promotions/${programId}/submitted-files?promotionStatus=${filmPromotionStatus}`
  );
  return result.data;
};

export const updatePromotionDetails = async (data) => {
  const result = await postApiCall(`/filmmaker-promotions/details/update`, data);
  return result.data;
};

export const removePromotionAssetById = async (id) => {
  const result = await postApiCall(`/filmmakers/promotion/${id}/remove-asset`, null);
  return result.data;
};

export const affiliateCounters = async (filmmakerId) => {
  const result = await getApiCall(`/filmmakers/affiliate/count?filmmakerId=${filmmakerId}`);
  return result.data;
};

export const createFilmmakerFilmsShortlink = async (data) => {
  const result = await postApiCall(`/filmmakers/films/shortlink/create`, data);
  return result.data;
};

export const updateRequestReleaseDate = async (data) => {
  const result = await postApiCall(`/filmmakers/films/update/request-release-date`, data);
  return result.data;
};

export const uploadFilmmakerPromotionAsset = async (data) => {
  const result = await postApiCall('/filmmakers/films/promotion-assets/upload', data);
  return result.data;
};

export const createTags = async (data) => {
  const result = await postApiCall('/filmmakers/tags/create', data);
  return result.data;
};

export const getPaymentIframe = async (data) => {
  const result = await getApiCall(`/filmmakers/get-iframe-url`, data);
  return result.data;
};
