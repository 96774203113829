import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useHistory } from 'react-router-dom';
import SidebarNavs from './SidebarNavs';
import logo from '../assets/images/logo.png';
import menubarStyle from '../theme/styles/components/Menubar';
import RoutePaths from '../config/Routes';

const useStyles = makeStyles(menubarStyle);

// Menubar with sidebar options
const Menubar = ({ pageName }) => {
  const classes = useStyles();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [showResourcesTab, setShowResourcesTab] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutUser = () => {
    history.push(RoutePaths.LOGOUT);
  };

  useEffect(() => {
    try {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (!isLoggedIn) {
        logoutUser();
      } else {
        const user = window.localStorage.getItem('user');
        const userNameObj = user ? JSON.parse(user) : null;
        setUserId(user ? userNameObj.id : null);
        setUserDisplayName(user ? userNameObj.name : '');
        setShowResourcesTab(user ? userNameObj.showResourcesTab : null);
      }
    } catch (e) {
      logoutUser();
    }
  }, []);

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleToggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {pageName}
          </Typography>

          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className={classes.userMenu}
          >
            <AccountCircle /> {userDisplayName}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.userOptions}
          >
            <MenuItem onClick={logoutUser}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleToggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
            <SidebarNavs userId={userId} showResourcesTab={showResourcesTab} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
            <SidebarNavs userId={userId} showResourcesTab={showResourcesTab} />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

Menubar.propTypes = {
  pageName: PropTypes.string.isRequired,
};

export default Menubar;
