import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const ConfirmDialog = ({
  title,
  message,
  approveButtonText,
  onClose,
  onApprove,
  approveProcessing,
  closeButtonText,
  hideDeleteBtn,
}) => (
  <Dialog open>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {!hideDeleteBtn && (
        <Button
          onClick={onApprove}
          autoFocus
          endIcon={approveProcessing && <CircularProgress size={25} />}
          disabled={approveProcessing}
          color="primary"
          variant="contained"
        >
          {approveButtonText}
        </Button>
      )}
      <Button onClick={onClose} variant="contained">
        {closeButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  approveButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  approveProcessing: PropTypes.bool,
  hideDeleteBtn: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  approveButtonText: 'Delete',
  closeButtonText: 'Cancel',
  approveProcessing: false,
  hideDeleteBtn: false,
};
