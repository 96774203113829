import green from '@material-ui/core/colors/green';

// Style for input froms
const Style = () => ({
  formInput: {
    marginBottom: 20,
    '& .css-yk16xz-control': {
      minHeight: 56,
    },
    '& .css-1pahdxg-control': {
      minHeight: 56,
      borderColor: green[600],
    },
    '& .css-1pahdxg-control:hover': {
      borderColor: green[600],
    },
  },
  chip: {
    margin: 2,
  },
  dialogContent: {
    '& .MuiGrid-item': {
      padding: '2px 6px',
    },
  },
  dialogContentNoTopPadding: {
    paddingTop: '0px !important',
  },
  formErrorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336',
  },
  leftAlignButtonAction: {
    justifyContent: 'flex-start',
  },
  threeLineEllipsis: {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default Style;
