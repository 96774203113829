import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import { getTrailerPreview, getVideoPreview } from '../../services/Programs';

const ViewVideo = ({ trailer, viewModelOpen, handleViewModel, url, programId, chapterId }) => {
  const [open, setOpen] = useState(viewModelOpen);
  const [processing, setProcessing] = useState(false);
  const [videoPreview, setVideoPreview] = useState({
    src: '',
    thumb: '',
    poster: '',
  });

  const handleClose = () => {
    setOpen(false);
    handleViewModel(false);
  };

  useEffect(() => {
    setProcessing(true);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(url) && url && url.includes('amazonaws')) {
      setVideoPreview({
        src: url,
        thumb: '',
        poster: '',
      });
      setProcessing(false);
    } else if (trailer) {
      getTrailerPreview(programId)
        .then((res) => {
          setVideoPreview({
            src: res.data.previewUrl || url,
            thumb: res.data.thumbnailUrl,
            poster: '',
          });
          setProcessing(false);
        })
        .catch(() => {
          setVideoPreview({ src: url, thumb: '', poster: '' });
          setProcessing(false);
        });
    } else {
      getVideoPreview(programId, chapterId)
        .then((res) => {
          setVideoPreview({
            src: res.data.previewUrl || url,
            thumb: res.data.thumbnailUrl,
            poster: '',
          });
          setProcessing(false);
        })
        .catch(() => {
          setVideoPreview({ src: url, thumb: '', poster: '' });
          setProcessing(false);
        });
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{trailer ? 'Trailer' : 'Video'} Preview</DialogTitle>
      <DialogContent>
        {processing && <CircularProgress size={30} />}
        {!processing && (
          <Box justifyContent="center" display="flex" flexDirection="column">
            <Box style={{ justifyContent: 'center', display: 'flex' }}>
              <VideoPlayer
                controls
                src={videoPreview.src ? videoPreview.src : url || ''}
                poster=""
                width="900"
              />
            </Box>
            <div
              style={{
                marginTop: 5,
                marginBottom: 5,
                width: 800,
                color: 'grey',
                fontSize: 10,
                textAlign: 'center',
              }}
            >
              Note: Some video preview may not work in browser,{' '}
              <a href={videoPreview.src || url} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to view/download the video.
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewVideo.propTypes = {
  trailer: PropTypes.bool.isRequired,
  viewModelOpen: PropTypes.bool.isRequired,
  handleViewModel: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  chapterId: PropTypes.number.isRequired,
};

export default ViewVideo;
