import grey from '@material-ui/core/colors/grey';

// Style for sidebar navigations
const Style = (theme) => ({
  menuItemContainer: {
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 0,
    color: 'white',
  },
  menuItem: {
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  menuSubItem: {
    paddingLeft: 30,
    color: 'white',
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  menuIcon: {
    minWidth: 0,
    width: 30,
    color: 'white',
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem',
    },
  },
  menuText: {
    marginLeft: 15,
    color: 'white',
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
    },
  },
});

export default Style;
