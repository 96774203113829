const drawerWidth = 250;

// Style for menubar and sidebar
const Style = (theme) => ({
  drawer: {
    backgroundColor: 'black',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)',
  },
  menuButton: {
    color: 'black',
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'black',
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  logo: {
    width: '150px',
    margin: '10px auto',
  },
  userMenu: {
    padding: 5,
    '& .MuiIconButton-label': {
      fontSize: '1rem',
    },
    '& .MuiSvgIcon-root': {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  userOptions: {
    '& .MuiList-root': {
      width: 160,
    },
  },
});

export default Style;
