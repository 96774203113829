/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PublicWrapper from '../../layout/Public';
import RoutePaths from '../../config/Routes';
import loginStyle from '../../theme/styles/pages/Login';
import { validateFilmmakerLogin } from '../../services/Auth';

const useStyles = makeStyles(loginStyle);
const useQuery = () => new URLSearchParams(useLocation().search);

// Auto login page
const AutoLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const tokenAuth = query.get('auth') || 0;

  useEffect(() => {
    validateFilmmakerLogin({ token: tokenAuth })
      .then((res) => {
        const data = res?.data;
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('isLoggedIn');

        window.localStorage.setItem('isLoggedIn', true);
        window.localStorage.setItem('user', JSON.stringify(res.user));
        window.location.assign(RoutePaths.HOME);
      })
      .catch((e) => {
        history.push(RoutePaths.LOGOUT);
      });
  }, []);

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs" className={classes.loadingContainer}>
          <CircularProgress color="inherit" />

          <Typography component="p" className={classes.loadingText}>
            Logging...
          </Typography>
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default AutoLogin;
