import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PrivateWrapper from '../../layout/Private';
import FilmsUploadAssetsForm from '../../components/filmmakers/FilmsUploadAssetsForm';
import { getFilmmakerProgramById } from '../../services/Filmmakers';

const FilmsViewPage = () => {
  const pageName = 'My Films / View';

  const params = useParams();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [copyProgramDetails, setCopyProgramDetails] = useState(false);
  const [programChapterId, setProgramChapterId] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [isEpisode, setIsEpisode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isNewEpisode, setIsNewEpisode] = useState(0);

  const handleAddEpisode = () => {
    window.FreshworksWidget('hide');
    setProgramChapterId(null);
    setIsAddMode(true);
    setOpenAddDialog(true);
    setIsEpisode(true);
  };

  const updateCopyEpisodeFlag = () => {
    setCopyProgramDetails(false);
  };

  const handleCloseAddEpisode = () => {
    window.FreshworksWidget('show');
    setIsAddMode(false);
    setCopyProgramDetails(false);
    setOpenAddDialog(false);
    setIsEpisode(false);
  };

  const handleViewEpisode = (id, newEpisodeFlag) => {
    setIsNewEpisode(newEpisodeFlag);
    setProgramChapterId(id);
    setIsAddMode(true);
    window.FreshworksWidget('hide');
    setOpenViewDialog(true);
    setIsEpisode(true);
  };

  const handleCloseViewEpisode = () => {
    setIsAddMode(false);
    setCopyProgramDetails(false);
    window.FreshworksWidget('show');
    setOpenViewDialog(false);
    setIsEpisode(false);
  };

  const handleCopyProgrmaDetails = () => {
    setCopyProgramDetails(true);
  };

  useEffect(() => {
    if (params.id) {
      getFilmmakerProgramById(params.id).then((res) => {
        const data = res?.data || null;
        if (data && data.programDetail.isCopy) {
          setProgramData(res?.data || null);
        }
      });
    }
  }, [isEpisode]);

  return (
    <PrivateWrapper pageName={pageName}>
      <FilmsUploadAssetsForm
        showInDialog={isEpisode}
        showAddEpisodeDialog={handleAddEpisode}
        showViewEpisodeDialog={handleViewEpisode}
        copyProgramDetails={copyProgramDetails}
        updateCopyEpisodeFlag={updateCopyEpisodeFlag}
      />

      {openAddDialog && (
        <Dialog open fullWidth maxWidth="lg">
          <DialogTitle>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>Add Episode Details</Box>
              {programData &&
                programData?.filmmakerProgramStatus !== 3 &&
                programData?.filmmakerProgramStatus !== 5 && (
                  <Box container item xs={6} md={6} justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="button"
                      onClick={() => handleCopyProgrmaDetails()}
                    >
                      Copy Program Details
                    </Button>
                  </Box>
                )}
            </Box>
          </DialogTitle>
          <DialogContent>
            <FilmsUploadAssetsForm
              showInDialog={isEpisode}
              closeAddViewEpisodeDialog={handleCloseAddEpisode}
              copyProgramDetails={copyProgramDetails}
              programChapterId={programChapterId}
              programData={copyProgramDetails ? programData : null}
              isAddMode={isAddMode}
              updateCopyEpisodeFlag={updateCopyEpisodeFlag}
            />
          </DialogContent>
        </Dialog>
      )}

      {openViewDialog && (
        <Dialog open fullWidth maxWidth="lg">
          <DialogTitle>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>View Episode Details</Box>
              {((programData &&
                programData?.filmmakerProgramStatus !== 3 &&
                programData?.filmmakerProgramStatus !== 4 &&
                programData?.filmmakerProgramStatus !== 5) ||
                (programData?.filmmakerProgramStatus === 4 && isNewEpisode === 1)) && (
                <Box container item xs={6} md={6} justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="button"
                    onClick={() => handleCopyProgrmaDetails()}
                  >
                    Copy Program Details
                  </Button>
                </Box>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            <FilmsUploadAssetsForm
              showInDialog={isEpisode}
              closeAddViewEpisodeDialog={handleCloseViewEpisode}
              copyProgramDetails={copyProgramDetails}
              programChapterId={programChapterId}
              programData={copyProgramDetails ? programData : null}
              updateCopyEpisodeFlag={updateCopyEpisodeFlag}
            />
          </DialogContent>
        </Dialog>
      )}
    </PrivateWrapper>
  );
};

export default FilmsViewPage;
