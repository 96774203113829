import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router';
import SnackbarMessage from '../../components/SnackbarMessage';
import Validations from '../../utils/Validations';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo-red.png';
import loginStyle from '../../theme/styles/pages/Login';
import { setNewPassword, validateResetPasswordToken } from '../../services/Auth';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(loginStyle);

const ResetPassword = () => {
  const params = useParams();
  const pageName = 'Reset Password';
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    setIsValidToken(false);
    if (params.slug) {
      validateResetPasswordToken(params.slug)
        .then(() => {
          setIsValidToken(true);
        })
        .catch(() => {
          setIsValidToken(false);
        });
    }
  }, [params]);

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setNewPassword(params.slug, data)
      .then(() => {
        setIsSubmitted(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Password set successfully.',
          type: 'success',
          show: true,
        });
        window.location.assign(RoutePaths.LOGIN);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        {!isValidToken && (
          <Container maxWidth="xs">
            <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
            <Typography component="h6">Invalid Link</Typography>
          </Container>
        )}
        {isValidToken && (
          <Container maxWidth="xs">
            <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
            <Container component="div" className={classes.authPageText}>
              <Typography component="h1" variant="h5">
                {pageName}
              </Typography>
            </Container>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
              <FormControl className={classes.authInput}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="password"
                  id="password"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Password"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="password"
                      autoFocus
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.authInput}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="confirmPassword"
                  id="confirmPassword"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Confirm Password"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="password"
                    />
                  )}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.authSubmitBtn}
                disabled={isSubmitted}
              >
                Submit
              </Button>
            </form>
          </Container>
        )}

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Grid>
    </PublicWrapper>
  );
};

export default ResetPassword;
