/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import listingStyle from '../../theme/styles/pages/Listing';
import Validations, {
  MaxLengthValidationFunc,
  ExactLengthValidationFunc,
} from '../../utils/Validations';
import UploadAssets from '../assets/UploadAsset';
import VideoTypes from '../../config/VideoTypes';
import Languages from '../../config/Languages';
import SearchableAutocomplete from '../SearchableAutocomplete';
import { searchFilmmakersGenres, searchSecondaryGenres, searchTags } from '../../services/Genres';
import {
  getCountriesList,
  getFilmmakerProgramById,
  getNewEpisodeId,
  updateFilmmakerAssets,
} from '../../services/Filmmakers';
import RoutePaths from '../../config/Routes';
import countriesList from '../../services/List';
import {
  filmmakerAssetsStatus,
  filmmakerFilmsAssetsType,
  filmmkaerFilmsStatus,
} from '../../config/Constants';
import EpisodeList from './EpisodeList';
import SnackbarMessage from '../SnackbarMessage';
import UploadMultipleAssets from './UploadMultipleAssets';
import ManageSubtitles from './ManageSubTitles';
import GeoRestrictionModal from './GeoRestricationModal';
import validateFilmAssets from '../../utils/FilmmakerFilms';
import 'react-datepicker/dist/react-datepicker.css';
import ScheduleFilmModal from './ScheduleFilmModal';
import AppLoader from '../AppLoader';

const useStyles = makeStyles(listingStyle);

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const FilmsUploadAssetsForm = ({
  showInDialog,
  showAddEpisodeDialog,
  showViewEpisodeDialog,
  closeAddViewEpisodeDialog,
  copyProgramDetails,
  programChapterId,
  programData,
  isAddMode,
  updateCopyEpisodeFlag,
}) => {
  const classes = useStyles();
  const updatedfilmStatus = useRef(0);
  const { control, handleSubmit, setValue, getValues, reset } = useForm();
  const params = useParams();
  const history = useHistory();
  const newEpisodeId = useRef(0);
  const isNewEpisode = useRef(0);
  const isShowMessage = useRef(true);
  const isChange = useRef(false);
  const validateStatus = useRef({
    isProcess: false,
    trailer: false,
    film: false,
    thumbnail: false,
    thumbnailWithoutText: false,
    videoSrt: false,
  });
  const episodeError = useRef(null);
  // const unsavedSortedCast = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isSeries, setIsSeries] = useState(false);
  const [processing, setProcessing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isError, setIsError] = useState(false);
  const [isEpisodeDeleted, setIsEpisodeDeleted] = useState(false);
  const [isShowUploadModel, setIsShowUploadModel] = useState(false);
  const [isShowSRTModel, setIsShowSRTModel] = useState(false);
  const [openGeoRestricationDialog, setOpenGeoRestricationDialog] = useState(false);
  const [openPublishDateModal, setOpenPublishDateModal] = useState(false);
  const [filmStatus, setFilmStatus] = useState(0);
  const [episodeStatus, setEpisodeStatus] = useState(0);
  const [assetType, setAssetType] = useState(2);
  const [episodeDetail, setEpisodeDetail] = useState([]);
  const [reveelProgramId, setReveelProgramId] = useState(0);
  const [reveelProgramChapterId, setReveelProgramChapterId] = useState(0);
  const [selectedAssetData, setSelectedAssetData] = useState({
    title: '',
    data: [],
  });
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [assetsData, setAssetsData] = useState({
    film: {
      assetType: filmmakerFilmsAssetsType.FILM_VIDEO,
      url: '',
    },
    trailer: {
      assetType: filmmakerFilmsAssetsType.TRAILER_VIDEO,
      url: '',
    },
    thumbnail: [],
    thumbnailWithoutText: [],
    transparentFiles: [],
    btsFiles: [],
    videoSrtFiles: [],
    trailerSrtFiles: [],
    geoRestriction: {},
  });
  const [assetsStatus, setAssetsStatus] = useState({
    programDetails: 1,
    thumbnail: 1,
    thumbnailWithOutText: 1,
    film: 1,
    trailer: 1,
    trailerSrt: 1,
    videoSrt: 1,
    btsVideos: 1,
    transparentFiles: 1,
  });

  const {
    fields: crewFields,
    append: crewAppend,
    remove: crewRemove,
  } = useFieldArray({
    control,
    name: 'crew',
  });
  const {
    fields: castFields,
    append: castAppend,
    remove: castRemove,
    move: castMove,
  } = useFieldArray({
    control,
    name: 'cast',
  });

  const loadPrimaryGenresOptions = (inputText, callBack) => {
    searchFilmmakersGenres(inputText).then((res) => {
      callBack(res.data.rows);
    });
  };
  const loadSecondaryGenresOptions = (inputText, callBack) => {
    searchSecondaryGenres(inputText).then((res) => {
      callBack(res.data.rows);
    });
  };

  const loadTagsOptions = (inputText, callBack) => {
    searchTags(inputText).then((res) => {
      callBack(res.data.rows);
    });
  };

  const loadAudioLanguageOptions = (inputText, callBack) => {
    const filteredLanguages = Languages.filter(
      (lang) =>
        lang.name.toLowerCase().includes(inputText.toLowerCase()) ||
        lang.code.toLowerCase().includes(inputText.toLowerCase())
    );

    callBack(filteredLanguages.map((r) => ({ id: r.code, name: `${r.name} (${r.code})` })));
  };

  const loadCountriesOptions = (inputText, callBack) => {
    countriesList(inputText).then((res) => {
      callBack(res.data?.rows);
    });
  };

  const handleBack = () => {
    history.push(RoutePaths.HOME);
  };

  const handleUploadThumbnail = (type) => {
    window.FreshworksWidget('hide');
    setAssetType(type);
    if (type === filmmakerFilmsAssetsType.THUMBNAIL_WITH_TEXT) {
      setSelectedAssetData({
        title: 'Upload Thumbnails Image',
        data: assetsData.thumbnail,
      });
      setIsShowUploadModel(true);
    } else if (type === filmmakerFilmsAssetsType.THUMBNAIL_WITHOUT_TEXT) {
      setSelectedAssetData({
        title: 'Upload Thumbnails Without Text Image',
        data: assetsData.thumbnailWithoutText,
      });
      setIsShowUploadModel(true);
    } else if (type === filmmakerFilmsAssetsType.TRANSPARENT_LOGO) {
      setSelectedAssetData({
        title: 'Upload Transparent Title Graphic',
        data: assetsData.transparentFiles,
      });
      setIsShowUploadModel(true);
    } else if (type === filmmakerFilmsAssetsType.VIDEO_SRT) {
      setSelectedAssetData({
        title: 'Video Subtitles List',
        data: assetsData.videoSrtFiles,
      });
      setIsShowSRTModel(true);
    } else if (type === filmmakerFilmsAssetsType.TRAILER_SRT) {
      setSelectedAssetData({
        title: 'Trailer Subtitles List',
        data: assetsData.trailerSrtFiles,
      });
      setIsShowSRTModel(true);
    } else if (type === filmmakerFilmsAssetsType.BTS_VIDEO) {
      setSelectedAssetData({
        title: 'Upload BTS and Stills',
        data: assetsData.btsFiles,
      });
      setIsShowUploadModel(true);
    }
  };

  const onFilmUploadComplete = (v) => {
    validateStatus.current.film = false;
    setAssetsData((prevAssetData) => ({
      ...prevAssetData,
      film: {
        assetType: filmmakerFilmsAssetsType.FILM_VIDEO,
        assetUrl: v.videoUrl || '',
        assetStatus: filmmakerAssetsStatus.PENDING,
        id: v.id ? v?.id : assetsData.film.id,
      },
    }));
  };

  const onTrailerUploadComplete = (v) => {
    validateStatus.current.trailer = false;
    setAssetsData((prevAssetData) => ({
      ...prevAssetData,
      trailer: {
        assetType: filmmakerFilmsAssetsType.TRAILER_VIDEO,
        assetUrl: v.videoUrl || '',
        assetStatus: filmmakerAssetsStatus.PENDING,
        id: v.id ? v?.id : assetsData.trailer.id,
        isTrailer: 1,
      },
    }));
  };

  const removeTrailer = () => {
    setAssetsData({
      ...assetsData,
      trailer: {
        assetType: filmmakerFilmsAssetsType.TRAILER_VIDEO,
        assetUrl: '',
        isTrailer: 1,
      },
    });
  };

  useEffect(() => {
    getNewEpisodeId(params.id)
      .then((res) => {
        if (res.data.newEpisodeId < 0) {
          newEpisodeId.current = res.data.newEpisodeId - 1;
        } else {
          newEpisodeId.current = -1;
        }
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data?.message,
          type: 'error',
          show: true,
        });
      });
  }, [isAddMode]);

  const defaultFormValues = getValues();

  const setupData = async (res) => {
    let data = res?.data || null;

    const episodeDataById = data?.programDetail?.chapters?.filter(
      (f) => f?.programDetail?.id === programChapterId
    );
    setFilmStatus(res?.data?.filmmakerProgramStatus || 2);
    setEpisodeStatus(
      episodeDataById.length > 0 ? episodeDataById[0]?.programDetail?.episodeStatus : 0
    );
    setIsSeries(data?.programDetail?.type === 'SERIES');
    if (episodeDataById && episodeDataById.length > 0 && copyProgramDetails) {
      reset({
        data: 'episodeTitle',
      });
      data = {
        filmmakerProgramStatus: programData.filmmakerProgramStatus,
        programDetail: {
          ...programData.programDetail,
          geoRestriction: {},
          title: getValues().episodeTitle,
          // runtime: episodeDataById[0].programDetail?.runtime,
        },
        episodeDataById,
      };
    } else if (episodeDataById && episodeDataById.length > 0 && !copyProgramDetails && !isAddMode) {
      reset();
      data = {
        ...episodeDataById[0],
      };
      isNewEpisode.current = episodeDataById[0]?.programDetail?.isNewEpisode;
    } else if (isAddMode && copyProgramDetails) {
      reset({
        data: 'episodeTitle',
      });
      data = {
        filmmakerProgramStatus: programData.filmmakerProgramStatus,
        programDetail: { ...programData.programDetail, geoRestriction: {}, runtime: 0 },
      };
    } else if (isAddMode && !copyProgramDetails) {
      data = null;
    }

    let geoData = data?.programDetail?.geoRestriction || null;
    if (!Array.isArray(geoData)) {
      geoData = [{ ...geoData }];
    }
    if (!copyProgramDetails) {
      const programMetaDataStatus =
        data?.programDetail?.assetStatus !== undefined ? data?.programDetail?.assetStatus : 9;
      setAssetsStatus({
        ...assetsStatus,
        programDetails: isAddMode ? 9 : programMetaDataStatus,
        trailerSrt: data?.assets?.trailerSrt?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
        videoSrt:
          data?.assets?.videoSrtFiles.length > 0
            ? data?.assets?.videoSrtFiles?.some(
                (item) =>
                  item.assetStatus === filmmakerAssetsStatus.APPROVED ||
                  item.assetStatus === filmmakerAssetsStatus.PENDING
              )
            : true,
        btsVideos: data?.assets?.btsFiles?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
        transparentFiles: data?.assets?.transparentFiles?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
        thumbnail:
          data?.assets?.thumbnail.length > 0
            ? data?.assets?.thumbnail?.some(
                (item) =>
                  item.assetStatus === filmmakerAssetsStatus.APPROVED ||
                  item.assetStatus === filmmakerAssetsStatus.PENDING
              )
            : true,
        thumbnailWithOutText: data?.assets?.thumbnailWithOutText.length
          ? data?.assets?.thumbnailWithOutText?.some(
              (item) =>
                item.assetStatus === filmmakerAssetsStatus.APPROVED ||
                item.assetStatus === filmmakerAssetsStatus.PENDING
            )
          : true,
        trailer: data?.assets?.trailer?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
        film:
          data?.assets?.film &&
          Array.isArray(data?.assets?.film) &&
          data?.assets?.film?.some(
            (item) =>
              item.assetStatus === filmmakerAssetsStatus.APPROVED ||
              item.assetStatus === filmmakerAssetsStatus.PENDING
          ),
      });
    }
    let tmpGeoData = null;
    if (geoData) {
      if (geoData.length > 0 && geoData[0]?.countries && geoData[0]?.countries.length > 0) {
        tmpGeoData = geoData;
      } else {
        const countryData = await getCountriesList();
        const tData = [];
        const oldData = geoData && geoData.length > 0 ? geoData[0].countryCodes : '';
        const cCode = oldData && oldData.length > 0 ? oldData.split(',') : [];
        cCode.forEach((c) => {
          const d = countryData.data.rows.filter((t) => t.code === c.trim())[0];
          tData.push({
            id: d?.code,
            name: d?.countryName,
          });
        });
        tmpGeoData = [
          {
            countries: tData,
            type: geoData?.[0]?.type ? geoData?.[0]?.type : 'include',
          },
        ];
      }
    }

    setReveelProgramId(res.data.programDetail?.reveelProgramId || null);
    if (episodeDataById && episodeDataById.length > 0) {
      setReveelProgramChapterId(episodeDataById[0]?.programDetail?.id);
    } else {
      setReveelProgramChapterId(
        data?.programDetail?.chapters[0]?.id
          ? data?.programDetail?.chapters[0]?.id
          : data?.programDetail?.id
      );
    }
    if (
      (!data?.programDetail?.crew || data?.programDetail?.crew.length === 0) &&
      !copyProgramDetails
    ) {
      crewAppend();
    } else {
      setValue('crew', data?.programDetail?.crew);
    }
    if (
      (!data?.programDetail?.cast || data?.programDetail?.cast.length === 0) &&
      !copyProgramDetails
    ) {
      castAppend();
    } else {
      setValue('cast', data?.programDetail?.cast);
    }

    setEpisodeDetail(data?.programDetail?.chapters || []);
    if (showInDialog && !isAddMode) {
      setValue(
        'episodeTitle',
        (!copyProgramDetails &&
          (episodeDataById[0]?.programDetail?.title ||
            episodeDataById[0]?.programDetail?.episodeTitle)) ||
          (copyProgramDetails && data?.programDetail?.title) ||
          ''
      );
    }
    setValue(
      'shortDescription',
      (programChapterId && episodeDataById[0]?.programDetail?.shortDescription) || ''
    );
    let countries = [];
    if (Array.isArray(data?.programDetail?.countryOfOrigin)) {
      countries = data?.programDetail?.countryOfOrigin;
    } else if (
      data?.programDetail?.countryOfOrigin &&
      Object.keys(data?.programDetail?.countryOfOrigin).length > 0
    ) {
      countries.push(data?.programDetail?.countryOfOrigin);
    }
    setValue('description', (programChapterId && episodeDataById[0]?.description) || '');
    setValue('title', data?.programDetail?.title || '');
    setValue('type', data?.programDetail?.type);
    setValue('season', defaultFormValues.season || Number(data?.programDetail?.season) || 1);
    setValue('shortDescription', data?.programDetail?.shortDescription || '');
    setValue('copyright', data?.programDetail?.copyright || '');
    setValue('description', data?.programDetail?.description || '');
    setValue('primayGenres', data?.programDetail?.primayGenres || []);
    setValue('countryOfOrigin', countries || null);
    setValue('secondaryGenres', data?.programDetail?.secondaryGenres || []);
    setValue('tags', data?.programDetail?.tags || []);
    setValue('year', Number(data?.programDetail?.year) || null);
    setValue('studio', data?.programDetail?.studio || '');
    setValue('rating', data?.programDetail?.rating || ' ');
    setValue('ratingRemarks', data?.programDetail?.ratingRemarks || '');
    setValue('productionCompanies', data?.programDetail?.productionCompanies || '');
    setValue('imdbId', data?.programDetail?.imdbId || '');
    setValue('filmingLocations', data?.programDetail?.filmingLocation || '');
    setValue('audioLanguage', data?.programDetail?.audioLanguage || []);
    setValue('chapters', res?.data?.chapters || []);

    if (!copyProgramDetails) {
      let tmpData = assetsData;
      let filmAssetUrl = null;
      if (data?.assets?.film[0]?.assetStatus === 1) {
        filmAssetUrl = showInDialog
          ? data?.programDetail?.id
          : data?.programDetail?.chapters?.[0]?.id || data?.assets?.film[0]?.assetChapterId;
      } else if (data?.assets?.film[0]?.assetUrl) {
        filmAssetUrl = data?.assets?.film[0]?.assetUrl;
      } else if (data?.programDetail?.title) {
        filmAssetUrl = data?.programDetail?.title;
      }
      tmpData.film = {
        ...data?.assets?.film[0],
        assetType: filmmakerFilmsAssetsType.FILM_VIDEO,
        assetUrl: filmAssetUrl,
        assetChapterId: data?.assets?.film[0]?.assetChapterId || null,
        id:
          data?.assets?.film?.[0]?.id ||
          data?.assets?.film?.id ||
          data?.programDetail?.chapters?.[0]?.id ||
          null,
      };

      // tmpData.film = data?.assets?.film || [];

      let trailerAssetUrl = null;
      if (data?.programDetail?.trailerAssetId && data?.assets?.trailer?.[0]?.assetStatus === 1) {
        trailerAssetUrl = data?.programDetail?.trailerAssetId;
      } else if (data?.assets?.trailer?.[0]?.assetStatus !== 1) {
        trailerAssetUrl = data?.assets?.trailer?.[0]?.assetUrl;
      }
      tmpData.trailer = {
        assetId: data?.programDetail?.trailerAssetId,
        ...data?.assets?.trailer[0],
        assetUrl: trailerAssetUrl || data?.assets?.trailer?.[0]?.id || null,
        isTrailer: data?.programDetail?.trailerAssetId || data?.assets?.trailer?.[0]?.id || null,
      };

      tmpData = {
        ...tmpData,
        thumbnail: data?.assets?.thumbnail || [],
        thumbnailWithoutText: data?.assets?.thumbnailWithOutText || [],
        transparentFiles: data?.assets?.transparentFiles || [],
        btsFiles: data?.assets?.btsFiles || [],
        videoSrtFiles: data?.assets?.videoSrtFiles || [],
        trailerSrtFiles: data?.assets?.trailerSrt || [],
        geoRestriction: tmpGeoData,
      };
      setAssetsData(tmpData);
    }
    setLoading(false);
    updateCopyEpisodeFlag();
    reset({}, { keepValues: true });
  };

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      getFilmmakerProgramById(params.id)
        .then((res) => {
          setupData(res);
        })
        .catch(({ response }) => {
          setLoading(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response?.data?.message,
            type: 'error',
            show: true,
          });
          setIsNotFound(true);
        });
    }
  }, [isEpisodeDeleted]);

  useEffect(() => {
    if (params.id && !showInDialog) {
      getFilmmakerProgramById(params.id).then((res) => {
        const { data } = res;
        if (data?.programDetail?.chapters) {
          const chapterData = data?.programDetail?.chapters || [];
          setEpisodeDetail(chapterData);
          const filterData = chapterData.filter((f) => f?.programDetail?.id === programChapterId);
          if (filterData && filterData.length > 0) {
            setValue('episodeTitle', filterData[0]?.programDetail.title || '');
          }
        }
      });
    }
  }, [showInDialog]);

  useEffect(() => {
    if (copyProgramDetails && params.id) {
      getFilmmakerProgramById(params.id).then((res) => {
        setupData(res);
      });
    }
  }, [copyProgramDetails]);

  const submitData = (data, fStatus, episodeData = false) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });
    let isProcess = true;
    let isEngSRT = true;
    if (episodeData) {
      const statusVal = validateFilmAssets(assetsData, true, episodeData, episodeStatus);
      if (!statusVal.isProcess && (statusVal.thumbnail || statusVal.film || statusVal.videoSrt)) {
        validateStatus.current.thumbnail = statusVal.thumbnail;
        validateStatus.current.film = statusVal.film;
        validateStatus.current.videoSrt = statusVal.videoSrt;
        validateStatus.current.message = statusVal?.message || '';

        isProcess = false;
      } else if (
        episodeStatus !== 1 &&
        !assetsData.videoSrtFiles?.some((item) => item.lanCode === 'en')
      ) {
        isEngSRT = false;
        isProcess = false;
      }
    }
    if (fStatus === 3 || fStatus === 5) {
      if (isSeries && !episodeData) {
        const statusVal = validateFilmAssets(assetsData, isSeries, episodeData);
        if (!statusVal.isProcess) {
          validateStatus.current = statusVal;
          isProcess = false;
        }
      } else if (!isSeries && !episodeData) {
        const statusVal = validateFilmAssets(assetsData, isSeries, episodeData, 0, filmStatus);
        if (!statusVal.isProcess) {
          validateStatus.current = statusVal;
          isProcess = false;
        } else if (
          filmStatus !== filmmkaerFilmsStatus.PUBLISHED &&
          !assetsData.videoSrtFiles?.some((item) => item.lanCode === 'en')
        ) {
          isEngSRT = false;
          isProcess = false;
        }
      }
    }

    if (isProcess) {
      setProcessing(true);

      const tempCast = [];
      const runTimeCast = getValues('cast');
      runTimeCast.forEach((d, i) => {
        tempCast.push({
          ...d,
          order: i + 1,
        });
      });
      const tempTags = [];
      data?.tags?.forEach((item) => {
        tempTags.push({
          id: item.id,
          name: item.customValue || item.name,
        });
      });

      const payload = {
        episodeData,
        programStatus: fStatus,
        isNewEpisode: episodeData && isNewEpisode.current ? 1 : 0,
        programDetails: {
          assetType: 1,
          status: 2,
          shortDescription: data?.shortDescription || '',
          studio: data?.studio || '',
          secondaryGenres: !episodeData ? data?.secondaryGenres || null : [],
          primayGenres: !episodeData ? data?.primayGenres || null : [],
          tags: !episodeData ? tempTags || null : [],
          ratingRemarks: data.ratingRemarks || '',
          rating: data?.rating || '',
          year: Number(data.year) || 0,
          productionCompanies: data?.productionCompanies || '',
          imdbId: data?.imdbId || '',
          description: data?.description || '',
          crew: data?.crew || [],
          cast: tempCast || [],
          countryOfOrigin: data?.countryOfOrigin?.length === 0 ? [] : data?.countryOfOrigin,
          copyright: data?.copyright || '',
          audioLanguage: data?.audioLanguage || '',
          filmingLocation: data?.filmingLocations || '',
          geoRestriction:
            !episodeData && assetsData?.geoRestriction ? assetsData.geoRestriction : null,
        },
        assetsData: [],
      };

      if (episodeData) {
        payload.programChapterId = isAddMode ? newEpisodeId?.current : programChapterId || 0;
        payload.programDetails.episodeTitle = data.episodeTitle || '';
        payload.programDetails.season = Number(data.season) || null;
      } else {
        payload.programDetails.type = data.type;
        payload.programDetails.title = data.title;
      }

      updateFilmmakerAssets(params.id, payload)
        .then((res) => {
          if (res) {
            if (res?.data?.submit) {
              if (episodeData) {
                closeAddViewEpisodeDialog();
              } else if (fStatus === 3 || fStatus === 5) {
                updatedfilmStatus.current = fStatus;
                if (res.data?.changeStatus) {
                  setOpenPublishDateModal(true);
                } else {
                  if (isShowMessage.current) {
                    setSnackbarMeesage({
                      ...snackbarMeesage,
                      message: 'Data saved successfully.',
                      type: 'success',
                      show: true,
                    });
                  } else {
                    isShowMessage.current = true;
                  }
                  const url = RoutePaths.FILMS_VIEW.replace(':id', params.id);
                  history.push(url);
                }
              } else {
                if (isShowMessage.current) {
                  setSnackbarMeesage({
                    ...snackbarMeesage,
                    message: 'Data saved successfully.',
                    type: 'success',
                    show: true,
                  });
                } else {
                  isShowMessage.current = true;
                }
                const url = RoutePaths.FILMS_VIEW.replace(':id', params.id);
                history.push(url);
              }
            } else {
              setSnackbarMeesage({
                ...snackbarMeesage,
                show: false,
              });
              setTimeout(() => {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  message: res?.data?.message,
                  type: 'error',
                  show: true,
                });
              }, 100);
            }
          }
        })
        .catch((e) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: e.response?.data?.message || 'Something went wrong. Please try again later.',
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
      setProcessing(false);
    } else {
      if (!isEngSRT) {
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: true,
          type: 'error',
          message: 'Please provide English SRT file.',
        });
        return;
      }
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: validateStatus.current?.message || 'Please provide required assets.',
        type: 'error',
        show: true,
      });
      setTimeout(() => {
        episodeError.current = null;
      }, 200);
    }
  };

  const handleSaveSubmitNow = (data) => {
    submitData(
      filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ? data : getValues(),
      filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD
        ? filmmkaerFilmsStatus.FOR_APPROVAL
        : filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
    );
  };

  const handleSaveFinishLater = () => {
    submitData(getValues(), 2);
  };

  const handleSaveEpisodeDetails = (data) => {
    submitData(data, 2, true);
  };

  const updateRuntimeData = (id = null, action, isNew = false) => {
    isShowMessage.current = false;
    if (isChange.current) {
      setLoading(true);
      isChange.current = false;
      handleSaveFinishLater();
      setTimeout(() => {
        if (action === 'v') {
          showViewEpisodeDialog(id, isNew);
        } else {
          showAddEpisodeDialog();
        }
        reset({}, { keepValues: true });
        setLoading(false);
      }, 900);
    } else if (action === 'v') {
      showViewEpisodeDialog(id, isNew);
    } else {
      showAddEpisodeDialog();
    }
  };

  const handleThumbnailSuccess = (v, type) => {
    if (type === filmmakerFilmsAssetsType.THUMBNAIL_WITH_TEXT) {
      validateStatus.current.thumbnail = !v?.some(
        (item) =>
          item.assetStatus === filmmakerAssetsStatus.APPROVED ||
          item.assetStatus === filmmakerAssetsStatus.PENDING
      );
      setAssetsData((prevAssetData) => ({ ...prevAssetData, thumbnail: v }));
      if (v?.length > 0) {
        setAssetsStatus({
          ...assetsStatus,
          thumbnail: v?.some(
            (item) =>
              item.assetStatus === filmmakerAssetsStatus.APPROVED ||
              item.assetStatus === filmmakerAssetsStatus.PENDING
          ),
        });
      }
    } else if (type === filmmakerFilmsAssetsType.THUMBNAIL_WITHOUT_TEXT) {
      validateStatus.current.thumbnailWithoutText = !v?.some(
        (item) =>
          item.assetStatus === filmmakerAssetsStatus.APPROVED ||
          item.assetStatus === filmmakerAssetsStatus.PENDING
      );
      setAssetsData((prevAssetData) => ({ ...prevAssetData, thumbnailWithoutText: v }));
      if (v?.length > 0) {
        setAssetsStatus({
          ...assetsStatus,
          thumbnailWithOutText: v?.some(
            (item) =>
              item.assetStatus === filmmakerAssetsStatus.APPROVED ||
              item.assetStatus === filmmakerAssetsStatus.PENDING
          ),
        });
      }
    } else if (type === filmmakerFilmsAssetsType.TRANSPARENT_LOGO) {
      setAssetsData((prevAssetData) => ({ ...prevAssetData, transparentFiles: v }));
      setAssetsStatus({
        ...assetsStatus,
        transparentFiles: v?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
      });
    } else if (type === filmmakerFilmsAssetsType.VIDEO_SRT) {
      validateStatus.current.videoSrt = !v?.some(
        (item) =>
          item.assetStatus === filmmakerAssetsStatus.APPROVED ||
          item.assetStatus === filmmakerAssetsStatus.PENDING
      );
      setAssetsData((prevAssetData) => ({ ...prevAssetData, videoSrtFiles: v }));
      setAssetsStatus({
        ...assetsStatus,
        videoSrt: v?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
      });
    } else if (type === filmmakerFilmsAssetsType.TRAILER_SRT) {
      setAssetsData((prevAssetData) => ({ ...prevAssetData, trailerSrtFiles: v }));
      setAssetsStatus({
        ...assetsStatus,
        trailerSrt: v?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
      });
    } else if (type === filmmakerFilmsAssetsType.BTS_VIDEO) {
      setAssetsData((prevAssetData) => ({ ...prevAssetData, btsFiles: v }));
      setAssetsStatus({
        ...assetsStatus,
        btsVideos: v?.some(
          (item) =>
            item.assetStatus === filmmakerAssetsStatus.APPROVED ||
            item.assetStatus === filmmakerAssetsStatus.PENDING
        ),
      });
    }
  };

  const handleGeoRestrictionSuccess = (v) => {
    setAssetsData({ ...assetsData, geoRestriction: [v] });
  };

  /* sorting cast logic start */

  const onSortEnd = ({ oldIndex, newIndex }) => {
    castMove(oldIndex, newIndex);
  };

  const checkRenderCondition =
    filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
    (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
      showInDialog &&
      (!programChapterId ||
        programChapterId < 0 ||
        (programChapterId > 0 && isNewEpisode.current === 1)));

  const SortableItem = SortableElement(({ idx }) => (
    <div key={idx + 1} style={{ marginTop: '5px' }}>
      <Grid container item xs={12} spacing={2} style={{ marginTop: idx === 0 ? '5px' : '' }}>
        {checkRenderCondition && (
          <Grid item xs={1}>
            <div className={classes.sortTableCell} style={{ cursor: 'n-resize' }}>
              <DragHandle />
            </div>
          </Grid>
        )}
        <Grid item xs={checkRenderCondition ? 5 : 6}>
          <Controller
            control={control}
            id={`cast[${idx}].title`}
            name={`cast[${idx}].title`}
            defaultValue=""
            rules={
              (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                isAddMode ||
                programChapterId < 0) && {
                ...Validations.REQUIRED,
                ...MaxLengthValidationFunc(100),
              }
            }
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Name"
                className={classes.formInput}
                variant="outlined"
                onChange={(e) => {
                  isChange.current = true;
                  onChange(e);
                }}
                value={value}
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
                disabled={
                  filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                  (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                    ((!showInDialog && !programChapterId) ||
                      (programChapterId > 0 && isNewEpisode.current === 0))) ||
                  filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            control={control}
            id={`cast[${idx}].role`}
            name={`cast[${idx}].role`}
            defaultValue=""
            rules={
              (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                isAddMode ||
                programChapterId < 0) && {
                ...Validations.REQUIRED,
                ...Validations.REQUIRED,
              }
            }
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Role"
                className={classes.formInput}
                variant="outlined"
                onChange={onChange}
                value={value}
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
                disabled={
                  filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                  (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                    ((!showInDialog && !programChapterId) ||
                      (programChapterId > 0 && isNewEpisode.current === 0))) ||
                  filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                }
              />
            )}
          />
        </Grid>
        <Controller
          control={control}
          id={`cast[${idx}].order`}
          name={`cast[${idx}].order`}
          defaultValue={idx + 1}
          render={({ field: { onChange, value } }) => (
            <TextField
              style={{ display: 'none' }}
              label="Order"
              className={classes.formInput}
              variant="outlined"
              onChange={onChange}
              value={value}
            />
          )}
        />

        {checkRenderCondition && idx !== 0 && (
          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
            <DeleteIcon
              color="primary"
              disabled={
                filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                  showInDialog &&
                  (!programChapterId ||
                    programChapterId < 0 ||
                    (programChapterId > 0 && isNewEpisode.current === 1))) ||
                filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                isChange.current = true;
                castRemove(idx);
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  ));

  const SortableRowList = SortableContainer(({ rows }) => (
    <div>
      {rows.map((row, index) => (
        <SortableItem key={`item-${row.id}`} index={index} sortIndex={index} idx={index} />
      ))}
    </div>
  ));
  /* sorting cast logic end */
  return (
    <>
      {loading && !isNotFound && <AppLoader />}
      {!loading && isNotFound && (
        <Box
          component={Paper}
          elevation={0}
          minHeight="85vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Typography className={classes.noRecordFoundText}>No data found</Typography>
        </Box>
      )}

      {!loading && !isNotFound && (
        <Grid container spacing={3} style={{ marginBottom: 20, marginTop: 10 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              border: !assetsStatus.programDetails ? '2px solid red' : '',
            }}
          >
            <form>
              {!showInDialog && (
                <>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        id="title"
                        name="title"
                        defaultValue=""
                        rules={
                          (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                            isAddMode ||
                            programChapterId < 0 ||
                            isNewEpisode.current) && {
                            ...Validations.REQUIRED,
                            ...MaxLengthValidationFunc(200),
                          }
                        }
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Title"
                            className={classes.formInput}
                            variant="outlined"
                            onChange={(e) => {
                              isChange.current = true;
                              onChange(e);
                            }}
                            value={value}
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled={
                              filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                              (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                ((!showInDialog && !programChapterId) ||
                                  (programChapterId > 0 && isNewEpisode.current === 0))) ||
                              filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                            }
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="type"
                        id="type"
                        defaultValue="SHORT_FILM"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            variant="outlined"
                            label="Type"
                            SelectProps={{
                              native: true,
                            }}
                            fullWidth
                            select
                            className={classes.formInput}
                            defaultValue={value}
                            value={value}
                            onChange={(e) => {
                              isChange.current = true;
                              onChange(e);
                            }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled
                          >
                            {Object.keys(VideoTypes).map((k) => (
                              <option key={k} value={k}>
                                {VideoTypes[k]}
                              </option>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {showInDialog && (
                <>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        id="episodeTitle"
                        name="episodeTitle"
                        defaultValue=""
                        rules={{
                          ...Validations.REQUIRED,
                          ...MaxLengthValidationFunc(200),
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Episode Title"
                            className={classes.formInput}
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            error={!!error}
                            helperText={error ? error.message : null}
                            fullWidth
                            disabled={
                              filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                              (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                ((!showInDialog && !programChapterId) ||
                                  (programChapterId > 0 && isNewEpisode.current === 0))) ||
                              filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        id="season"
                        name="season"
                        defaultValue={defaultFormValues.season || 1}
                        rules={
                          (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                            isAddMode ||
                            programChapterId < 0 ||
                            isNewEpisode.current) && {
                            ...Validations.REQUIRED,
                          }
                        }
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            type="number"
                            label="Season"
                            className={classes.formInput}
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            error={!!error}
                            helperText={error ? error.message : null}
                            fullWidth
                            disabled={
                              filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                              (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                ((!showInDialog && !programChapterId) ||
                                  (programChapterId > 0 && isNewEpisode.current === 0))) ||
                              filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: '15px', marginBottom: '15px', fontSize: '16px' }}
              >
                <Grid item xs={12}>
                  <Box>
                    {`Please complete the details of your ${
                      isSeries ? 'series' : 'film'
                    } to proceed.`}
                  </Box>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="description"
                    name="description"
                    defaultValue=""
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) && { ...Validations.REQUIRED }
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        multiline
                        label="Full Description"
                        rows={5}
                        className={classes.formInput}
                        variant="outlined"
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        value={value}
                        error={!!error}
                        helperText={error ? error.message : null}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                        fullWidth
                      />
                    )}
                  />
                  <Box mt={1} mb={3}>
                    <div style={{ color: 'grey' }}>
                      This complete description will appear on your video page. Use it to explain to
                      the viewer what to expect from your video.
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="shortDescription"
                    name="shortDescription"
                    defaultValue=""
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) && {
                        ...Validations.REQUIRED,
                        ...MaxLengthValidationFunc(255),
                      }
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Short Description"
                        className={classes.formInput}
                        variant="outlined"
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        value={value}
                        error={!!error}
                        helperText={error ? error.message : null}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                        fullWidth
                      />
                    )}
                  />
                  <Box mt={1} mb={3}>
                    <div style={{ color: 'grey' }}>
                      This shorter description will appear on collections and when users hover over
                      your program&apos;s thumbnail
                    </div>
                  </Box>
                </Grid>
              </Grid>

              {!showInDialog && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="primayGenres"
                      id="primayGenres"
                      defaultValue={[]}
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...Validations.REQUIRED,
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SearchableAutocomplete
                          label="Primary Genres"
                          value={value}
                          loadOptions={loadPrimaryGenresOptions}
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) || programChapterId > 0)) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="secondaryGenres"
                      id="secondaryGenres"
                      defaultValue={[]}
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...Validations.REQUIRED,
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SearchableAutocomplete
                          label="Secondary Genres"
                          value={value}
                          loadOptions={loadSecondaryGenresOptions}
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) || programChapterId > 0)) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}

              {!showInDialog && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="tags"
                      id="tags"
                      defaultValue={[]}
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          programChapterId > 0) && {
                          ...Validations.REQUIRED,
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SearchableAutocomplete
                          label="Tags"
                          value={value}
                          loadOptions={loadTagsOptions}
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          error={!!error}
                          allowCustomValue
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) || programChapterId > 0)) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
                            filmStatus === filmmkaerFilmsStatus.DEACTIVATED ||
                            filmStatus === filmmkaerFilmsStatus.REJECTED
                          }
                          helperText={error ? error.message : null}
                        />
                      )}
                    />

                    <Box mt={1} mb={3} display="flex">
                      <div style={{ color: 'grey', marginBottom: 5, marginRight: 5 }}>
                        Descriptive keywords that will allow your film to be classified into our
                        curated collections and campaigns
                      </div>
                      <Tooltip
                        title={
                          <h3>{`Effective tags describe the film's themes (unrequited love, christmas, lgbt, etc), notable aspects of the crew (woman filmmaker, Asian actor, one man crew, etc), or trivia about the film itself (award winning, based on real events, shot on iphone, etc). Add as few or as many tags as you want, but make sure they all truthfully describe your film`}</h3>
                        }
                        arrow
                      >
                        <InfoIcon color="action" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    id="year"
                    name="year"
                    defaultValue={defaultFormValues.year}
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) && {
                        ...Validations.REQUIRED,
                        ...ExactLengthValidationFunc(4),
                      }
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Production Year (YYYY)"
                        type="number"
                        className={classes.formInput}
                        variant="outlined"
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        value={value || ''}
                        error={!!error}
                        helperText={error ? error.message : null}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                        fullWidth
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value, 10))
                            .toString()
                            .slice(0, 4);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="rating"
                      id="rating"
                      defaultValue=""
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && { ...Validations.SELECT }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          variant="outlined"
                          label="Rating"
                          defaultValue={value}
                          value={value}
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          className={classes.formInput}
                          SelectProps={{
                            native: true,
                          }}
                          fullWidth
                          select
                        >
                          <option key={99} value=" " disabled>
                            Select
                          </option>
                          <option key={0} value="G">
                            G
                          </option>
                          <option key={1} value="PG">
                            PG
                          </option>
                          <option key={2} value="PG-13">
                            PG-13
                          </option>
                          <option key={3} value="R">
                            R
                          </option>
                          <option key={4} value="NC-17">
                            NC-17
                          </option>
                          <option key={5} value="TV-Y">
                            TV-Y
                          </option>
                          <option key={6} value="TV-Y7">
                            TV-Y7
                          </option>
                          <option key={7} value="TV-Y7 FV">
                            TV-Y7 FV
                          </option>
                          <option key={8} value="TV-G">
                            TV-G
                          </option>
                          <option key={9} value="TV-PG">
                            TV-PG
                          </option>
                          <option key={10} value="TV-14">
                            TV-14
                          </option>
                          <option key={11} value="TV-MA">
                            TV-MA
                          </option>
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      id="ratingRemarks"
                      name="ratingRemarks"
                      defaultValue=""
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...MaxLengthValidationFunc(80),
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Rating Remarks"
                          className={classes.formInput}
                          variant="outlined"
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          value={value}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      id="productionCompanies"
                      name="productionCompanies"
                      defaultValue=""
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...Validations.REQUIRED,
                          ...MaxLengthValidationFunc(40),
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Production Company"
                          className={classes.formInput}
                          variant="outlined"
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          value={value}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      id="copyright"
                      name="copyright"
                      defaultValue={new Date().getFullYear()}
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...Validations.REQUIRED,
                          ...MaxLengthValidationFunc(255),
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Copyright"
                          className={classes.formInput}
                          variant="outlined"
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          value={value}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          fullWidth
                        />
                      )}
                    />
                    <Box mt={1} mb={1}>
                      <div style={{ color: 'grey' }}>
                        {`Year and copyright owner, e.g. ${new Date().getFullYear()} Reveel Entertainment`}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      id="studio"
                      name="studio"
                      defaultValue=""
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0 ||
                          isNewEpisode.current) && {
                          ...MaxLengthValidationFunc(50),
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Studio"
                          className={classes.formInput}
                          variant="outlined"
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          value={value}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      id="imdbId"
                      name="imdbId"
                      defaultValue=""
                      rules={
                        (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                          isAddMode ||
                          programChapterId < 0) && {
                          ...MaxLengthValidationFunc(255),
                          ...Validations.OPTIONAL_URL,
                        }
                      }
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="IMDB Film URL"
                          className={classes.formInput}
                          variant="outlined"
                          onChange={(e) => {
                            isChange.current = true;
                            onChange(e);
                          }}
                          value={value}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                              ((!showInDialog && !programChapterId) ||
                                (programChapterId > 0 && isNewEpisode.current === 0))) ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                          fullWidth
                        />
                      )}
                    />
                    <Box mt={1} mb={2}>
                      <div style={{ color: 'grey' }}>www.imdb.com/title/xxxxxxxx</div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="countryOfOrigin"
                    id="countryOfOrigin"
                    defaultValue={null}
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) && {
                        ...Validations.REQUIRED,
                      }
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <SearchableAutocomplete
                        label="Countries"
                        value={value}
                        loadOptions={loadCountriesOptions}
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="filmingLocations"
                    id="filmingLocations"
                    defaultValue=""
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) &&
                      Validations.REQUIRED
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Filming Locations"
                        className={classes.formInput}
                        variant="outlined"
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        value={value}
                        error={!!error}
                        helperText={error ? error.message : null}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                        fullWidth
                      />
                    )}
                  />
                  <Box mt={1} mb={2}>
                    <div style={{ color: 'grey' }}>Cities</div>
                  </Box>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="audioLanguage"
                    id="audioLanguage"
                    defaultValue={[]}
                    rules={
                      (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        isAddMode ||
                        programChapterId < 0 ||
                        isNewEpisode.current) &&
                      Validations.REQUIRED
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <SearchableAutocomplete
                        label="Audio Language"
                        value={value}
                        loadOptions={loadAudioLanguageOptions}
                        onChange={(e) => {
                          isChange.current = true;
                          onChange(e);
                        }}
                        error={!!error}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                            ((!showInDialog && !programChapterId) ||
                              (programChapterId > 0 && isNewEpisode.current === 0))) ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE ||
                          filmStatus === filmmkaerFilmsStatus.DEACTIVATED ||
                          filmStatus === filmmkaerFilmsStatus.REJECTED
                        }
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.formMediaSection}>
              {(!isSeries || showInDialog) && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      {`${showInDialog ? 'Upload Episode' : 'Upload Film'}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      .mp4, 4K or smaller, H.264 video codec, 20-60 fps
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    <UploadAssets
                      name="video"
                      caption={`${showInDialog ? 'Episode' : 'Film'}`}
                      programId={reveelProgramId}
                      chapterId={isAddMode ? newEpisodeId.current : reveelProgramChapterId}
                      trailer={0}
                      value={
                        !isAddMode || assetsData.film?.assetUrl
                          ? assetsData.film?.assetUrl ||
                            assetsData.film?.id ||
                            assetsData.film?.assetChapterId ||
                            assetsData.film?.programDetail?.id
                          : ''
                      }
                      onUploadComplete={(v) => onFilmUploadComplete(v)}
                      saveVideoUrl={false}
                      assetId={assetsData?.film?.id}
                      filmStatus={filmStatus}
                      assetType={2}
                      isSeries={isSeries}
                      filmmakerProgramId={params.id}
                      isError={validateStatus.current.film}
                      assetStatus={assetsData?.film?.assetStatus}
                      rejectionNote={
                        !assetsData?.film?.assetStatus && assetsData?.film?.assetRejectionNotes
                          ? assetsData?.film?.assetRejectionNotes
                          : ''
                      }
                    />
                  </div>
                </div>
              )}

              {!showInDialog && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Upload Trailer
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      .mp4, 4K or smaller, H.264 video codec, 20-60 fps
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    <UploadAssets
                      name="trailer"
                      caption="Trailer"
                      programId={reveelProgramId}
                      chapterId={
                        isAddMode
                          ? newEpisodeId.current
                          : !showInDialog
                          ? 0
                          : reveelProgramChapterId
                      }
                      trailer={1}
                      value={
                        assetsData.trailer.assetUrl ||
                        assetsData.trailer.assetId ||
                        assetsData.trailer.url
                      }
                      onUploadComplete={(v) => onTrailerUploadComplete(v)}
                      assetType={3}
                      saveVideoUrl={false}
                      filmStatus={filmStatus}
                      assetId={assetsData?.trailer?.id}
                      isSeries={isSeries}
                      filmmakerProgramId={params.id}
                      isError={validateStatus.current.trailer}
                      assetStatus={assetsData?.trailer?.assetStatus}
                      rejectionNote={
                        !assetsData?.trailer?.assetStatus &&
                        assetsData?.trailer?.assetRejectionNotes
                          ? assetsData?.trailer?.assetRejectionNotes
                          : ''
                      }
                      removeTrailer={removeTrailer}
                    />
                  </div>
                </div>
              )}

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                }}
              >
                <div className={classes.form50Section1}>
                  <Typography variant="h6" component="h6">
                    Upload Thumbnails
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    1920x1080px with film title graphic. No other text/laurels/etc. This is the main
                    image viewers see when scrolling.
                  </Typography>
                </div>
                <div className={classes.form50Section2}>
                  <label htmlFor="upload-vid-button">
                    {assetsData?.thumbnail?.filter(
                      (item) => item.assetStatus !== filmmakerAssetsStatus.DELETED
                    ).length <= 0 ? (
                      <Button
                        fullWidth
                        onClick={() =>
                          handleUploadThumbnail(filmmakerFilmsAssetsType.THUMBNAIL_WITH_TEXT)
                        }
                        variant="outlined"
                        color={
                          isError || validateStatus.current.thumbnail || !assetsStatus.thumbnail
                            ? 'primary'
                            : ''
                        }
                        startIcon={<CloudUpload />}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                      >
                        Upload Thumbnails
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        fullWidth
                        color={isError ? 'primary' : ''}
                        onClick={() =>
                          handleUploadThumbnail(filmmakerFilmsAssetsType.THUMBNAIL_WITH_TEXT)
                        }
                        style={{
                          marginBottom: 20,
                          color:
                            validateStatus.current.thumbnail || !assetsStatus.thumbnail
                              ? '#f44336'
                              : 'green',
                          borderColor:
                            validateStatus.current.thumbnail || !assetsStatus.thumbnail
                              ? '#f44336'
                              : 'green',
                        }}
                      >
                        Manage Thumbnails
                      </Button>
                    )}
                    {(validateStatus.current.thumbnail || !assetsStatus.thumbnail) && (
                      <Box
                        mt={-1.5}
                        style={{
                          color:
                            validateStatus.current.thumbnail || !assetsStatus.thumbnail
                              ? '#f44336'
                              : 'green',
                          borderColor:
                            validateStatus.current.thumbnail || !assetsStatus.thumbnail
                              ? '#f44336'
                              : 'green',
                        }}
                      >
                        {assetsData?.thumbnail?.[0]?.assetRejectionNotes}
                      </Box>
                    )}
                  </label>
                </div>
              </div>

              {!showInDialog && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Upload Thumbnails Without Text
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Other HD stills without text. These will be used for specific screens in the
                      apps. No logos, text, or graphics.
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    <label htmlFor="upload-vid-button">
                      {assetsData?.thumbnailWithoutText?.filter(
                        (item) => item.assetStatus !== filmmakerAssetsStatus.DELETED
                      ).length <= 0 ? (
                        <Button
                          fullWidth
                          onClick={() =>
                            handleUploadThumbnail(filmmakerFilmsAssetsType.THUMBNAIL_WITHOUT_TEXT)
                          }
                          variant="outlined"
                          color={
                            isError ||
                            validateStatus.current.thumbnailWithoutText ||
                            !assetsStatus.thumbnailWithOutText
                              ? 'primary'
                              : ''
                          }
                          startIcon={<CloudUpload />}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                        >
                          Upload Thumbnails Without Text
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          fullWidth
                          color={isError || assetsStatus.thumbnailWithOutText ? 'primary' : ''}
                          onClick={() =>
                            handleUploadThumbnail(filmmakerFilmsAssetsType.THUMBNAIL_WITHOUT_TEXT)
                          }
                          style={{
                            marginBottom: 20,
                            color:
                              validateStatus.current.thumbnailWithoutText ||
                              !assetsStatus.thumbnailWithOutText
                                ? '#f44336'
                                : 'green',
                            borderColor:
                              validateStatus.current.thumbnailWithoutText ||
                              !assetsStatus.thumbnailWithOutText
                                ? '#f44336'
                                : 'green',
                          }}
                        >
                          Manage Thumbnails Without Text
                        </Button>
                      )}
                      {(validateStatus.current.thumbnailWithoutText ||
                        !assetsStatus.thumbnailWithOutText) && (
                        <Box
                          mt={-1.5}
                          style={{
                            color:
                              validateStatus.current.thumbnailWithoutText ||
                              !assetsStatus.thumbnailWithOutText
                                ? '#f44336'
                                : 'green',
                            borderColor:
                              validateStatus.current.thumbnailWithoutText ||
                              !assetsStatus.thumbnailWithOutText
                                ? '#f44336'
                                : 'green',
                          }}
                        >
                          {assetsData?.thumbnailWithoutText?.[0]?.assetRejectionNotes}
                        </Box>
                      )}
                    </label>
                  </div>
                </div>
              )}

              {!isSeries && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Upload Transparent Title Graphic
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Optional image file of the title with transparent background.
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    <label htmlFor="upload-vid-button">
                      {assetsData?.transparentFiles?.filter(
                        (item) =>
                          item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                          item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                      ).length <= 0 ? (
                        <Button
                          fullWidth
                          onClick={() =>
                            handleUploadThumbnail(filmmakerFilmsAssetsType.TRANSPARENT_LOGO)
                          }
                          variant="outlined"
                          startIcon={<CloudUpload />}
                          disabled={
                            filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                            filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                          }
                        >
                          Upload Transparent Title Graphic
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() =>
                            handleUploadThumbnail(filmmakerFilmsAssetsType.TRANSPARENT_LOGO)
                          }
                          style={{
                            marginBottom: 20,
                            color: !assetsStatus.transparentFiles ? '#f44336' : 'green',
                            borderColor: !assetsStatus.transparentFiles ? '#f44336' : 'green',
                          }}
                        >
                          Manage Transparent Title Graphic
                        </Button>
                      )}
                      {!assetsStatus.transparentFiles && (
                        <Box
                          mt={-1.5}
                          style={{
                            color: !assetsStatus.transparentFiles ? '#f44336' : 'green',
                            borderColor: !assetsStatus.transparentFiles ? '#f44336' : 'green',
                          }}
                        >
                          {assetsData?.transparentFiles?.[0]?.assetRejectionNotes}
                        </Box>
                      )}
                    </label>
                  </div>
                </div>
              )}

              {(!isSeries || showInDialog) && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      {`${showInDialog ? 'Upload SRT File' : 'Upload Film SRT File'}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Required to upload English subtitles. Other languages optional.
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    {assetsData?.videoSrtFiles?.filter(
                      (item) =>
                        item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                        item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                    ).length <= 0 ? (
                      <Button
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.VIDEO_SRT)}
                        variant="outlined"
                        startIcon={<CloudUpload />}
                        color={
                          isError || validateStatus.current.videoSrt || !assetsStatus.videoSrt
                            ? 'primary'
                            : ''
                        }
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                      >
                        {`${showInDialog ? 'Upload SRT File' : 'Upload Film SRT File'}`}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.VIDEO_SRT)}
                        style={{
                          marginBottom: 20,
                          color: !assetsStatus.videoSrt ? '#f44336' : 'green',
                          borderColor: !assetsStatus.videoSrt ? '#f44336' : 'green',
                        }}
                      >
                        {`${showInDialog ? 'Manage Subtitles' : 'Manage Film Subtitles'}`}
                      </Button>
                    )}
                    {!assetsStatus.videoSrt && (
                      <Box
                        mt={-1.5}
                        style={{
                          color: !assetsStatus.videoSrt ? '#f44336' : 'green',
                          borderColor: !assetsStatus.videoSrt ? '#f44336' : 'green',
                        }}
                      >
                        {assetsData?.videoSrtFiles?.[0]?.assetRejectionNotes}
                      </Box>
                    )}
                  </div>
                </div>
              )}

              {!isSeries && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Upload Trailer SRT File
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Required to upload English subtitles. Other languages optional.
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    {assetsData?.trailerSrtFiles?.filter(
                      (item) =>
                        item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                        item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                    ).length <= 0 ? (
                      <Button
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.TRAILER_SRT)}
                        variant="outlined"
                        startIcon={<CloudUpload />}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                      >
                        Upload Trailer SRT File
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.TRAILER_SRT)}
                        style={{
                          marginBottom: 20,
                          color: !assetsStatus.trailerSrt ? '#f44336' : 'green',
                          borderColor: !assetsStatus.trailerSrt ? '#f44336' : 'green',
                        }}
                      >
                        Manage Trailer Subtitles
                      </Button>
                    )}
                    {!assetsStatus.trailerSrt && (
                      <Box
                        mt={-1.5}
                        style={{
                          color: !assetsStatus.trailerSrt ? '#f44336' : 'green',
                          borderColor: !assetsStatus.trailerSrt ? '#f44336' : 'green',
                        }}
                      >
                        {assetsData?.trailerSrtFiles?.[0]?.assetRejectionNotes}
                      </Box>
                    )}
                  </div>
                </div>
              )}

              {(!isSeries || showInDialog) && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Upload BTS and Stills
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Optional behind the scenes videos and images for possible promotions
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    {assetsData?.btsFiles?.filter(
                      (item) =>
                        item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                        item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                    ).length <= 0 ? (
                      <Button
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.BTS_VIDEO)}
                        variant="outlined"
                        startIcon={<CloudUpload />}
                        disabled={
                          filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                          filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                        }
                      >
                        Upload BTS and Stills
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleUploadThumbnail(filmmakerFilmsAssetsType.BTS_VIDEO)}
                        style={{
                          marginBottom: 20,
                          color: !assetsStatus.btsVideos ? '#f44336' : 'green',
                          borderColor: !assetsStatus.btsVideos ? '#f44336' : 'green',
                        }}
                      >
                        Manage BTS and Stills
                      </Button>
                    )}
                    {!assetsStatus.btsVideos && (
                      <Box
                        mt={-1.5}
                        style={{
                          color: !assetsStatus.btsVideos ? '#f44336' : 'green',
                          borderColor: !assetsStatus.btsVideos ? '#f44336' : 'green',
                        }}
                      >
                        {assetsData?.btsFiles?.[0]?.assetRejectionNotes}
                      </Box>
                    )}
                  </div>
                </div>
              )}

              {!showInDialog && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}
                >
                  <div className={classes.form50Section1}>
                    <Typography variant="h6" component="h6">
                      Geo Restrictions
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Are there any countries or territories you&apos;re contractually obligated to
                      restrict streaming on this platform? If yes, add the details here
                    </Typography>
                  </div>
                  <div className={classes.form50Section2}>
                    <Button
                      fullWidth
                      onClick={() => {
                        window.FreshworksWidget('hide');
                        setOpenGeoRestricationDialog(true);
                      }}
                      variant="outlined"
                      style={
                        (assetsData.geoRestriction[0] &&
                          assetsData.geoRestriction[0]?.countries?.length > 0 && {
                            marginBottom: 20,
                            color: 'green',
                            borderColor: 'green',
                          }) ||
                        (assetsData.geoRestriction?.countries &&
                          assetsData.geoRestriction.countries.length > 0 && {
                            marginBottom: 20,
                            color: 'green',
                            borderColor: 'green',
                          })
                      }
                    >
                      Geo Restrictions
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <Box mt={3} mb={5}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: '15px', marginBottom: '15px', fontSize: '16px' }}
              >
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormLabel component="legend">Crew</FormLabel>
                </Grid>
                {((filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                  showInDialog &&
                  (!programChapterId ||
                    programChapterId < 0 ||
                    (programChapterId > 0 && isNewEpisode.current === 1))) ||
                  filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD) && (
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      color="primary"
                      onClick={() => {
                        crewAppend();
                      }}
                      disableElevation
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Box maxHeight="400px" minHeight="100px" overflow="auto">
                {crewFields.map((field, index) => (
                  <div key={field.id} className="my-2 px-1" style={{ marginTop: '5px' }}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          id={`crew[${index}].title`}
                          name={`crew[${index}].title`}
                          defaultValue=""
                          rules={
                            (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                              isAddMode ||
                              programChapterId < 0) && {
                              ...Validations.REQUIRED,
                              ...MaxLengthValidationFunc(100),
                            }
                          }
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Name"
                              className={classes.formInput}
                              variant="outlined"
                              onChange={(e) => {
                                isChange.current = true;
                                onChange(e);
                              }}
                              value={value}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                              disabled={
                                filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                                (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                  ((!showInDialog && !programChapterId) ||
                                    (programChapterId > 0 && isNewEpisode.current === 0))) ||
                                filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          control={control}
                          id={`crew[${index}].role`}
                          name={`crew[${index}].role`}
                          defaultValue="director"
                          rules={
                            (filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                              isAddMode ||
                              programChapterId < 0) && {
                              ...Validations.SELECT,
                              ...Validations.SELECT,
                            }
                          }
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              variant="outlined"
                              label="Role"
                              defaultValue={value}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                              disabled={
                                index === 0 ||
                                filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                                (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                  ((!showInDialog && !programChapterId) ||
                                    (programChapterId > 0 && isNewEpisode.current === 0))) ||
                                filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                              }
                              className={classes.formInput}
                              SelectProps={{
                                native: true,
                              }}
                              fullWidth
                              select
                            >
                              <option key={99} value=" " disabled>
                                Select
                              </option>
                              <option key={0} value="director">
                                Director
                              </option>
                              <option key={1} value="producer">
                                Producer
                              </option>
                              <option key={2} value="writer">
                                Writer
                              </option>
                              <option key={3} value="cinematographer">
                                Cinematographer
                              </option>
                            </TextField>
                          )}
                        />
                      </Grid>
                      {(filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD ||
                        (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                          showInDialog &&
                          (!programChapterId ||
                            programChapterId < 0 ||
                            (programChapterId > 0 && isNewEpisode.current === 1)))) &&
                        index !== 0 && (
                          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <DeleteIcon
                              color="primary"
                              disabled={
                                filmStatus === filmmkaerFilmsStatus.FOR_APPROVAL ||
                                (filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                                  showInDialog &&
                                  (!programChapterId ||
                                    programChapterId < 0 ||
                                    (programChapterId > 0 && isNewEpisode.current === 1))) ||
                                filmStatus === filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE
                              }
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                isChange.current = true;
                                crewRemove(index);
                              }}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </div>
                ))}
              </Box>
            </Box>

            <Box mt={3} mb={5}>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: '15px', marginBottom: '15px', fontSize: '16px' }}
              >
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormLabel component="legend">Cast</FormLabel>
                </Grid>
                {((filmStatus === filmmkaerFilmsStatus.PUBLISHED &&
                  showInDialog &&
                  (!programChapterId ||
                    programChapterId < 0 ||
                    (programChapterId > 0 && isNewEpisode.current === 1))) ||
                  filmStatus === filmmkaerFilmsStatus.ASSET_UPLOAD) && (
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      color="primary"
                      onClick={() => {
                        isChange.current = true;
                        castAppend();
                      }}
                      disableElevation
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Box maxHeight="400px" minHeight="100px" overflow="auto">
                <SortableRowList
                  updateBeforeSortStart={(node) => {
                    // eslint-disable-next-line no-param-reassign
                    node.node.style.zIndex = 9999;
                  }}
                  useDragHandle
                  rows={castFields}
                  lockAxis="y"
                  onSortEnd={onSortEnd}
                />
              </Box>
            </Box>

            {!showInDialog && isSeries && (
              <EpisodeList
                chapters={episodeDetail}
                // showAddEpisodeDialog={showAddEpisodeDialog}
                showAddEpisodeDialog={() => updateRuntimeData(null, 'n')}
                showViewEpisodeDialog={(id, isNew) => updateRuntimeData(id, 'v', isNew)}
                filmStatus={filmStatus}
                filmmakerProgramId={params.id}
                onSuccess={() => setIsEpisodeDeleted(!isEpisodeDeleted)}
              />
            )}
          </Grid>

          <Grid container item xs={12} spacing={2}>
            {!showInDialog && (
              <Box display="flex" justifyContent="center" paddingTop="30px" width="100%">
                {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                  filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        endIcon={processing && <CircularProgress size={25} />}
                        disabled={processing}
                        style={{ marginRight: 10 }}
                        onClick={handleSubmit(handleSaveSubmitNow)}
                      >
                        Save and Submit Now
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="button"
                        disabled={processing}
                        onClick={() => handleSaveFinishLater()}
                        style={{ marginRight: 10 }}
                      >
                        Save and Finish Later
                      </Button>
                    </>
                  )}

                <Button
                  variant="contained"
                  disableElevation
                  onClick={showInDialog ? closeAddViewEpisodeDialog : handleBack}
                >
                  Back To List
                </Button>
              </Box>
            )}

            {showInDialog && (
              <Grid item xs={6}>
                {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                  filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                      id="program-submit"
                      style={{ marginRight: 10 }}
                      onClick={handleSubmit(handleSaveEpisodeDetails)}
                    >
                      Save and Continue
                    </Button>
                  )}

                <Button
                  variant="contained"
                  disableElevation
                  onClick={showInDialog ? closeAddViewEpisodeDialog : handleBack}
                >
                  Close
                </Button>
              </Grid>
            )}
          </Grid>
          {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
          {isShowUploadModel && (
            <UploadMultipleAssets
              data={selectedAssetData.data || []}
              title={selectedAssetData.title || ''}
              onSuccess={(v, type) => handleThumbnailSuccess(v, type)}
              closeModal={() => {
                window.FreshworksWidget('show');
                setIsShowUploadModel(false);
              }}
              assetType={assetType}
              filmStatus={filmStatus}
              programId={reveelProgramId}
              chapterId={
                isAddMode ? newEpisodeId.current : !showInDialog ? 0 : reveelProgramChapterId
              }
              isSeries={isSeries}
              filmId={params.id}
            />
          )}
          {isShowSRTModel && (
            <ManageSubtitles
              closeModal={() => {
                window.FreshworksWidget('show');
                setIsShowSRTModel(false);
              }}
              onSuccess={(v, type) => handleThumbnailSuccess(v, type)}
              title={selectedAssetData.title || ''}
              data={
                selectedAssetData.data.filter(
                  (item) =>
                    item.assetStatus !== filmmakerAssetsStatus.DELETED &&
                    item.assetStatus !== filmmakerAssetsStatus.REQUESTED_DELETION
                ) || []
              }
              assetType={assetType}
              filmStatus={filmStatus}
              chapterId={
                isAddMode ? newEpisodeId.current : !showInDialog ? 0 : reveelProgramChapterId
              }
              programId={reveelProgramId}
              isSeries={isSeries}
              filmmakerFilmId={params.id}
            />
          )}
          {openGeoRestricationDialog && (
            <GeoRestrictionModal
              geoData={assetsData.geoRestriction}
              onSuccess={handleGeoRestrictionSuccess}
              closeModal={() => {
                window.FreshworksWidget('show');
                setOpenGeoRestricationDialog(false);
              }}
              filmStatus={filmStatus}
            />
          )}
          {openPublishDateModal && (
            <ScheduleFilmModal
              closeModal={() => setOpenPublishDateModal(false)}
              filmDetails={{
                filmmakerFilmId: params.id,
                programStatus: updatedfilmStatus.current,
                programId: reveelProgramId,
                type: isSeries ? 'SERIES' : 'FILM',
              }}
            />
          )}
        </Grid>
      )}
    </>
  );
};

FilmsUploadAssetsForm.propTypes = {
  showInDialog: PropTypes.bool.isRequired,
  showAddEpisodeDialog: PropTypes.func,
  showViewEpisodeDialog: PropTypes.func,
  closeAddViewEpisodeDialog: PropTypes.func,
  copyProgramDetails: PropTypes.bool.isRequired,
  programChapterId: PropTypes.number,
  programData: PropTypes.objectOf,
  isAddMode: PropTypes.bool,
  updateCopyEpisodeFlag: PropTypes.func.isRequired,
};
FilmsUploadAssetsForm.defaultProps = {
  showAddEpisodeDialog: () => {},
  showViewEpisodeDialog: () => {},
  closeAddViewEpisodeDialog: () => {},
  programChapterId: null,
  programData: null,
  isAddMode: false,
};

export default FilmsUploadAssetsForm;
