import { getApiCall } from '../utils/Api';

export const searchFilmmakersGenres = async (searchText) => {
  const result = await getApiCall(`/filmmakers/genres/search?searchText=${searchText}`);
  return result.data;
};

export const searchSecondaryGenres = async (searchText) => {
  const result = await getApiCall(`/filmmakers/secondary-genres/search?searchText=${searchText}`);
  return result.data;
};

export const searchTags = async (searchText) => {
  const result = await getApiCall(`/filmmakers/tags/search?searchText=${searchText}`);
  return result.data;
};
