import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import loaderStyle from '../theme/styles/components/TableLoader';

const useStyles = makeStyles(loaderStyle);

// Show loading for table data
const TableLoader = ({ rows, cols }) => {
  const classes = useStyles();
  const rowsArray = [...Array(rows)].map((item, index) => index);
  const colsArray = [...Array(cols)].map((item, index) => index);

  return (
    <>
      {rowsArray.map((r) => (
        <TableRow key={`ldr-${r}`}>
          {colsArray.map((c) => (
            <TableCell key={`ldc-${c}`}>
              <Skeleton variant="text" className={classes.placeholderSkeleton} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

TableLoader.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};
TableLoader.defaultProps = {
  rows: 10,
  cols: 5,
};

export default TableLoader;
