import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Add, CloudUpload } from '@material-ui/icons';
import axios from 'axios';
import { getAWSUploadUrl } from '../../services/Assets';
import { uploadFilmAsset } from '../../services/Programs';
import { filmmakerFilmsAssetsType, promotionSubmissionType } from '../../config/Constants';
import { getCurrentTimestamp } from '../../utils/Datetime';
import { uploadFilmmakerPromotionAsset } from '../../services/Filmmakers';

const VideoUpload = ({
  caption,
  filmId,
  onUploadComplete,
  assetType,
  programId,
  chapterId,
  filmStatus,
  filmmakerFilmId,
  isSeries,
  filmmakerId,
  promotionsAsset,
  showSnackbarMessage,
}) => {
  const hiddenFileInput = useRef(null);
  const buttonId = Date.now();

  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
    setProgress(Math.round((p.loaded / p.total) * 100));
  };

  const onFinish = (data, file) => {
    const URL = data.signedUrl.split('?').shift();
    setProgress(100);
    setProgress(0);
    setFileSelected(false);
    setIsFileUploading(false);

    if (promotionsAsset) {
      const payload = {
        filmmakerId,
        programId,
        type: promotionSubmissionType.OTHER_FILE,
        fileName: file.name,
        fileUrl: URL,
      };
      uploadFilmmakerPromotionAsset(payload)
        .then((res) => {
          onUploadComplete({ url: URL, id: res?.data, fileName: file.name });
        })
        .catch(() => {
          // Skipped
        });
    } else {
      const payload = {
        assetType,
        assetUrl: URL,
        assetStatus: 2,
        programDetail: null,
        programChapterId: chapterId,
        programId,
        programStatus: filmStatus,
        isSeries,
      };
      uploadFilmAsset(filmmakerFilmId, payload).then((res) =>
        onUploadComplete({ url: URL, id: res?.data })
      );
    }
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          onProgress(p);
        },
      });
      onFinish(data, file);
    } catch {
      onError();
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        (!promotionsAsset && file?.type?.split('/')[0] === 'video') ||
        promotionsAsset ||
        assetType === filmmakerFilmsAssetsType.BTS_VIDEO
      ) {
        const fileExtension = file?.name.split('.')[file?.name.split('.').length - 1];
        const currentTime = getCurrentTimestamp();
        const fileName = `${filmmakerId}_${currentTime}.${fileExtension}`;
        const params = {
          objectName: fileName,
          contentType: file.type,
          path: `films/${filmId}/assets/`,
        };
        getAWSUploadUrl(params)
          .then((res) => {
            uploadFile(res, file);
            setFileSelected(true);
          })
          .catch(() => {
            setHasError(true);
            setErrorMessage('Something went wrong.');
          });
      } else if (!promotionsAsset) {
        showSnackbarMessage('error', 'Please select video file only.');
      }
    }
  };

  let acceptFile;
  if (assetType === filmmakerFilmsAssetsType.BTS_VIDEO) {
    acceptFile = '.png, .jpg, .jpeg, .gif, video/*';
  } else if (promotionsAsset) {
    acceptFile = '';
  } else {
    acceptFile = 'video/*';
  }

  return (
    <>
      <input
        accept={acceptFile}
        id={`upload-vid-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading}
      />

      <label htmlFor={`upload-vid-button-${buttonId}`} style={{ width: '97%' }}>
        <Button
          fullWidth
          onClick={handleClick}
          variant="outlined"
          startIcon={!fileSelected && (promotionsAsset ? <CloudUpload /> : <Add />)}
          title={errorMessage}
        >
          {!fileSelected && caption}
          {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
          {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
          {hasError && 'Error'}
        </Button>
        {isFileUploading && (
          <div style={{ marginBottom: 20, color: 'grey', fontSize: 14 }}>
            Do not close the window or save the details until whole file is uploaded.
          </div>
        )}
      </label>
    </>
  );
};

VideoUpload.propTypes = {
  caption: PropTypes.string.isRequired,
  filmId: PropTypes.number,
  onUploadComplete: PropTypes.func.isRequired,
  assetType: PropTypes.number,
  programId: PropTypes.number.isRequired,
  chapterId: PropTypes.number,
  filmStatus: PropTypes.number,
  filmmakerFilmId: PropTypes.number,
  isSeries: PropTypes.bool,
  filmmakerId: PropTypes.number,
  promotionsAsset: PropTypes.bool,
  showSnackbarMessage: PropTypes.func,
};
VideoUpload.defaultProps = {
  filmId: null,
  assetType: null,
  chapterId: null,
  filmStatus: null,
  filmmakerFilmId: null,
  isSeries: false,
  filmmakerId: null,
  promotionsAsset: false,
  showSnackbarMessage: () => {},
};

export default VideoUpload;
