// Style for application whole loader
const Style = (theme) => ({
  loader: {
    display: 'flex',
    height: '95vh',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
});

export default Style;
