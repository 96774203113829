import {
  Button,
  Box,
  Card,
  CardActions,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import VideoPlayer from 'react-video-js-player';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import SnackbarMessage from '../SnackbarMessage';
import pageStyle from '../../theme/styles/pages/FormPage';
import UploadVideo from './VideoUpload';
import UploadImage from './ImageUpload';
import {
  filmmakerAssetsStatus,
  filmmakerFilmsAssetsType,
  filmmkaerFilmsStatus,
} from '../../config/Constants';
import ConfirmDialog from '../ConfirmDialog';
import { updateFilmsAssetStatus } from '../../services/Filmmakers';
import getLocalStorageValue from '../../utils/LocalStorage';

const useStyles = makeStyles(pageStyle);

const UploadMultipleAssets = ({
  data,
  title,
  onSuccess,
  closeModal,
  assetType,
  filmStatus,
  filmId,
  programId,
  chapterId,
  isSeries,
}) => {
  const classes = useStyles();

  const { id } = useParams();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removedItemIndex, setRemovedItemIndex] = useState(null);
  const [filmmakerId, setFilmmakerId] = useState(null);

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const removeItem = (index) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });
    const newRows = [...rows];
    const removedData = rows[index];
    const assetStatus =
      (filmStatus === filmmkaerFilmsStatus.PUBLISHED && removedData.assetStatus !== 2) ||
      (removedData?.programDetails && JSON.parse(removedData.programDetails)?.selectedImage)
        ? filmmakerAssetsStatus.REQUESTED_DELETION
        : filmmakerAssetsStatus.DELETED;

    updateFilmsAssetStatus(removedData.id, {
      assetStatus,
    })
      .then((res) => {
        if (res.data.success) {
          if (assetStatus === filmmakerAssetsStatus.REQUESTED_DELETION) {
            newRows[index].assetStatus = filmmakerAssetsStatus.REQUESTED_DELETION;
          } else {
            newRows.splice(index, 1);
          }
          setDataLoaded(false);
          setRows([]);
          setConfirmDialog(false);
          setSnackbarMeesage({
            message:
              assetStatus === filmmakerAssetsStatus.REQUESTED_DELETION
                ? 'Asset deletion request successfully'
                : 'Asset removed successfully.',
            type: 'success',
            show: true,
          });
          setTimeout(() => {
            setRows(newRows);
            setDataLoaded(true);
          }, 1000);
        }
      })
      .catch((e) => {
        setSnackbarMeesage({
          message: e.message,
          type: 'error',
          show: true,
        });
      });
  };

  const onUploadComplete = (v) => {
    const type = v?.url ? v.url.split('.') : '';
    setRows([
      ...rows,
      {
        url: v.url,
        assetStatus: filmmakerAssetsStatus.PENDING,
        assetType,
        id: v?.id,
        isImage: !!['jpeg', 'jpg', 'png', 'gif'].includes(type?.[type.length - 1] || ''),
      },
    ]);
    onSuccess(
      [...rows, { url: v.url, assetStatus: filmmakerAssetsStatus.PENDING, assetType, id: v?.id }],
      assetType
    );
    setReloadRows(!reloadRows);
  };

  const updateData = () => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    onSuccess(rows, assetType);
    closeModal();
  };

  useEffect(() => {
    setDataLoaded(true);
    setTotalPages(0);
  }, [reloadRows, activePage]);

  useEffect(() => {
    const localValues = getLocalStorageValue('user');
    setFilmmakerId(localValues.id);
    if (data && data.length > 0) {
      const newData = [];
      data.forEach((d) => {
        const type = d?.assetUrl ? d.assetUrl.split('.') : d?.url.split('.') || '';
        newData.push({
          ...d,
          isImage: !!['jpeg', 'jpg', 'png', 'gif'].includes(type?.[type.length - 1] || ''),
        });
      });
      setRows(newData);
    }
  }, []);

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>{title}</Box>

          {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
            filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
              <Box className={classes.filterToolbar}>
                <Grid container direction="row">
                  <Box className={classes.filterRight}>
                    {assetType === filmmakerFilmsAssetsType.BTS_VIDEO ? (
                      <UploadVideo
                        name="video"
                        caption="Add New"
                        filmId={filmId}
                        onUploadComplete={(v) => onUploadComplete(v)}
                        assetType={assetType}
                        programId={programId}
                        chapterId={chapterId}
                        filmStatus={filmStatus}
                        filmmakerFilmId={id}
                        isSeries={isSeries}
                        filmmakerId={filmmakerId}
                      />
                    ) : (
                      <UploadImage
                        name="image"
                        caption="Add New"
                        filmId={filmId}
                        onUploadComplete={(v) => onUploadComplete(v)}
                        assetType={assetType}
                        programId={programId}
                        chapterId={chapterId}
                        filmStatus={filmStatus}
                        filmmakerFilmId={id}
                        isSeries={isSeries}
                        filmmakerId={filmmakerId}
                      />
                    )}
                  </Box>
                </Grid>
              </Box>
            )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={2} mb={5}>
          {!dataLoaded && <CircularProgress />}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {dataLoaded &&
                rows.filter((item) => item.assetStatus !== filmmakerAssetsStatus.DELETED).length ===
                  0 && (
                  <Grid item xs={12}>
                    <Typography align="center" style={{ color: 'red' }}>
                      No records found.
                    </Typography>
                  </Grid>
                )}
              {dataLoaded &&
                rows.length !== 0 &&
                rows.map(
                  (m, index) =>
                    m.url !== '' &&
                    m.assetUrl !== '' &&
                    m.assetStatus !== filmmakerAssetsStatus.DELETED && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        style={{
                          border:
                            m?.programDetails &&
                            JSON.parse(m.programDetails).selectedImage &&
                            '2px solid green',
                        }}
                      >
                        <Card sx={{ maxWidth: 100, minWidth: 100 }}>
                          <Box mt={2} mb={2} display="flex" justifyContent="center">
                            {m?.isImage && (
                              <CardMedia
                                component="img"
                                alt="m.name"
                                height={150}
                                image={m.url || m.assetUrl}
                                style={{ 'object-fit': 'contain' }}
                              />
                            )}
                            {!m.isImage && (
                              <VideoPlayer
                                src={m.url || m.assetUrl}
                                width="270"
                                hideControls={['volume', 'playbackrates']}
                                controls
                              />
                            )}
                          </Box>
                          {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                            filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
                              <CardActions disableSpacing>
                                <>
                                  {m.assetStatus > 0 && m.assetStatus !== 5 && (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        setConfirmDialog(true);
                                        setRemovedItemIndex(index);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                  {m.assetStatus === 5 && (
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      disabled
                                    >
                                      Requested for Delete
                                    </Button>
                                  )}
                                  {m.assetStatus === 0 && <Button color="primary">Rejected</Button>}
                                </>
                              </CardActions>
                            )}
                        </Card>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
        </Box>
        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={updateData}>
          Close
        </Button>
      </DialogActions>

      {confirmDialog && (
        <ConfirmDialog
          title="Remove Asset"
          message="Do you want to delete this record? This action can not be undone."
          onClose={() => {
            setConfirmDialog(false);
          }}
          onApprove={() => removeItem(removedItemIndex)}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

UploadMultipleAssets.propTypes = {
  title: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  assetType: PropTypes.number.isRequired,
  data: PropTypes.objectOf.isRequired,
  filmStatus: PropTypes.number.isRequired,
  filmId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  chapterId: PropTypes.number.isRequired,
  isSeries: PropTypes.bool.isRequired,
};

export default UploadMultipleAssets;
