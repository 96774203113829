import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { saveAs } from 'file-saver';

const ViewPrompt = ({ title, closeViewPromptDialog, promptData, downloadFileName }) => {
  const handleDownload = () => {
    const blob = new Blob([promptData], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(blob, `${downloadFileName}.docx`);
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>{`View ${title}`}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} style={{ marginBottom: 20, marginTop: 10 }}>
            <Grid item xs={12}>
              {promptData && promptData.split(/\r\n|\n|\r/gm).map((line) => <p>{line}</p>)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download
          </Button>
          <Button variant="contained" onClick={closeViewPromptDialog}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ViewPrompt.propTypes = {
  title: PropTypes.string.isRequired,
  closeViewPromptDialog: PropTypes.func.isRequired,
  promptData: PropTypes.string.isRequired,
  downloadFileName: PropTypes.string.isRequired,
};

export default ViewPrompt;
