import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Menu,
  Link,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/RemoveRedEye';
import { CloudUpload, ArrowDropDown } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import SnackbarMessage from '../SnackbarMessage';
import listingStyle from '../../theme/styles/pages/Listing';
import {
  createBtsFile,
  deleteBtsFile,
  getBtsFiles,
  updateBtsFile,
} from '../../services/Filmmakers';
import { getAWSUploadUrl } from '../../services/Assets';
import ConfirmDialog from '../ConfirmDialog';
import TableLoader from '../TableLoader';

const useStyles = makeStyles(listingStyle);

const ManageBtsFiles = ({ filmmakerProgramId, handleClose }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);
  const [addNewMode, setAddNewMode] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const openListMode = () => {
    setAddNewMode(false);
    setSelectedDocument(null);
    setReloadRows(!reloadRows);
  };

  const getParams = () => {
    let queryString = '1=1';
    if (searchText) queryString += `&searchText=${encodeURIComponent(searchText)}`;

    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    getBtsFiles(activePage, getParams(), filmmakerProgramId).then((res) => {
      setRows(res.data.rows);
      setTotalPages(res.data.totalPages);
      setDataLoaded(true);
    });
  }, [reloadRows, filmmakerProgramId]);

  const refreshItems = () => {
    setReloadRows(!reloadRows);
  };

  const deleteDocument = () => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    deleteBtsFile(selectedDocument.btsDocId)
      .then((res) => {
        if (res.success) {
          setSnackbarMeesage({
            message: 'Document deleted successfully.',
            type: 'success',
            show: true,
          });
        } else {
          setSnackbarMeesage({
            message: res.message || 'Something went wrong',
            type: 'error',
            show: true,
          });
        }
        setOpenDeleteConfirmDialog(false);
        setSelectedDocument(null);
        setReloadRows(!reloadRows);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          message: response.data.message || 'Something went wrong',
          type: 'error',
          show: true,
        });
        setOpenDeleteConfirmDialog(false);
        setSelectedDocument(null);
      });
  };

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  return (
    <Dialog open maxWidth={addNewMode ? 'sm' : 'md'} fullWidth>
      <DialogTitle>
        {addNewMode ? (
          `Add BTS Files`
        ) : (
          <div>
            <Box display="flex" justifyContent="space-between">
              <Box>BTS Files</Box>
            </Box>
            <Box display="flex" mt={3}>
              <TextField
                name="search"
                id="search"
                label="Search"
                className={classes.searchInput}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  searchList();
                }}
                value={searchText}
              />

              <Box display="flex" ml={2}>
                <Button onClick={refreshItems} variant="contained" disableElevation>
                  Refresh
                </Button>
              </Box>
              <Box display="flex" ml="auto">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addNewBtn}
                  onClick={() => {
                    setAddNewMode(true);
                  }}
                  startIcon={<AddIcon />}
                >
                  Add New
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <Box pt={0.3} pb={3}>
          <Box mt={0}>
            {addNewMode ? (
              <AddFileMode
                filmmakerProgramId={filmmakerProgramId}
                btsDocData={selectedDocument}
                onSuccess={openListMode}
              />
            ) : (
              <>
                <TableContainer>
                  <Table className={classes.tableData}>
                    <TableHead>
                      <TableRow>
                        <TableCell>File</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {!dataLoaded && <TableLoader cols={3} />}
                    {dataLoaded && rows.length === 0 && (
                      <TableCell
                        align="center"
                        size="medium"
                        colSpan={3}
                        className={classes.noRecordFoundText}
                      >
                        No records found
                      </TableCell>
                    )}
                    {dataLoaded &&
                      rows.map((row) => (
                        <TableRow key={row.btsDocId}>
                          <TableCell>{row.fileName}</TableCell>
                          <TableCell>
                            <Tooltip title={row.notes || ''}>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  maxWidth: 300,
                                  WebkitLineClamp: 3,
                                }}
                              >
                                {row.notes || '-'}
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell width="15%">
                            <IconButton aria-label="edit" className={classes.deleteBtn}>
                              <Tooltip
                                title="edit"
                                onClick={() => {
                                  setSelectedDocument(row);
                                  setAddNewMode(true);
                                }}
                              >
                                <EditIcon fontSize="small" color="textPrimary" />
                              </Tooltip>
                            </IconButton>
                            <IconButton aria-label="view" className={classes.deleteBtn}>
                              <Tooltip title="view">
                                <Link
                                  href={row.filePath}
                                  color="textPrimary"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <PreviewIcon fontSize="small" color="textPrimary" />
                                </Link>
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              aria-label="reject"
                              className={classes.deleteBtn}
                              onClick={() => {
                                setSelectedDocument(row);
                                setOpenDeleteConfirmDialog(true);
                              }}
                            >
                              <Tooltip title="delete">
                                <DeleteIcon fontSize="small" color="secondary" />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </TableContainer>
                {dataLoaded && totalPages > 1 && (
                  <Pagination
                    count={totalPages}
                    showFirstButton
                    showLastButton
                    className={classes.tablePagination}
                    onChange={(_, pageNumber) => {
                      setActivePage(pageNumber);
                      setReloadRows(!reloadRows);
                    }}
                    page={activePage}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {addNewMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              openListMode();
            }}
          >
            Back
          </Button>
        )}
        <Button onClick={handleClose} variant="contained" disableElevation>
          close
        </Button>
      </DialogActions>
      {openDeleteConfirmDialog && (
        <ConfirmDialog
          title="Remove Document"
          message="Do you want to delete this document? This action can not be undone."
          onClose={() => {
            setOpenDeleteConfirmDialog(false);
          }}
          onApprove={deleteDocument}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

const AddFileMode = ({ filmmakerProgramId, onSuccess, btsDocData }) => {
  const hiddenFileInput = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [fileUrl, setFileUrl] = useState(false);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [notes, setNotes] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (btsDocData) {
      setFileName(btsDocData?.fileName || '');
      setFileUrl(btsDocData?.filePath || '');
      setNotes(btsDocData?.notes || '');
    }
  }, [btsDocData]);

  const onSubmit = async () => {
    try {
      if (btsDocData) {
        await updateBtsFile(btsDocData.btsDocId, {
          fileName,
          src: fileUrl,
          filmmakerProgramId,
          notes,
        });
      } else {
        await createBtsFile({
          fileName,
          src: fileUrl,
          filmmakerProgramId,
          notes,
        });
      }

      onSuccess();
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || 'Something went wrong.');
    }
  };

  const onFinish = async (data) => {
    try {
      const URL = data.signedUrl.split('?').shift();
      setFileUrl(URL);
    } catch (error) {
      // skip
    }
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          setProgress(Math.round((p.loaded / p.total) * 100));
        },
      });
      setFileName(file.name);
      onFinish({ ...data, name: file.name });
    } catch (e) {
      // do something
    }
  };

  const handleChange = async (e) => {
    try {
      setIsFileUploading(true);
      setErrorMessage('');
      const file = e.target.files[0];
      const params = {
        objectName: file.name,
        contentType: file.type,
        path: `films/${filmmakerProgramId}/bts-documents/`,
      };
      const res = await getAWSUploadUrl(params);
      await uploadFile(res, file);
      setIsFileUploading(false);
    } catch (error) {
      setErrorMessage(error.message || 'Something went wrong.');
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <Box display="flex" flexDirection="column">
      <input
        id="upload-bts-doc"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading}
      />

      <TextField
        label="Notes"
        multiline
        autoFocus
        defaultValue={notes}
        variant="outlined"
        onChange={(e) => setNotes(e.target.value)}
      />

      {fileUrl ? (
        <div>
          <Button
            aria-haspopup="true"
            variant="outlined"
            fullWidth
            endIcon={<ArrowDropDown />}
            onClick={handleManageClick}
            style={{ margin: '10px 0px', color: 'green', borderColor: 'green' }}
          >
            Manage File
          </Button>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
              component={Link}
              color="textPrimary"
              href={fileUrl}
              target="_blank"
              rel="noreferrer"
            >
              View File
            </MenuItem>

            <label htmlFor="upload-button-">
              <MenuItem button onClick={handleClick}>
                {isFileUploading ? `Uploading File (${progress || 0}%)` : 'Upload new File'}
              </MenuItem>
            </label>
          </Menu>
        </div>
      ) : (
        <Button
          fullWidth
          onClick={handleClick}
          style={{ margin: '10px 0' }}
          variant="outlined"
          disableElevation
          disabled={isFileUploading}
          startIcon={
            !isFileUploading ? (
              <CloudUpload />
            ) : (
              <CircularProgress variant="determinate" value={progress} size={24} />
            )
          }
        >
          {isFileUploading ? `Uploading File (${progress || 0}%)` : 'Select File'}
        </Button>
      )}
      <Button
        aria-haspopup="true"
        variant="outlined"
        disabled={isFileUploading || !fileUrl}
        fullWidth
        onClick={onSubmit}
        color="primary"
      >
        Submit
      </Button>

      {errorMessage && (
        <Typography variant="h6" style={{ color: 'red' }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

AddFileMode.defaultProps = {
  btsDocData: null,
};

AddFileMode.propTypes = {
  filmmakerProgramId: PropTypes.number.isRequired,
  btsDocData: PropTypes.shape(),
  onSuccess: PropTypes.func.isRequired,
};

ManageBtsFiles.propTypes = {
  filmmakerProgramId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ManageBtsFiles;
