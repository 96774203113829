import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

// Preview image inline
const ImageInlinePreview = ({ url }) => {
  const [downloading, setDownloading] = useState(false);

  if (!url) {
    return <></>;
  }

  const downloadImage = (imageURL) => {
    setDownloading(true);
    fetch(imageURL)
      .then((resp) => resp.blob())
      .then((blobobject) => {
        const blob = window.URL.createObjectURL(blobobject);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        anchor.download = imageURL.substring(imageURL.lastIndexOf('/') + 1);
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        window.open(imageURL, '_blank');
      });
  };

  return (
    <>
      <img src={url} alt="profile-pic" width="100" /> <br />
      {downloading && (
        <CircularProgress color="inherit" size={14} style={{ marginRight: '3px' }} />
      )}{' '}
      <Button
        variant="text"
        size="small"
        onClick={() => {
          downloadImage(url);
        }}
        style={{
          textTransform: 'none',
          fontSize: '10px',
          color: '#00F',
          textDecoration: 'underline',
        }}
        disabled={downloading}
      >
        click here
      </Button>
      to view/download the image.
    </>
  );
};

ImageInlinePreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImageInlinePreview;
