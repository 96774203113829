import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import listingStyle from '../../theme/styles/pages/Listing';
import SnackbarMessage from '../SnackbarMessage';
import ConfirmDialog from '../ConfirmDialog';
import { deleteChapter } from '../../services/Filmmakers';
import { filmmkaerFilmsStatus } from '../../config/Constants';

const useStyles = makeStyles(listingStyle);

const EpisodeList = ({
  chapters,
  showAddEpisodeDialog,
  showViewEpisodeDialog,
  filmStatus,
  filmmakerProgramId,
  onSuccess,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setRows(chapters);
  }, [chapters]);

  const requestDeleteEpisode = async (id) => {
    setDeleteId(id);
    setIsDelete(true);
  };

  const submitDelete = async () => {
    // TODO
    /**
     * Need to confirm how to delete request for episode
     */
    deleteChapter(deleteId, {
      filmmakerProgramId,
    })
      .then((res) => {
        if (res.data.success) {
          setSnackbarMeesage({
            message: 'Episode deleted successfully.',
            type: 'success',
            show: true,
          });
          setIsDelete(false);
          onSuccess();
        } else {
          setSnackbarMeesage({
            message: 'some error occurred please try again.',
            type: 'error',
            show: true,
          });
        }
      })
      .catch((e) => {
        // skip
        setSnackbarMeesage({
          message: e.message,
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <>
      <div style={{ width: '100%', display: 'flex', paddingTop: 20, marginBottom: 15 }}>
        <div style={{ width: '50%', float: 'left' }}>
          <Typography variant="h6" component="h2">
            Episodes
          </Typography>
        </div>
        {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
          filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE && (
            <div style={{ width: '50%', float: 'left', textAlign: 'right' }}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={showAddEpisodeDialog}
              >
                Add Episode
              </Button>
            </div>
          )}
      </div>
      <TableContainer>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="30%">Episode No</TableCell>
              <TableCell width="50%">Title</TableCell>
              <TableCell width="50%">Rejection Note</TableCell>
              <TableCell width="20%">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows?.length === 0 && (
              <TableCell
                align="center"
                colSpan={8}
                size="medium"
                className={classes.noRecordFoundText}
              >
                No records found
              </TableCell>
            )}

            {rows &&
              rows?.length > 0 &&
              rows?.map((row, idx) => (
                <TableRow key={`gn-${row.id}`}>
                  <TableCell>{row?.programDetail?.episodeNumber || idx + 1}</TableCell>
                  <TableCell>
                    {row?.programDetail?.episodeTitle ||
                      row?.programDetail?.title ||
                      'Untitle Episode'}
                  </TableCell>
                  <TableCell>{row?.rejectionNote || '-'}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        showViewEpisodeDialog(
                          row?.programDetail?.id,
                          row?.programDetail?.isNewEpisode
                        )
                      }
                    >
                      view
                    </Button>
                    {filmStatus !== filmmkaerFilmsStatus.FOR_APPROVAL &&
                      filmStatus !== filmmkaerFilmsStatus.PUBLISHED_WITH_PENDING_UPDATE &&
                      row?.programDetail.isNewEpisode > 0 && (
                        <Button onClick={() => requestDeleteEpisode(row?.programDetail?.id || 0)}>
                          Delete
                        </Button>
                      )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {isDelete && (
        <ConfirmDialog
          title="Episode Delete"
          message="Are you sure want to delete this episode? This action can not be undone."
          approveButtonText="Delete"
          onClose={() => setIsDelete(false)}
          onApprove={() => submitDelete()}
        />
      )}
    </>
  );
};
export default EpisodeList;

EpisodeList.propTypes = {
  chapters: PropTypes.arrayOf.isRequired,
  showAddEpisodeDialog: PropTypes.func.isRequired,
  showViewEpisodeDialog: PropTypes.func.isRequired,
  filmStatus: PropTypes.number.isRequired,
  filmmakerProgramId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
