import { postApiCall, userGetApiCall, userPostApiCall } from '../utils/Api';

export const signInUser = async (data) => {
  const response = await userPostApiCall(`/api/sessions`, data);
  return response.data;
};

export const signUpFilmmaker = async (data) => {
  const response = await userPostApiCall(`/api/filmmaker/registrations`, data);
  return response.data;
};

export const signOutUser = async () => {
  await userGetApiCall(`/api/logout`);
};

export const validateSession = async () => {
  const response = await userGetApiCall(`/api/validate-session`);
  return response.data;
};

export const changePassword = async (data) => {
  const response = await postApiCall(`/auth/change-password`, data);
  return response.data;
};

export const validateInvitation = async (token) => {
  const response = await userGetApiCall(`/api/filmmaker/${token}/validate-invitation`);
  return response.data;
};

export const acceptInvitation = async (token, data) => {
  const response = await userPostApiCall(`/api/filmmaker/${token}/accept-invitation`, data);
  return response.data;
};

export const resetPassword = async (data) => {
  const response = await userPostApiCall(`/api/reset-password`, data);
  return response.data;
};

export const setNewPassword = async (token, data) => {
  const response = await userPostApiCall(`/api/set-new-password/${token}`, data);
  return response.data;
};

export const validateResetPasswordToken = async (token) => {
  const response = await userGetApiCall(`/api/validate-reset-token/${token}`);
  return response;
};

export const validateFilmmakerLogin = async (data) => {
  const response = await userPostApiCall(`/api/filmmaker-login-validate`, data);
  return response.data;
};
export const checkUserExists = async (email) => {
  const response = await userPostApiCall(`/api/check-user-exist`, { email });
  return response.data;
};
