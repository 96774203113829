import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import SnackbarMessage from '../../components/SnackbarMessage';
import Validations from '../../utils/Validations';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo-red.png';
import loginStyle from '../../theme/styles/pages/Login';
import { resetPassword } from '../../services/Auth';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(loginStyle);

// Login page
const ForgotPassword = () => {
  const pageName = 'Reset Password';
  const classes = useStyles();
  const history = useHistory();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    resetPassword(data)
      .then(() => {
        setIsSubmitted(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Request submitted successfully.',
          type: 'success',
          show: true,
        });
        setTimeout(() => {
          history.replace(RoutePaths.LOGIN);
        }, 2000);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data?.message || 'Something went wrong.',
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="sm">
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />

          <Container component="div" className={classes.authPageText}>
            <Typography component="h1" variant="h5">
              {pageName}
            </Typography>
            <Typography component="p">
              Enter your email address and we&apos;ll send you an email with instructions to reset
              your password.
            </Typography>
          </Container>

          <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
            <FormControl className={classes.authInput}>
              <Controller
                control={control}
                rules={Validations.REQUIRED_EMAIL}
                name="email"
                id="email"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Email Address"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="email"
                    autoFocus
                  />
                )}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.authSubmitBtn}
              disabled={isSubmitted}
            >
              Reset
            </Button>
          </form>
        </Container>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Grid>
    </PublicWrapper>
  );
};

export default ForgotPassword;
