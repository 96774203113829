import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  // DialogActions,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import pageStyle from '../../theme/styles/pages/FormPage';
import Validations from '../../utils/Validations';
import { signUpFilmmaker } from '../../services/Auth';
import RoutePaths from '../../config/Routes';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(pageStyle);

const UpgradeUserModal = ({ closeModal, userData }) => {
  const classes = useStyles();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { handleSubmit, control, reset } = useForm();

  const handleDialog = () => {
    reset();
    closeModal();
  };

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const submitData = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });
    setFormSubmitted(true);
    const payload = {
      filmmakerEmail: data.email,
      filmmakerPhone: data.phone || '',
      userId: userData.userId,
      upgrade: true,
    };
    signUpFilmmaker(payload)
      .then(() => {
        setFormSubmitted(false);
        window.location.href = RoutePaths.HOME;
        closeModal();
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        if (message && message.indexOf('Email has already been taken') !== -1) {
          window.jQuery('#email').focus();
        } else {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message,
            type: 'error',
            show: true,
          });
        }
        setFormSubmitted(false);
      });
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="sm" onClose={handleDialog}>
        <DialogTitle>Upgrade to filmmaker</DialogTitle>
        <DialogContent>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            It seems you only have a regular user account. Upgrade to a filmmaker account for free?
          </Box>
          <form id="upgrade-user" onSubmit={handleSubmit(submitData)}>
            <Controller
              control={control}
              rules={Validations.REQUIRED_EMAIL}
              name="email"
              id="email"
              defaultValue={userData.email || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  autoFocus
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              id="phone"
              defaultValue={userData.phone || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Phone"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              type="submit"
              form="upgrade-user"
              disabled={formSubmitted}
              style={{ marginBottom: '15px' }}
            >
              Upgrade to filmmaker account
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UpgradeUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userData: PropTypes.objectOf.isRequired,
};

export default UpgradeUserModal;
