import { Grid, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPaymentIframe } from '../../services/Filmmakers';

const ResourcesCategory = () => {
  const [iFrameUrl, setIFreamUrl] = useState(null);
  useEffect(() => {
    getPaymentIframe()
      .then((res) => {
        setIFreamUrl(res.data);
      })
      .catch(() => {
        // skip
      });
  }, []);

  return (
    <>
      <Grid container sx={12} md={12} sm={12}>
        <Grid container>
          <Box mt={2}>
            <ul>
              <li>
                Reveel offers the following payment methods: ACH, Wires, PayPal and Global ACH
                (International ACH).
              </li>
              <li>
                Reveel is happy to cover the transaction fees for ACH. However, the filmmaker is
                responsible for paying the transaction fees for all other payment methods. Fees will
                be charged to the filmmaker and deducted from the payment amount at the time of the
                transaction.
              </li>
              <li>
                Reveel will process all payments in US dollars only. If a filmmaker selects to get
                paid in a foreign currency, the foreign exchange fees will be charged to the
                filmmaker and deducted from the payment amount at the time of the transaction.
              </li>
              <li>
                If payment is rejected owing to incorrect information submitted by the filmmaker,
                Reveel may pass on the rejection fees to the filmmaker. This will be deducted from
                the next payment cycle.
              </li>
              <li>{`For fee details, please refer to FAQ > Payments.`}</li>
              <li>
                If you have any questions, please email us at payments@reveel.net or submit a
                support ticket via the Contact Us button.
              </li>
            </ul>
          </Box>
        </Grid>
        <iFrame
          height="800"
          width="860"
          src={iFrameUrl}
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </Grid>
    </>
  );
};

export default ResourcesCategory;
