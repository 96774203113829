import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import { Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import srtValidator from 'srt-validator';
import { uploadFile } from '../../services/Assets';
import SnackbarMessage from '../SnackbarMessage';

const UploadSrtFile = ({ caption, name, updateSubtitleFile, srtFilePath, programId }) => {
  const hiddenFileInput = useRef(null);

  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const getSignedUrl = (file, callback) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });

    if (file.name.toLowerCase().split('.').pop() === 'srt') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const errors = srtValidator(content);
        if (errors.length > 0) {
          setFileSelected(false);
          setTimeout(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              show: true,
              type: 'error',
              message:
                'The uploaded SRT file has errors. Please check that you have followed all requirements.',
            });
          }, 100);
        } else {
          const params = {
            objectName: file.name,
            contentType: 'text/plain',
            programId,
          };
          uploadFile(params)
            .then((res) => {
              callback(res.data);
              setFileSelected(true);
            })
            .catch(() => {
              setHasError(true);
              setErrorMessage('Something went wrong.');
              hiddenFileInput.current.value = '';
            });
        }
      };
      reader.readAsText(file);
    } else {
      setTimeout(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: `Please select subtitle(srt) file only.`,
          type: 'error',
          show: true,
        });
        hiddenFileInput.current.value = '';
      }, 250);
    }
  };

  const onProgress = (p) => {
    setProgress(p);
  };
  const onError = (error) => {
    setHasError(true);
    setErrorMessage(error);
  };
  const onFinish = (data) => {
    const URL = data.publicUrl;
    setProgress(100);
    setProgress(0);
    setFileSelected(false);
    updateSubtitleFile(URL);
  };

  return (
    <>
      <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        accept=".srt"
        onProgress={onProgress}
        onError={onError}
        onFinish={onFinish}
        uploadRequestHeaders={{
          'x-amz-acl': 'public-read',
        }}
        contentDisposition="auto"
        inputRef={hiddenFileInput}
        name={name}
        style={{ width: 0, height: 0, display: 'none' }}
      />

      <Button
        onClick={handleClick}
        fullWidth
        variant="outlined"
        startIcon={<CloudUpload />}
        title={errorMessage}
        style={{ borderColor: srtFilePath && 'green', color: srtFilePath && 'green' }}
      >
        {!fileSelected && caption}
        {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
        {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
        {hasError && 'Error'}
      </Button>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadSrtFile.propTypes = {
  caption: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updateSubtitleFile: PropTypes.func.isRequired,
  srtFilePath: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
};

export default UploadSrtFile;
