import { filmmkaerFilmsStatus } from '../config/Constants';

const validateFilmAssets = (data, isSeries, isEpisode, episodeStatus = 0, filmStatus = 0) => {
  const returnStatus = {
    isProcess: true,
    trailer: false,
    film: false,
    thumbnail: false,
    thumbnailWithoutText: false,
    videoSrt: false,
  };

  if (!isSeries) {
    if (!data.trailer.assetUrl || data.trailer.assetStatus === 0) {
      returnStatus.trailer = true;
      returnStatus.isProcess = false;
    }
    if (data.film.length <= 0) {
      returnStatus.film = true;
      returnStatus.isProcess = false;
    }

    if (data?.film?.assetStatus === 0) {
      returnStatus.film = true;
      returnStatus.isProcess = false;
    }

    if (data.thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    if (data.thumbnailWithoutText.length === 0) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
    if (filmStatus !== filmmkaerFilmsStatus.PUBLISHED && data.videoSrtFiles.length === 0) {
      returnStatus.videoSrt = true;
      returnStatus.isProcess = false;
    }

    if (
      filmStatus !== filmmkaerFilmsStatus.PUBLISHED &&
      data.videoSrtFiles.length === 1 &&
      (data.videoSrtFiles?.[0]?.assetStatus === 0 || data.videoSrtFiles?.[0]?.assetStatus === 5)
    ) {
      returnStatus.videoSrt = true;
      returnStatus.isProcess = false;
    }

    if (
      filmStatus !== filmmkaerFilmsStatus.PUBLISHED &&
      data.videoSrtFiles.length > 0 &&
      !returnStatus.videoSrt
    ) {
      const videoSrtFiles = data.videoSrtFiles.filter(
        (f) => f.assetStatus === 2 || f.assetStatus === 1
      );
      const isEng = videoSrtFiles.filter((f) => f.lanCode === 'en');
      if ((videoSrtFiles && videoSrtFiles.length <= 0) || isEng.length <= 0) {
        returnStatus.videoSrt = true;
        returnStatus.isProcess = false;
        returnStatus.message = 'Please provide English SRT (Subtitle) file.';
      }
    }

    if (
      data.thumbnail.length === 1 &&
      (data.thumbnail?.[0]?.assetStatus === 0 || data.thumbnail?.[0]?.assetStatus === 5)
    ) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    const thumbnail =
      data.thumbnail.length > 1 &&
      data.thumbnail.filter((f) => f.assetStatus === 2 || f.assetStatus === 1);
    if (thumbnail && thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }

    if (
      data.thumbnailWithoutText.length === 1 &&
      (data.thumbnailWithoutText?.[0]?.assetStatus === 0 ||
        data.thumbnailWithoutText?.[0]?.assetStatus === 5)
    ) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
    const thumbnailWithoutText =
      data.thumbnailWithoutText.length > 1 &&
      data.thumbnailWithoutText.filter((f) => f.assetStatus === 2 || f.assetStatus === 1);
    if (thumbnailWithoutText && thumbnailWithoutText.length <= 0) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
  } else if (!isEpisode && isSeries) {
    if (Array.isArray(data.trailer) && data.trailer.length <= 0) {
      returnStatus.trailer = true;
      returnStatus.isProcess = false;
    } else if (!data.trailer.assetUrl || !data.trailer.assetStatus) {
      returnStatus.trailer = true;
      returnStatus.isProcess = false;
    }
    if (data.thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    if (data.thumbnailWithoutText.length === 0) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
    if (
      data.thumbnail.length === 1 &&
      (data.thumbnail?.[0]?.assetStatus === 0 || data.thumbnail?.[0]?.assetStatus === 5)
    ) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    const thumbnail =
      data.thumbnail.length > 1 &&
      data.thumbnail.filter((f) => f.assetStatus === 2 || f.assetStatus === 1);
    if (thumbnail && thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }

    if (
      data.thumbnailWithoutText.length === 1 &&
      (data.thumbnailWithoutText?.[0]?.assetStatus === 0 ||
        data.thumbnailWithoutText?.[0]?.assetStatus === 5)
    ) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
    const thumbnailWithoutText =
      data.thumbnailWithoutText.length > 1 &&
      data.thumbnailWithoutText.filter((f) => f.assetStatus === 2 || f.assetStatus === 1);
    if (thumbnailWithoutText && thumbnailWithoutText.length <= 0) {
      returnStatus.thumbnailWithoutText = true;
      returnStatus.isProcess = false;
    }
  } else if (isEpisode && isSeries) {
    if (!data.film?.assetUrl) {
      returnStatus.film = true;
      returnStatus.isProcess = false;
    }
    if (data?.film?.assetStatus === 0) {
      returnStatus.film = true;
      returnStatus.isProcess = false;
    }
    if (data.thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    if (episodeStatus !== 1 && data.videoSrtFiles.length === 0) {
      returnStatus.videoSrt = true;
      returnStatus.isProcess = false;
    }

    if (
      episodeStatus !== 1 &&
      data.videoSrtFiles.length === 1 &&
      (data.videoSrtFiles?.[0]?.assetStatus === 0 || data.videoSrtFiles?.[0]?.assetStatus === 5)
    ) {
      returnStatus.videoSrt = true;
      returnStatus.isProcess = false;
    }

    if (episodeStatus !== 1 && data.videoSrtFiles.length > 0 && !returnStatus.videoSrt) {
      const videoSrtFiles = data.videoSrtFiles.filter(
        (f) => f.assetStatus === 2 || f.assetStatus === 1
      );

      const isEng = videoSrtFiles.filter((f) => f.lanCode === 'en');
      if ((videoSrtFiles && videoSrtFiles.length <= 0) || isEng.length <= 0) {
        returnStatus.videoSrt = true;
        returnStatus.isProcess = false;
        returnStatus.message = 'Please provide English SRT (Subtitle) file.';
      }
    }

    if (
      data.thumbnail.length === 1 &&
      (data.thumbnail?.[0]?.assetStatus === 0 || data.thumbnail?.[0]?.assetStatus === 5)
    ) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
    const thumbnail =
      data.thumbnail.length > 1 &&
      data.thumbnail.filter((f) => f.assetStatus === 2 || f.assetStatus === 1);
    if (thumbnail && thumbnail.length <= 0) {
      returnStatus.thumbnail = true;
      returnStatus.isProcess = false;
    }
  }
  return returnStatus;
};

export default validateFilmAssets;
