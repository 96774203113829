import { Box, CircularProgress, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo-red.png';
import loginStyle from '../../theme/styles/pages/Login';
import { acceptInvitation, validateInvitation } from '../../services/Auth';
import Validations from '../../utils/Validations';
import RoutePaths from '../../config/Routes';
import SnackbarMessage from '../../components/SnackbarMessage';

const useStyles = makeStyles(loginStyle);

const Invitation = () => {
  const classes = useStyles();
  const params = useParams();

  const [validToken, setValidToken] = useState(false);
  const [processingToken, setProcessingToken] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue } = useForm();

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    acceptInvitation(params.token, data)
      .then(() => {
        setIsSubmitted(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Account created successfully.',
          type: 'success',
          show: true,
        });
        window.location.assign(RoutePaths.LOGIN);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response?.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  useEffect(() => {
    setValidToken(false);
    setProcessingToken(true);
    validateInvitation(params.token)
      .then((res) => {
        const { data } = res;
        setValue('name', data.userData?.name || '');
        setValue('email', data.userData?.email || '');
        setValue('phone', data.userData?.phone || '');
        setProcessingToken(false);
        setValidToken(true);
      })
      .catch(({ response }) => {
        setErrorText(response?.data.message || '');
        setProcessingToken(false);
        setValidToken(false);
      });
  }, []);

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="sm">
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
          {processingToken && <CircularProgress />}
          {!processingToken && validToken && (
            <Box p={3}>
              <Typography variant="h5">Setup Your Account</Typography>
              <Typography component="span" style={{ fontSize: '12px' }}>
                This will also create a Reveel user account with the same details.
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="name"
                  id="name"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="Name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.authInput}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  control={control}
                  rules={Validations.REQUIRED_EMAIL}
                  name="email"
                  id="email"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      disabled
                      label="Email"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.authInput}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  id="phone"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="Phone"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.authInput}
                      fullWidth
                    />
                  )}
                />

                <>
                  <Controller
                    control={control}
                    rules={Validations.REQUIRED_PASSWORD}
                    name="password"
                    id="password"
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        variant="outlined"
                        label="Password"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        className={classes.authInput}
                        fullWidth
                        type="password"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    rules={Validations.REQUIRED_PASSWORD}
                    name="confirmPassword"
                    id="confirmPassword"
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        variant="outlined"
                        label="Confirm Password"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        className={classes.authInput}
                        fullWidth
                        type="password"
                      />
                    )}
                  />
                </>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.authSubmitBtn}
                  disabled={isSubmitted}
                >
                  Create Account
                </Button>
              </form>
            </Box>
          )}
          {!processingToken && !validToken && <Typography variant="h6">{errorText}</Typography>}
          {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default Invitation;
