const imageSize = (file, from = '') => {
  const promise = new Promise((resolve) => {
    const img = new Image();

    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width >= 1920 && img.height >= 1080) {
        if (from) {
          const resolution = `${img.width}x${img.height}`;
          resolve(resolution);
        } else {
          resolve(true);
        }
      } else {
        resolve(`Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require minimum 1920 x 1080 size image.`);
      }
    };
  });

  return promise;
};

export default imageSize;
