import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown, CloudUpload } from '@material-ui/icons';
import axios from 'axios';
import { removeProgramImages, updateProgramImages } from '../../services/Programs';
import { getAWSUploadUrl } from '../../services/Assets';
import ConfirmDialog from '../ConfirmDialog';
import ViewImage from './ViewImage';
import SnackbarMessage from '../SnackbarMessage';

const UploadImage = ({ caption, name, value, filmId, chapterId, onUploadComplete }) => {
  const hiddenFileInput = useRef(null);
  const buttonId = Date.now();

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [viewModel, setViewModel] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isFileUploading, setIsFileUploading] = useState(false);

  useEffect(() => {
    setImageURL(value);
  }, [value]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
    setProgress(Math.round((p.loaded / p.total) * 100));
  };

  const onFinish = (data) => {
    const URL = data.signedUrl.split('?').shift();
    setProgress(100);
    setProgress(0);
    setFileSelected(false);
    setImageURL(URL);
    setIsFileUploading(false);

    updateProgramImages(filmId, chapterId, {
      image: name,
      src: URL,
    }).then(onUploadComplete);
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (p) => {
          onProgress(p);
        },
      });
      onFinish(data);
    } catch {
      onError();
    }
  };

  const downloadImage = () => {
    setDownloading(true);
    fetch(imageURL)
      .then((resp) => resp.blob())
      .then((blobobject) => {
        const blob = window.URL.createObjectURL(blobobject);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        anchor.download = imageURL.substring(imageURL.lastIndexOf('/') + 1);
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        window.open(imageURL, '_blank');
      });
  };

  const handleChange = (e) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    const acceptedImages = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
    const file = e.target.files[0];
    if (acceptedImages.includes(file.type)) {
      const params = {
        objectName: file.name,
        contentType: file.type,
        path: `films/${filmId}/assets/`,
      };
      getAWSUploadUrl(params)
        .then((res) => {
          uploadFile(res, file);
          setFileSelected(true);
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Something went wrong.');
        });
    } else {
      setSnackbarMeesage({
        message: `Please select image of type jpeg, jpg, png or gif file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeImage = () => {
    removeProgramImages(filmId, chapterId, { image: name, src: null }).then(() => {
      setConfirmDialog(false);
      setImageURL('');
    });
  };

  return (
    <>
      <input
        accept=".png, .jpg, .jpeg, .gif"
        id={`upload-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading}
      />

      {imageURL ? (
        <div>
          <Button
            aria-controls={name}
            aria-haspopup="true"
            variant="outlined"
            fullWidth
            endIcon={<ArrowDropDown />}
            onClick={handleManageClick}
            style={{ marginBottom: 20, color: 'green', borderColor: 'green' }}
          >
            {`Manage ${caption}`}
          </Button>
          <Menu
            id={name}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setViewModel(true);
              }}
              button
            >
              View Image
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadImage();
              }}
              button
              disabled={downloading}
            >
              {downloading && (
                <CircularProgress color="inherit" size={20} style={{ marginRight: '5px' }} />
              )}{' '}
              Download Image
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setConfirmDialog(true);
              }}
              button
            >
              Remove Image
            </MenuItem>
            <label htmlFor={`upload-button-${buttonId}`}>
              <MenuItem button>
                {!fileSelected && `Upload New Image`}
                {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
                {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                {hasError && 'Error'}
              </MenuItem>
            </label>
          </Menu>
        </div>
      ) : (
        <label htmlFor={`upload-button-${buttonId}`}>
          <Button
            onClick={handleClick}
            fullWidth
            variant="outlined"
            startIcon={<CloudUpload />}
            title={errorMessage}
          >
            {!fileSelected && caption}
            {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
            {hasError && 'Error'}
          </Button>
        </label>
      )}

      {confirmDialog && (
        <ConfirmDialog
          title={`Remove ${caption} `}
          message="Do you want to delete this record? This action can not be undone."
          onClose={() => {
            setConfirmDialog(false);
          }}
          onApprove={() => removeImage()}
        />
      )}

      {viewModel && (
        <ViewImage
          caption={caption}
          url={imageURL}
          viewModelOpen={viewModel}
          handleViewModel={(flag) => setViewModel(flag)}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadImage.propTypes = {
  caption: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  filmId: PropTypes.number.isRequired,
  chapterId: PropTypes.number,
  onUploadComplete: PropTypes.func.isRequired,
};
UploadImage.defaultProps = {
  value: '',
  chapterId: 0,
};

export default UploadImage;
