import { grey } from '@material-ui/core/colors';

// Style for form page
const Style = (theme) => ({
  heading: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 10,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  datePickerInput: {
    width: '100%',
    paddingTop: 16.5,
    paddingBottom: 16.5,
    paddingLeft: 14,
    paddingRight: 14,
    font: 'inherit',
    border: '1px solid #D3D3D3',
    borderRadius: 4,
    '&:hover': {
      border: '1px solid black',
    },
    '&:focus-visible': {
      border: '2px solid #bf0000',
      outline: 'none',
    },
  },
  datePickerInput2: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    height: '50px !important',
    border: '2px solid rgba(224, 224, 224, 1) !important',
    borderRadius: '5px',
  },
  form50Section1: {
    width: '49%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  form50Section2: {
    width: '49%',
    float: 'left',
    marginLeft: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formMediaSection: {
    position: 'relative',
    width: '100%',
    marginBottom: 50,
  },
  formVideoUpload: {
    position: 'relative',
    width: '49%',
    float: 'left',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formTrailerUpload: {
    position: 'relative',
    width: '49%',
    float: 'left',
    textAlign: 'center',
    marginLeft: '2%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  uploadBtn: {
    marginBottom: 15,
  },
  fieldMarginLeft: {
    marginLeft: 10,
  },
  fieldMarginRight: {
    marginRight: 10,
  },
  colorPicker: {
    '& div:first-child': {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  errorText: {
    color: '#f44336',
  },
  tableData: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: 8,
      fontSize: '0.85rem',
    },
    '& .MuiTableHead-root': {
      backgroundColor: grey[100],
    },
  },
  subHeader: {
    margin: '15px 0',
    fontSize: 18,
    opacity: 0.75,
  },
});

export default Style;
