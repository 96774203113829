import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const ViewImage = ({ url, viewModelOpen, handleViewModel, caption }) => {
  const [open, setOpen] = useState(viewModelOpen);

  const handleClose = () => {
    setOpen(false);
    handleViewModel(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{caption} Preview</DialogTitle>
      <DialogContent>
        <Box justifyContent="center" display="flex">
          <img src={url} alt="" width={800} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewImage.propTypes = {
  caption: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  viewModelOpen: PropTypes.bool.isRequired,
  handleViewModel: PropTypes.func.isRequired,
};

export default ViewImage;
