/* eslint-disable no-useless-catch */
import axios from 'axios';
import crypto from 'crypto';
import RoutePaths from '../config/Routes';
import { getCurrentTimestamp } from './Datetime';
import parseCookies from './GetCookie';
import COOKIE_AUTH from '../config/Constants';

export const postApiCall = async (endpoint, data) => {
  const options = {
    url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Req-Time': getCurrentTimestamp(),
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
    data: data || {},
  };

  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.assign(RoutePaths.LOGOUT);
    }
    throw e;
  }
};

export const getApiCall = async (endpoint) => {
  const options = {
    url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Req-Time': getCurrentTimestamp(),
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
    data: {},
  };

  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.assign(RoutePaths.LOGOUT);
    }
    throw e;
  }
};

export const deleteApiCall = async (endpoint) => {
  const options = {
    url: `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Req-Time': getCurrentTimestamp(),
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
    data: {},
  };

  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.assign(RoutePaths.LOGOUT);
    }
    throw e;
  }
};

export const userGetApiCall = async (endpoint) => {
  const options = {
    url: `${process.env.REACT_APP_USER_API_ENDPOINT}${endpoint}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Req-Time': getCurrentTimestamp(),
      'X-Req-Platform': process.env.REACT_APP_PLATFORM_ID,
      'X-Req-Device': process.env.REACT_APP_DEVICE_ID,
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
    data: {},
  };

  let headerValues = options.headers['X-Req-Platform'].toString();
  headerValues += options.headers['X-Req-Device'].toString();
  headerValues += options.headers['X-Req-Time'].toString();

  const cookies = parseCookies(null);
  const auth = COOKIE_AUTH in cookies ? cookies[COOKIE_AUTH] : null;
  if (auth != null) {
    options.headers['X-Auth-Token'] = auth;
    headerValues += auth;
  }
  options.headers['X-Hash'] = crypto.createHash('md5').update(headerValues).digest('hex');

  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.assign(RoutePaths.LOGOUT);
    }
    throw e;
  }
};

export const userPostApiCall = async (endpoint, data) => {
  const options = {
    url: `${process.env.REACT_APP_USER_API_ENDPOINT}${endpoint}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Req-Time': getCurrentTimestamp(),
      'X-Req-Platform': process.env.REACT_APP_PLATFORM_ID,
      'X-Req-Device': process.env.REACT_APP_DEVICE_ID,
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
    data: data || {},
  };

  let headerValues = options.headers['X-Req-Platform'].toString();
  headerValues += options.headers['X-Req-Device'].toString();
  headerValues += options.headers['X-Req-Time'].toString();

  const cookies = parseCookies(null);
  const auth = COOKIE_AUTH in cookies ? cookies[COOKIE_AUTH] : null;
  if (auth != null) {
    options.headers['X-Auth-Token'] = auth;
    headerValues += auth;
  }
  options.headers['X-Hash'] = crypto.createHash('md5').update(headerValues).digest('hex');

  try {
    const response = await axios(options);
    return response;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.assign(RoutePaths.LOGOUT);
    }
    throw e;
  }
};
