// Application route path information
const RoutePaths = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FILMS: '/films',
  LOGOUT: '/logout',
  RESOURCES: '/resources',
  INVITATION: '/invitation/:token',
  CONTRACT: '/contract/:token',
  CONTRACT_CANCELLED: '/:token/cancelled',
  CONTRACT_SIGNED: '/:token/signed',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:slug',
  PROFILE_OVERVIEW: '/overview',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  AUTOLOGIN: '/auto-login',
  FILMS_VIEW: '/films/view/:id',
  TERMS: '/terms',
  PAYMENT: '/payment',
};

export default RoutePaths;
